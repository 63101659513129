/* To Dropdown navbar dropdown on hover */
.navbar-nav > li:hover > .dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}


.acd_top_menu ul li a {
   
    padding: 5px 9px !important;
	
}

.sub-nav-link{
	color: #000;
}

.cs-menu .dropdown-menu{
	padding: 10px;
	min-width: 13rem;
}

.cs-menu a{
	font-family: 'Playfair Display', serif !important;
    font-weight: 700;
    font-size: .9rem;
}
.cs-menu .dropdown-menu li{
	width: auto;
	padding-top: 5px;
	font-size: 15px;
}
.cs-menu .dropdown-menu {
	    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.13);
    border-radius: 0px;
}
.cs-menu .dropdown-menu li{
	box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}
.cs-menu .dropdown-menu a{
	color: black;
  padding: 5px 10px;
  text-decoration: none;
    
  display: block;
}