.cl__dropdown-menu {
    z-index: 99;
    list-style: none;
    margin: 0;
    padding: 8px;
    float: left;
    text-align: center;
	/* background: #003f58; */
	background-color: #426f45;
	
}

.cl__menu__row{
	/*background: linear-gradient(180deg,rgba(1,148,154,.88),#0d6a79);*/
	/* background: purple; */
    background-color: #426f45;
}

/* .cl__dropdown-menu li {
    display: inline-block;
    position: relative;
    float: left;
} */
.cl__menu__row > div > ul.cl__dropdown-menu{
	/* background: transparent; */
	padding: 7px 0px;
	margin:auto;
	width: 850px;
}
.cl__dropdown-menu ul{
	  transition: all .5s ease;
}
.cl__dropdown-menu li {
    display: inline-block;
    position: relative;
    float: left;
    width: auto;
	/* padding: 0px 14px; */
	padding: 0 17px;
    line-height: 35px;
    text-decoration: none;
	cursor: pointer;
	transition-duration: 0.5s;
}

.cl__dropdown-menu li a {
    display: inline-block;
    /* width: 200px; */
    line-height: 35px;
    text-decoration: none;
	color: #fff;
	font-weight: 500;
}

.cl__dropdown-menu li ul{
	box-shadow: 0 8px 16px 0 rgba(0,0,0,.13);
    border-radius: 0;
	/* background-color: #fff; */
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
	
}
.cl__dropdown-menu li li {
	border-bottom: 1px dotted #8c8c8c;
	 transition-duration: 0.5s;
}
.cl__dropdown-menu li li a {
    font-size: 15px;
	min-width: 200px;
    text-align: left;
}

.cl__dropdown-menu li:hover {
    /* background: #C4082A; */
    background: hsla(0,0%,100%,.7607843137254902);
	border-radius: 20px;
}

/*--- Sublist Styles ---*/

.cl__dropdown-menu ul {
	 
    position: absolute;
    display: none;
	
}

/*--- Hide Sub Sublists ---*/
.cl__dropdown-menu li:hover ul ul {
    display: none;
}


/*--- Sublevel UL's display and position on hover ---*/
.cl__dropdown-menu li:hover ul {
	display: block;
	-moz-animation: bounceInDown .6s ease-in  ;
    -webkit-animation: bounceInDown .6s ease-in ;
    animation:bounceInDown .6s ease-in ; 
	 
}

.cl__dropdown-menu li li:hover ul {
    margin-left: 200px;
    margin-top: -35px;
    display: block;
}
nu-li {
    padding: 10px;
}

.dropdown-submenu {
    position: absolute;
    left: 0px;
    top: 0px;
}



.cl__menu__scrolled{
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 99;
}











