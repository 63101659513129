
.modal-pdffile-viewer{
	padding-top:130px;
}
.modal-pdffile-viewer .modal-dialog{
	width: 1000px;
	
}

.modal-pdffile-viewer .modal-content{
	padding: 0px;
	min-height: calc(100vh);
}

.modal-pdffile-viewer .modal-header .close {
    margin-top: -27px;
}