/*Absolute positions*/
.t-0 {
  top: 0 !important;
}

.b-0 {
  bottom: 0 !important;
}

.l-0 {
  left: 0 !important;
}

.r-0 {
  right: 0 !important;
}

/*Padding*/
.pa-0 {
  padding: 0px !important;
}

.pa-5 {
  padding: 5px !important;
}

.pa-10 {
  padding: 10px !important;
}

.pa-15 {
  padding: 15px !important;
}

.pa-20 {
  padding: 20px !important;
}

.pa-25 {
  padding: 25px !important;
}

.pa-30 {
  padding: 30px !important;
}

.pa-35 {
  padding: 35px !important;
}

.pa-40 {
  padding: 40px !important;
}

.pa-45 {
  padding: 45px !important;
}

.pa-50 {
  padding: 50px !important;
}

.pa-55 {
  padding: 55px !important;
}

.pa-60 {
  padding: 60px !important;
}

.pa-65 {
  padding: 65px !important;
}

.pa-70 {
  padding: 70px !important;
}

.pa-75 {
  padding: 75px !important;
}

.pa-80 {
  padding: 80px !important;
}

.pa-85 {
  padding: 85px !important;
}

.pa-90 {
  padding: 90px !important;
}

.pa-95 {
  padding: 95px !important;
}

.pa-100 {
  padding: 100px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.px-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.px-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.px-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.px-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.px-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.px-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.px-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.px-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.px-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.px-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.px-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.py-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.py-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.py-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.py-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.py-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.py-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.py-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.py-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.py-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.py-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

@media (min-width: 576px) {
  .pa-sm-0 {
    padding: 0px !important;
  }

  .pa-sm-5 {
    padding: 5px !important;
  }

  .pa-sm-10 {
    padding: 10px !important;
  }

  .pa-sm-15 {
    padding: 15px !important;
  }

  .pa-sm-20 {
    padding: 20px !important;
  }

  .pa-sm-25 {
    padding: 25px !important;
  }

  .pa-sm-30 {
    padding: 30px !important;
  }

  .pa-sm-35 {
    padding: 35px !important;
  }

  .pa-sm-40 {
    padding: 40px !important;
  }

  .pa-sm-45 {
    padding: 45px !important;
  }

  .pa-sm-50 {
    padding: 50px !important;
  }

  .pa-sm-55 {
    padding: 55px !important;
  }

  .pa-sm-60 {
    padding: 60px !important;
  }

  .pa-sm-65 {
    padding: 65px !important;
  }

  .pa-sm-70 {
    padding: 70px !important;
  }

  .pa-sm-75 {
    padding: 75px !important;
  }

  .pa-sm-80 {
    padding: 80px !important;
  }

  .pa-sm-85 {
    padding: 85px !important;
  }

  .pa-sm-90 {
    padding: 90px !important;
  }

  .pa-sm-95 {
    padding: 95px !important;
  }

  .pa-sm-100 {
    padding: 100px !important;
  }

  .pt-sm-0 {
    padding-top: 0px !important;
  }

  .pt-sm-5 {
    padding-top: 5px !important;
  }

  .pt-sm-10 {
    padding-top: 10px !important;
  }

  .pt-sm-15 {
    padding-top: 15px !important;
  }

  .pt-sm-20 {
    padding-top: 20px !important;
  }

  .pt-sm-25 {
    padding-top: 25px !important;
  }

  .pt-sm-30 {
    padding-top: 30px !important;
  }

  .pt-sm-35 {
    padding-top: 35px !important;
  }

  .pt-sm-40 {
    padding-top: 40px !important;
  }

  .pt-sm-45 {
    padding-top: 45px !important;
  }

  .pt-sm-50 {
    padding-top: 50px !important;
  }

  .pt-sm-55 {
    padding-top: 55px !important;
  }

  .pt-sm-60 {
    padding-top: 60px !important;
  }

  .pt-sm-65 {
    padding-top: 65px !important;
  }

  .pt-sm-70 {
    padding-top: 70px !important;
  }

  .pt-sm-75 {
    padding-top: 75px !important;
  }

  .pt-sm-80 {
    padding-top: 80px !important;
  }

  .pt-sm-85 {
    padding-top: 85px !important;
  }

  .pt-sm-90 {
    padding-top: 90px !important;
  }

  .pt-sm-95 {
    padding-top: 95px !important;
  }

  .pt-sm-100 {
    padding-top: 100px !important;
  }

  .pb-sm-0 {
    padding-bottom: 0px !important;
  }

  .pb-sm-5 {
    padding-bottom: 5px !important;
  }

  .pb-sm-10 {
    padding-bottom: 10px !important;
  }

  .pb-sm-15 {
    padding-bottom: 15px !important;
  }

  .pb-sm-20 {
    padding-bottom: 20px !important;
  }

  .pb-sm-25 {
    padding-bottom: 25px !important;
  }

  .pb-sm-30 {
    padding-bottom: 30px !important;
  }

  .pb-sm-35 {
    padding-bottom: 35px !important;
  }

  .pb-sm-40 {
    padding-bottom: 40px !important;
  }

  .pb-sm-45 {
    padding-bottom: 45px !important;
  }

  .pb-sm-50 {
    padding-bottom: 50px !important;
  }

  .pb-sm-55 {
    padding-bottom: 55px !important;
  }

  .pb-sm-60 {
    padding-bottom: 60px !important;
  }

  .pb-sm-65 {
    padding-bottom: 65px !important;
  }

  .pb-sm-70 {
    padding-bottom: 70px !important;
  }

  .pb-sm-75 {
    padding-bottom: 75px !important;
  }

  .pb-sm-80 {
    padding-bottom: 80px !important;
  }

  .pb-sm-85 {
    padding-bottom: 85px !important;
  }

  .pb-sm-90 {
    padding-bottom: 90px !important;
  }

  .pb-sm-95 {
    padding-bottom: 95px !important;
  }

  .pb-sm-100 {
    padding-bottom: 100px !important;
  }

  .pl-sm-0 {
    padding-left: 0px !important;
  }

  .pl-sm-5 {
    padding-left: 5px !important;
  }

  .pl-sm-10 {
    padding-left: 10px !important;
  }

  .pl-sm-15 {
    padding-left: 15px !important;
  }

  .pl-sm-20 {
    padding-left: 20px !important;
  }

  .pl-sm-25 {
    padding-left: 25px !important;
  }

  .pl-sm-30 {
    padding-left: 30px !important;
  }

  .pl-sm-35 {
    padding-left: 35px !important;
  }

  .pl-sm-40 {
    padding-left: 40px !important;
  }

  .pl-sm-45 {
    padding-left: 45px !important;
  }

  .pl-sm-50 {
    padding-left: 50px !important;
  }

  .pl-sm-55 {
    padding-left: 55px !important;
  }

  .pl-sm-60 {
    padding-left: 60px !important;
  }

  .pl-sm-65 {
    padding-left: 65px !important;
  }

  .pl-sm-70 {
    padding-left: 70px !important;
  }

  .pl-sm-75 {
    padding-left: 75px !important;
  }

  .pl-sm-80 {
    padding-left: 80px !important;
  }

  .pl-sm-85 {
    padding-left: 85px !important;
  }

  .pl-sm-90 {
    padding-left: 90px !important;
  }

  .pl-sm-95 {
    padding-left: 95px !important;
  }

  .pl-sm-100 {
    padding-left: 100px !important;
  }

  .pr-sm-0 {
    padding-right: 0px !important;
  }

  .pr-sm-5 {
    padding-right: 5px !important;
  }

  .pr-sm-10 {
    padding-right: 10px !important;
  }

  .pr-sm-15 {
    padding-right: 15px !important;
  }

  .pr-sm-20 {
    padding-right: 20px !important;
  }

  .pr-sm-25 {
    padding-right: 25px !important;
  }

  .pr-sm-30 {
    padding-right: 30px !important;
  }

  .pr-sm-35 {
    padding-right: 35px !important;
  }

  .pr-sm-40 {
    padding-right: 40px !important;
  }

  .pr-sm-45 {
    padding-right: 45px !important;
  }

  .pr-sm-50 {
    padding-right: 50px !important;
  }

  .pr-sm-55 {
    padding-right: 55px !important;
  }

  .pr-sm-60 {
    padding-right: 60px !important;
  }

  .pr-sm-65 {
    padding-right: 65px !important;
  }

  .pr-sm-70 {
    padding-right: 70px !important;
  }

  .pr-sm-75 {
    padding-right: 75px !important;
  }

  .pr-sm-80 {
    padding-right: 80px !important;
  }

  .pr-sm-85 {
    padding-right: 85px !important;
  }

  .pr-sm-90 {
    padding-right: 90px !important;
  }

  .pr-sm-95 {
    padding-right: 95px !important;
  }

  .pr-sm-100 {
    padding-right: 100px !important;
  }

  .px-sm-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-sm-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .px-sm-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .px-sm-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-sm-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .px-sm-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-sm-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .px-sm-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-sm-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .px-sm-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .px-sm-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .px-sm-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-sm-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .px-sm-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .px-sm-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .px-sm-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .px-sm-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .px-sm-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .px-sm-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .px-sm-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .py-sm-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .py-sm-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-sm-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-sm-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-sm-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-sm-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-sm-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-sm-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-sm-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .py-sm-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .py-sm-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .py-sm-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-sm-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .py-sm-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .py-sm-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .py-sm-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .py-sm-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .py-sm-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .py-sm-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .py-sm-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
@media (min-width: 768px) {
  .pa-md-0 {
    padding: 0px !important;
  }

  .pa-md-5 {
    padding: 5px !important;
  }

  .pa-md-10 {
    padding: 10px !important;
  }

  .pa-md-15 {
    padding: 15px !important;
  }

  .pa-md-20 {
    padding: 20px !important;
  }

  .pa-md-25 {
    padding: 25px !important;
  }

  .pa-md-30 {
    padding: 30px !important;
  }

  .pa-md-35 {
    padding: 35px !important;
  }

  .pa-md-40 {
    padding: 40px !important;
  }

  .pa-md-45 {
    padding: 45px !important;
  }

  .pa-md-50 {
    padding: 50px !important;
  }

  .pa-md-55 {
    padding: 55px !important;
  }

  .pa-md-60 {
    padding: 60px !important;
  }

  .pa-md-65 {
    padding: 65px !important;
  }

  .pa-md-70 {
    padding: 70px !important;
  }

  .pa-md-75 {
    padding: 75px !important;
  }

  .pa-md-80 {
    padding: 80px !important;
  }

  .pa-md-85 {
    padding: 85px !important;
  }

  .pa-md-90 {
    padding: 90px !important;
  }

  .pa-md-95 {
    padding: 95px !important;
  }

  .pa-md-100 {
    padding: 100px !important;
  }

  .pt-md-0 {
    padding-top: 0px !important;
  }

  .pt-md-5 {
    padding-top: 5px !important;
  }

  .pt-md-10 {
    padding-top: 10px !important;
  }

  .pt-md-15 {
    padding-top: 15px !important;
  }

  .pt-md-20 {
    padding-top: 20px !important;
  }

  .pt-md-25 {
    padding-top: 25px !important;
  }

  .pt-md-30 {
    padding-top: 30px !important;
  }

  .pt-md-35 {
    padding-top: 35px !important;
  }

  .pt-md-40 {
    padding-top: 40px !important;
  }

  .pt-md-45 {
    padding-top: 45px !important;
  }

  .pt-md-50 {
    padding-top: 50px !important;
  }

  .pt-md-55 {
    padding-top: 55px !important;
  }

  .pt-md-60 {
    padding-top: 60px !important;
  }

  .pt-md-65 {
    padding-top: 65px !important;
  }

  .pt-md-70 {
    padding-top: 70px !important;
  }

  .pt-md-75 {
    padding-top: 75px !important;
  }

  .pt-md-80 {
    padding-top: 80px !important;
  }

  .pt-md-85 {
    padding-top: 85px !important;
  }

  .pt-md-90 {
    padding-top: 90px !important;
  }

  .pt-md-95 {
    padding-top: 95px !important;
  }

  .pt-md-100 {
    padding-top: 100px !important;
  }

  .pb-md-0 {
    padding-bottom: 0px !important;
  }

  .pb-md-5 {
    padding-bottom: 5px !important;
  }

  .pb-md-10 {
    padding-bottom: 10px !important;
  }

  .pb-md-15 {
    padding-bottom: 15px !important;
  }

  .pb-md-20 {
    padding-bottom: 20px !important;
  }

  .pb-md-25 {
    padding-bottom: 25px !important;
  }

  .pb-md-30 {
    padding-bottom: 30px !important;
  }

  .pb-md-35 {
    padding-bottom: 35px !important;
  }

  .pb-md-40 {
    padding-bottom: 40px !important;
  }

  .pb-md-45 {
    padding-bottom: 45px !important;
  }

  .pb-md-50 {
    padding-bottom: 50px !important;
  }

  .pb-md-55 {
    padding-bottom: 55px !important;
  }

  .pb-md-60 {
    padding-bottom: 60px !important;
  }

  .pb-md-65 {
    padding-bottom: 65px !important;
  }

  .pb-md-70 {
    padding-bottom: 70px !important;
  }

  .pb-md-75 {
    padding-bottom: 75px !important;
  }

  .pb-md-80 {
    padding-bottom: 80px !important;
  }

  .pb-md-85 {
    padding-bottom: 85px !important;
  }

  .pb-md-90 {
    padding-bottom: 90px !important;
  }

  .pb-md-95 {
    padding-bottom: 95px !important;
  }

  .pb-md-100 {
    padding-bottom: 100px !important;
  }

  .pl-md-0 {
    padding-left: 0px !important;
  }

  .pl-md-5 {
    padding-left: 5px !important;
  }

  .pl-md-10 {
    padding-left: 10px !important;
  }

  .pl-md-15 {
    padding-left: 15px !important;
  }

  .pl-md-20 {
    padding-left: 20px !important;
  }

  .pl-md-25 {
    padding-left: 25px !important;
  }

  .pl-md-30 {
    padding-left: 30px !important;
  }

  .pl-md-35 {
    padding-left: 35px !important;
  }

  .pl-md-40 {
    padding-left: 40px !important;
  }

  .pl-md-45 {
    padding-left: 45px !important;
  }

  .pl-md-50 {
    padding-left: 50px !important;
  }

  .pl-md-55 {
    padding-left: 55px !important;
  }

  .pl-md-60 {
    padding-left: 60px !important;
  }

  .pl-md-65 {
    padding-left: 65px !important;
  }

  .pl-md-70 {
    padding-left: 70px !important;
  }

  .pl-md-75 {
    padding-left: 75px !important;
  }

  .pl-md-80 {
    padding-left: 80px !important;
  }

  .pl-md-85 {
    padding-left: 85px !important;
  }

  .pl-md-90 {
    padding-left: 90px !important;
  }

  .pl-md-95 {
    padding-left: 95px !important;
  }

  .pl-md-100 {
    padding-left: 100px !important;
  }

  .pr-md-0 {
    padding-right: 0px !important;
  }

  .pr-md-5 {
    padding-right: 5px !important;
  }

  .pr-md-10 {
    padding-right: 10px !important;
  }

  .pr-md-15 {
    padding-right: 15px !important;
  }

  .pr-md-20 {
    padding-right: 20px !important;
  }

  .pr-md-25 {
    padding-right: 25px !important;
  }

  .pr-md-30 {
    padding-right: 30px !important;
  }

  .pr-md-35 {
    padding-right: 35px !important;
  }

  .pr-md-40 {
    padding-right: 40px !important;
  }

  .pr-md-45 {
    padding-right: 45px !important;
  }

  .pr-md-50 {
    padding-right: 50px !important;
  }

  .pr-md-55 {
    padding-right: 55px !important;
  }

  .pr-md-60 {
    padding-right: 60px !important;
  }

  .pr-md-65 {
    padding-right: 65px !important;
  }

  .pr-md-70 {
    padding-right: 70px !important;
  }

  .pr-md-75 {
    padding-right: 75px !important;
  }

  .pr-md-80 {
    padding-right: 80px !important;
  }

  .pr-md-85 {
    padding-right: 85px !important;
  }

  .pr-md-90 {
    padding-right: 90px !important;
  }

  .pr-md-95 {
    padding-right: 95px !important;
  }

  .pr-md-100 {
    padding-right: 100px !important;
  }

  .px-md-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-md-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .px-md-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .px-md-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .px-md-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-md-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .px-md-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-md-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .px-md-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-md-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .px-md-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .px-md-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-md-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .px-md-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .px-md-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .px-md-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .px-md-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .px-md-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .px-md-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .px-md-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .py-md-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .py-md-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-md-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-md-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-md-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-md-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-md-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-md-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-md-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .py-md-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .py-md-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-md-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .py-md-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .py-md-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .py-md-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .py-md-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .py-md-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .py-md-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .py-md-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .pa-lg-0 {
    padding: 0px !important;
  }

  .pa-lg-5 {
    padding: 5px !important;
  }

  .pa-lg-10 {
    padding: 10px !important;
  }

  .pa-lg-15 {
    padding: 15px !important;
  }

  .pa-lg-20 {
    padding: 20px !important;
  }

  .pa-lg-25 {
    padding: 25px !important;
  }

  .pa-lg-30 {
    padding: 30px !important;
  }

  .pa-lg-35 {
    padding: 35px !important;
  }

  .pa-lg-40 {
    padding: 40px !important;
  }

  .pa-lg-45 {
    padding: 45px !important;
  }

  .pa-lg-50 {
    padding: 50px !important;
  }

  .pa-lg-55 {
    padding: 55px !important;
  }

  .pa-lg-60 {
    padding: 60px !important;
  }

  .pa-lg-65 {
    padding: 65px !important;
  }

  .pa-lg-70 {
    padding: 70px !important;
  }

  .pa-lg-75 {
    padding: 75px !important;
  }

  .pa-lg-80 {
    padding: 80px !important;
  }

  .pa-lg-85 {
    padding: 85px !important;
  }

  .pa-lg-90 {
    padding: 90px !important;
  }

  .pa-lg-95 {
    padding: 95px !important;
  }

  .pa-lg-100 {
    padding: 100px !important;
  }

  .pt-lg-0 {
    padding-top: 0px !important;
  }

  .pt-lg-5 {
    padding-top: 5px !important;
  }

  .pt-lg-10 {
    padding-top: 10px !important;
  }

  .pt-lg-15 {
    padding-top: 15px !important;
  }

  .pt-lg-20 {
    padding-top: 20px !important;
  }

  .pt-lg-25 {
    padding-top: 25px !important;
  }

  .pt-lg-30 {
    padding-top: 30px !important;
  }

  .pt-lg-35 {
    padding-top: 35px !important;
  }

  .pt-lg-40 {
    padding-top: 40px !important;
  }

  .pt-lg-45 {
    padding-top: 45px !important;
  }

  .pt-lg-50 {
    padding-top: 50px !important;
  }

  .pt-lg-55 {
    padding-top: 55px !important;
  }

  .pt-lg-60 {
    padding-top: 60px !important;
  }

  .pt-lg-65 {
    padding-top: 65px !important;
  }

  .pt-lg-70 {
    padding-top: 70px !important;
  }

  .pt-lg-75 {
    padding-top: 75px !important;
  }

  .pt-lg-80 {
    padding-top: 80px !important;
  }

  .pt-lg-85 {
    padding-top: 85px !important;
  }

  .pt-lg-90 {
    padding-top: 90px !important;
  }

  .pt-lg-95 {
    padding-top: 95px !important;
  }

  .pt-lg-100 {
    padding-top: 100px !important;
  }

  .pb-lg-0 {
    padding-bottom: 0px !important;
  }

  .pb-lg-5 {
    padding-bottom: 5px !important;
  }

  .pb-lg-10 {
    padding-bottom: 10px !important;
  }

  .pb-lg-15 {
    padding-bottom: 15px !important;
  }

  .pb-lg-20 {
    padding-bottom: 20px !important;
  }

  .pb-lg-25 {
    padding-bottom: 25px !important;
  }

  .pb-lg-30 {
    padding-bottom: 30px !important;
  }

  .pb-lg-35 {
    padding-bottom: 35px !important;
  }

  .pb-lg-40 {
    padding-bottom: 40px !important;
  }

  .pb-lg-45 {
    padding-bottom: 45px !important;
  }

  .pb-lg-50 {
    padding-bottom: 50px !important;
  }

  .pb-lg-55 {
    padding-bottom: 55px !important;
  }

  .pb-lg-60 {
    padding-bottom: 60px !important;
  }

  .pb-lg-65 {
    padding-bottom: 65px !important;
  }

  .pb-lg-70 {
    padding-bottom: 70px !important;
  }

  .pb-lg-75 {
    padding-bottom: 75px !important;
  }

  .pb-lg-80 {
    padding-bottom: 80px !important;
  }

  .pb-lg-85 {
    padding-bottom: 85px !important;
  }

  .pb-lg-90 {
    padding-bottom: 90px !important;
  }

  .pb-lg-95 {
    padding-bottom: 95px !important;
  }

  .pb-lg-100 {
    padding-bottom: 100px !important;
  }

  .pl-lg-0 {
    padding-left: 0px !important;
  }

  .pl-lg-5 {
    padding-left: 5px !important;
  }

  .pl-lg-10 {
    padding-left: 10px !important;
  }

  .pl-lg-15 {
    padding-left: 15px !important;
  }

  .pl-lg-20 {
    padding-left: 20px !important;
  }

  .pl-lg-25 {
    padding-left: 25px !important;
  }

  .pl-lg-30 {
    padding-left: 30px !important;
  }

  .pl-lg-35 {
    padding-left: 35px !important;
  }

  .pl-lg-40 {
    padding-left: 40px !important;
  }

  .pl-lg-45 {
    padding-left: 45px !important;
  }

  .pl-lg-50 {
    padding-left: 50px !important;
  }

  .pl-lg-55 {
    padding-left: 55px !important;
  }

  .pl-lg-60 {
    padding-left: 60px !important;
  }

  .pl-lg-65 {
    padding-left: 65px !important;
  }

  .pl-lg-70 {
    padding-left: 70px !important;
  }

  .pl-lg-75 {
    padding-left: 75px !important;
  }

  .pl-lg-80 {
    padding-left: 80px !important;
  }

  .pl-lg-85 {
    padding-left: 85px !important;
  }

  .pl-lg-90 {
    padding-left: 90px !important;
  }

  .pl-lg-95 {
    padding-left: 95px !important;
  }

  .pl-lg-100 {
    padding-left: 100px !important;
  }

  .pr-lg-0 {
    padding-right: 0px !important;
  }

  .pr-lg-5 {
    padding-right: 5px !important;
  }

  .pr-lg-10 {
    padding-right: 10px !important;
  }

  .pr-lg-15 {
    padding-right: 15px !important;
  }

  .pr-lg-20 {
    padding-right: 20px !important;
  }

  .pr-lg-25 {
    padding-right: 25px !important;
  }

  .pr-lg-30 {
    padding-right: 30px !important;
  }

  .pr-lg-35 {
    padding-right: 35px !important;
  }

  .pr-lg-40 {
    padding-right: 40px !important;
  }

  .pr-lg-45 {
    padding-right: 45px !important;
  }

  .pr-lg-50 {
    padding-right: 50px !important;
  }

  .pr-lg-55 {
    padding-right: 55px !important;
  }

  .pr-lg-60 {
    padding-right: 60px !important;
  }

  .pr-lg-65 {
    padding-right: 65px !important;
  }

  .pr-lg-70 {
    padding-right: 70px !important;
  }

  .pr-lg-75 {
    padding-right: 75px !important;
  }

  .pr-lg-80 {
    padding-right: 80px !important;
  }

  .pr-lg-85 {
    padding-right: 85px !important;
  }

  .pr-lg-90 {
    padding-right: 90px !important;
  }

  .pr-lg-95 {
    padding-right: 95px !important;
  }

  .pr-lg-100 {
    padding-right: 100px !important;
  }

  .px-lg-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-lg-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .px-lg-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .px-lg-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .px-lg-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-lg-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .px-lg-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-lg-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .px-lg-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-lg-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .px-lg-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .px-lg-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .px-lg-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-lg-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .px-lg-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .px-lg-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .px-lg-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .px-lg-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .px-lg-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .px-lg-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .px-lg-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .py-lg-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .py-lg-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-lg-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-lg-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-lg-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-lg-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-lg-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-lg-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-lg-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .py-lg-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .py-lg-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .py-lg-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-lg-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .py-lg-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .py-lg-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .py-lg-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .py-lg-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .py-lg-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .py-lg-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .py-lg-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
@media (min-width: 1200px) {
  .pa-xl-0 {
    padding: 0px !important;
  }

  .pa-xl-5 {
    padding: 5px !important;
  }

  .pa-xl-10 {
    padding: 10px !important;
  }

  .pa-xl-15 {
    padding: 15px !important;
  }

  .pa-xl-20 {
    padding: 20px !important;
  }

  .pa-xl-25 {
    padding: 25px !important;
  }

  .pa-xl-30 {
    padding: 30px !important;
  }

  .pa-xl-35 {
    padding: 35px !important;
  }

  .pa-xl-40 {
    padding: 40px !important;
  }

  .pa-xl-45 {
    padding: 45px !important;
  }

  .pa-xl-50 {
    padding: 50px !important;
  }

  .pa-xl-55 {
    padding: 55px !important;
  }

  .pa-xl-60 {
    padding: 60px !important;
  }

  .pa-xl-65 {
    padding: 65px !important;
  }

  .pa-xl-70 {
    padding: 70px !important;
  }

  .pa-xl-75 {
    padding: 75px !important;
  }

  .pa-xl-80 {
    padding: 80px !important;
  }

  .pa-xl-85 {
    padding: 85px !important;
  }

  .pa-xl-90 {
    padding: 90px !important;
  }

  .pa-xl-95 {
    padding: 95px !important;
  }

  .pa-xl-100 {
    padding: 100px !important;
  }

  .pt-xl-0 {
    padding-top: 0px !important;
  }

  .pt-xl-5 {
    padding-top: 5px !important;
  }

  .pt-xl-10 {
    padding-top: 10px !important;
  }

  .pt-xl-15 {
    padding-top: 15px !important;
  }

  .pt-xl-20 {
    padding-top: 20px !important;
  }

  .pt-xl-25 {
    padding-top: 25px !important;
  }

  .pt-xl-30 {
    padding-top: 30px !important;
  }

  .pt-xl-35 {
    padding-top: 35px !important;
  }

  .pt-xl-40 {
    padding-top: 40px !important;
  }

  .pt-xl-45 {
    padding-top: 45px !important;
  }

  .pt-xl-50 {
    padding-top: 50px !important;
  }

  .pt-xl-55 {
    padding-top: 55px !important;
  }

  .pt-xl-60 {
    padding-top: 60px !important;
  }

  .pt-xl-65 {
    padding-top: 65px !important;
  }

  .pt-xl-70 {
    padding-top: 70px !important;
  }

  .pt-xl-75 {
    padding-top: 75px !important;
  }

  .pt-xl-80 {
    padding-top: 80px !important;
  }

  .pt-xl-85 {
    padding-top: 85px !important;
  }

  .pt-xl-90 {
    padding-top: 90px !important;
  }

  .pt-xl-95 {
    padding-top: 95px !important;
  }

  .pt-xl-100 {
    padding-top: 100px !important;
  }

  .pb-xl-0 {
    padding-bottom: 0px !important;
  }

  .pb-xl-5 {
    padding-bottom: 5px !important;
  }

  .pb-xl-10 {
    padding-bottom: 10px !important;
  }

  .pb-xl-15 {
    padding-bottom: 15px !important;
  }

  .pb-xl-20 {
    padding-bottom: 20px !important;
  }

  .pb-xl-25 {
    padding-bottom: 25px !important;
  }

  .pb-xl-30 {
    padding-bottom: 30px !important;
  }

  .pb-xl-35 {
    padding-bottom: 35px !important;
  }

  .pb-xl-40 {
    padding-bottom: 40px !important;
  }

  .pb-xl-45 {
    padding-bottom: 45px !important;
  }

  .pb-xl-50 {
    padding-bottom: 50px !important;
  }

  .pb-xl-55 {
    padding-bottom: 55px !important;
  }

  .pb-xl-60 {
    padding-bottom: 60px !important;
  }

  .pb-xl-65 {
    padding-bottom: 65px !important;
  }

  .pb-xl-70 {
    padding-bottom: 70px !important;
  }

  .pb-xl-75 {
    padding-bottom: 75px !important;
  }

  .pb-xl-80 {
    padding-bottom: 80px !important;
  }

  .pb-xl-85 {
    padding-bottom: 85px !important;
  }

  .pb-xl-90 {
    padding-bottom: 90px !important;
  }

  .pb-xl-95 {
    padding-bottom: 95px !important;
  }

  .pb-xl-100 {
    padding-bottom: 100px !important;
  }

  .pl-xl-0 {
    padding-left: 0px !important;
  }

  .pl-xl-5 {
    padding-left: 5px !important;
  }

  .pl-xl-10 {
    padding-left: 10px !important;
  }

  .pl-xl-15 {
    padding-left: 15px !important;
  }

  .pl-xl-20 {
    padding-left: 20px !important;
  }

  .pl-xl-25 {
    padding-left: 25px !important;
  }

  .pl-xl-30 {
    padding-left: 30px !important;
  }

  .pl-xl-35 {
    padding-left: 35px !important;
  }

  .pl-xl-40 {
    padding-left: 40px !important;
  }

  .pl-xl-45 {
    padding-left: 45px !important;
  }

  .pl-xl-50 {
    padding-left: 50px !important;
  }

  .pl-xl-55 {
    padding-left: 55px !important;
  }

  .pl-xl-60 {
    padding-left: 60px !important;
  }

  .pl-xl-65 {
    padding-left: 65px !important;
  }

  .pl-xl-70 {
    padding-left: 70px !important;
  }

  .pl-xl-75 {
    padding-left: 75px !important;
  }

  .pl-xl-80 {
    padding-left: 80px !important;
  }

  .pl-xl-85 {
    padding-left: 85px !important;
  }

  .pl-xl-90 {
    padding-left: 90px !important;
  }

  .pl-xl-95 {
    padding-left: 95px !important;
  }

  .pl-xl-100 {
    padding-left: 100px !important;
  }

  .pr-xl-0 {
    padding-right: 0px !important;
  }

  .pr-xl-5 {
    padding-right: 5px !important;
  }

  .pr-xl-10 {
    padding-right: 10px !important;
  }

  .pr-xl-15 {
    padding-right: 15px !important;
  }

  .pr-xl-20 {
    padding-right: 20px !important;
  }

  .pr-xl-25 {
    padding-right: 25px !important;
  }

  .pr-xl-30 {
    padding-right: 30px !important;
  }

  .pr-xl-35 {
    padding-right: 35px !important;
  }

  .pr-xl-40 {
    padding-right: 40px !important;
  }

  .pr-xl-45 {
    padding-right: 45px !important;
  }

  .pr-xl-50 {
    padding-right: 50px !important;
  }

  .pr-xl-55 {
    padding-right: 55px !important;
  }

  .pr-xl-60 {
    padding-right: 60px !important;
  }

  .pr-xl-65 {
    padding-right: 65px !important;
  }

  .pr-xl-70 {
    padding-right: 70px !important;
  }

  .pr-xl-75 {
    padding-right: 75px !important;
  }

  .pr-xl-80 {
    padding-right: 80px !important;
  }

  .pr-xl-85 {
    padding-right: 85px !important;
  }

  .pr-xl-90 {
    padding-right: 90px !important;
  }

  .pr-xl-95 {
    padding-right: 95px !important;
  }

  .pr-xl-100 {
    padding-right: 100px !important;
  }

  .px-xl-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-xl-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .px-xl-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .px-xl-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .px-xl-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-xl-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .px-xl-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-xl-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .px-xl-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .px-xl-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .px-xl-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .px-xl-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .px-xl-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .px-xl-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .px-xl-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .px-xl-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .px-xl-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .px-xl-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .px-xl-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .px-xl-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .px-xl-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .py-xl-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .py-xl-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .py-xl-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .py-xl-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .py-xl-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .py-xl-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .py-xl-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .py-xl-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .py-xl-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .py-xl-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .py-xl-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .py-xl-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .py-xl-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .py-xl-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .py-xl-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .py-xl-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .py-xl-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .py-xl-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .py-xl-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .py-xl-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .py-xl-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
/*Margin*/
.ma-0 {
  margin: 0px !important;
}

.ma-5 {
  margin: 5px !important;
}

.ma-10 {
  margin: 10px !important;
}

.ma-15 {
  margin: 15px !important;
}

.ma-20 {
  margin: 20px !important;
}

.ma-25 {
  margin: 25px !important;
}

.ma-30 {
  margin: 30px !important;
}

.ma-35 {
  margin: 35px !important;
}

.ma-40 {
  margin: 40px !important;
}

.ma-45 {
  margin: 45px !important;
}

.ma-50 {
  margin: 50px !important;
}

.ma-55 {
  margin: 55px !important;
}

.ma-60 {
  margin: 60px !important;
}

.ma-65 {
  margin: 65px !important;
}

.ma-70 {
  margin: 70px !important;
}

.ma-75 {
  margin: 75px !important;
}

.ma-80 {
  margin: 80px !important;
}

.ma-85 {
  margin: 85px !important;
}

.ma-90 {
  margin: 90px !important;
}

.ma-95 {
  margin: 95px !important;
}

.ma-100 {
  margin: 100px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mx-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mx-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mx-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mx-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mx-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mx-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mx-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mx-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mx-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mx-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mx-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mx-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mx-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.my-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.my-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.my-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.my-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.my-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.my-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.my-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.my-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.my-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.my-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.my-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

@media (min-width: 576px) {
  .ma-sm-0 {
    margin: 0px !important;
  }

  .ma-sm-5 {
    margin: 5px !important;
  }

  .ma-sm-10 {
    margin: 10px !important;
  }

  .ma-sm-15 {
    margin: 15px !important;
  }

  .ma-sm-20 {
    margin: 20px !important;
  }

  .ma-sm-25 {
    margin: 25px !important;
  }

  .ma-sm-30 {
    margin: 30px !important;
  }

  .ma-sm-35 {
    margin: 35px !important;
  }

  .ma-sm-40 {
    margin: 40px !important;
  }

  .ma-sm-45 {
    margin: 45px !important;
  }

  .ma-sm-50 {
    margin: 50px !important;
  }

  .ma-sm-55 {
    margin: 55px !important;
  }

  .ma-sm-60 {
    margin: 60px !important;
  }

  .ma-sm-65 {
    margin: 65px !important;
  }

  .ma-sm-70 {
    margin: 70px !important;
  }

  .ma-sm-75 {
    margin: 75px !important;
  }

  .ma-sm-80 {
    margin: 80px !important;
  }

  .ma-sm-85 {
    margin: 85px !important;
  }

  .ma-sm-90 {
    margin: 90px !important;
  }

  .ma-sm-95 {
    margin: 95px !important;
  }

  .ma-sm-100 {
    margin: 100px !important;
  }

  .mt-sm-0 {
    margin-top: 0px !important;
  }

  .mt-sm-5 {
    margin-top: 5px !important;
  }

  .mt-sm-10 {
    margin-top: 10px !important;
  }

  .mt-sm-15 {
    margin-top: 15px !important;
  }

  .mt-sm-20 {
    margin-top: 20px !important;
  }

  .mt-sm-25 {
    margin-top: 25px !important;
  }

  .mt-sm-30 {
    margin-top: 30px !important;
  }

  .mt-sm-35 {
    margin-top: 35px !important;
  }

  .mt-sm-40 {
    margin-top: 40px !important;
  }

  .mt-sm-45 {
    margin-top: 45px !important;
  }

  .mt-sm-50 {
    margin-top: 50px !important;
  }

  .mt-sm-55 {
    margin-top: 55px !important;
  }

  .mt-sm-60 {
    margin-top: 60px !important;
  }

  .mt-sm-65 {
    margin-top: 65px !important;
  }

  .mt-sm-70 {
    margin-top: 70px !important;
  }

  .mt-sm-75 {
    margin-top: 75px !important;
  }

  .mt-sm-80 {
    margin-top: 80px !important;
  }

  .mt-sm-85 {
    margin-top: 85px !important;
  }

  .mt-sm-90 {
    margin-top: 90px !important;
  }

  .mt-sm-95 {
    margin-top: 95px !important;
  }

  .mt-sm-100 {
    margin-top: 100px !important;
  }

  .mb-sm-0 {
    margin-bottom: 0px !important;
  }

  .mb-sm-5 {
    margin-bottom: 5px !important;
  }

  .mb-sm-10 {
    margin-bottom: 10px !important;
  }

  .mb-sm-15 {
    margin-bottom: 15px !important;
  }

  .mb-sm-20 {
    margin-bottom: 20px !important;
  }

  .mb-sm-25 {
    margin-bottom: 25px !important;
  }

  .mb-sm-30 {
    margin-bottom: 30px !important;
  }

  .mb-sm-35 {
    margin-bottom: 35px !important;
  }

  .mb-sm-40 {
    margin-bottom: 40px !important;
  }

  .mb-sm-45 {
    margin-bottom: 45px !important;
  }

  .mb-sm-50 {
    margin-bottom: 50px !important;
  }

  .mb-sm-55 {
    margin-bottom: 55px !important;
  }

  .mb-sm-60 {
    margin-bottom: 60px !important;
  }

  .mb-sm-65 {
    margin-bottom: 65px !important;
  }

  .mb-sm-70 {
    margin-bottom: 70px !important;
  }

  .mb-sm-75 {
    margin-bottom: 75px !important;
  }

  .mb-sm-80 {
    margin-bottom: 80px !important;
  }

  .mb-sm-85 {
    margin-bottom: 85px !important;
  }

  .mb-sm-90 {
    margin-bottom: 90px !important;
  }

  .mb-sm-95 {
    margin-bottom: 95px !important;
  }

  .mb-sm-100 {
    margin-bottom: 100px !important;
  }

  .ml-sm-0 {
    margin-left: 0px !important;
  }

  .ml-sm-5 {
    margin-left: 5px !important;
  }

  .ml-sm-10 {
    margin-left: 10px !important;
  }

  .ml-sm-15 {
    margin-left: 15px !important;
  }

  .ml-sm-20 {
    margin-left: 20px !important;
  }

  .ml-sm-25 {
    margin-left: 25px !important;
  }

  .ml-sm-30 {
    margin-left: 30px !important;
  }

  .ml-sm-35 {
    margin-left: 35px !important;
  }

  .ml-sm-40 {
    margin-left: 40px !important;
  }

  .ml-sm-45 {
    margin-left: 45px !important;
  }

  .ml-sm-50 {
    margin-left: 50px !important;
  }

  .ml-sm-55 {
    margin-left: 55px !important;
  }

  .ml-sm-60 {
    margin-left: 60px !important;
  }

  .ml-sm-65 {
    margin-left: 65px !important;
  }

  .ml-sm-70 {
    margin-left: 70px !important;
  }

  .ml-sm-75 {
    margin-left: 75px !important;
  }

  .ml-sm-80 {
    margin-left: 80px !important;
  }

  .ml-sm-85 {
    margin-left: 85px !important;
  }

  .ml-sm-90 {
    margin-left: 90px !important;
  }

  .ml-sm-95 {
    margin-left: 95px !important;
  }

  .ml-sm-100 {
    margin-left: 100px !important;
  }

  .mr-sm-0 {
    margin-right: 0px !important;
  }

  .mr-sm-5 {
    margin-right: 5px !important;
  }

  .mr-sm-10 {
    margin-right: 10px !important;
  }

  .mr-sm-15 {
    margin-right: 15px !important;
  }

  .mr-sm-20 {
    margin-right: 20px !important;
  }

  .mr-sm-25 {
    margin-right: 25px !important;
  }

  .mr-sm-30 {
    margin-right: 30px !important;
  }

  .mr-sm-35 {
    margin-right: 35px !important;
  }

  .mr-sm-40 {
    margin-right: 40px !important;
  }

  .mr-sm-45 {
    margin-right: 45px !important;
  }

  .mr-sm-50 {
    margin-right: 50px !important;
  }

  .mr-sm-55 {
    margin-right: 55px !important;
  }

  .mr-sm-60 {
    margin-right: 60px !important;
  }

  .mr-sm-65 {
    margin-right: 65px !important;
  }

  .mr-sm-70 {
    margin-right: 70px !important;
  }

  .mr-sm-75 {
    margin-right: 75px !important;
  }

  .mr-sm-80 {
    margin-right: 80px !important;
  }

  .mr-sm-85 {
    margin-right: 85px !important;
  }

  .mr-sm-90 {
    margin-right: 90px !important;
  }

  .mr-sm-95 {
    margin-right: 95px !important;
  }

  .mr-sm-100 {
    margin-right: 100px !important;
  }

  .mx-sm-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mx-sm-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mx-sm-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mx-sm-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mx-sm-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-sm-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mx-sm-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-sm-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mx-sm-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-sm-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mx-sm-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mx-sm-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mx-sm-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-sm-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mx-sm-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mx-sm-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mx-sm-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mx-sm-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mx-sm-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mx-sm-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mx-sm-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .my-sm-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .my-sm-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-sm-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-sm-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-sm-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-sm-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-sm-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-sm-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-sm-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .my-sm-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-sm-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .my-sm-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-sm-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .my-sm-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .my-sm-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .my-sm-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .my-sm-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .my-sm-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .my-sm-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .my-sm-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
@media (min-width: 768px) {
  .ma-md-0 {
    margin: 0px !important;
  }

  .ma-md-5 {
    margin: 5px !important;
  }

  .ma-md-10 {
    margin: 10px !important;
  }

  .ma-md-15 {
    margin: 15px !important;
  }

  .ma-md-20 {
    margin: 20px !important;
  }

  .ma-md-25 {
    margin: 25px !important;
  }

  .ma-md-30 {
    margin: 30px !important;
  }

  .ma-md-35 {
    margin: 35px !important;
  }

  .ma-md-40 {
    margin: 40px !important;
  }

  .ma-md-45 {
    margin: 45px !important;
  }

  .ma-md-50 {
    margin: 50px !important;
  }

  .ma-md-55 {
    margin: 55px !important;
  }

  .ma-md-60 {
    margin: 60px !important;
  }

  .ma-md-65 {
    margin: 65px !important;
  }

  .ma-md-70 {
    margin: 70px !important;
  }

  .ma-md-75 {
    margin: 75px !important;
  }

  .ma-md-80 {
    margin: 80px !important;
  }

  .ma-md-85 {
    margin: 85px !important;
  }

  .ma-md-90 {
    margin: 90px !important;
  }

  .ma-md-95 {
    margin: 95px !important;
  }

  .ma-md-100 {
    margin: 100px !important;
  }

  .mt-md-0 {
    margin-top: 0px !important;
  }

  .mt-md-5 {
    margin-top: 5px !important;
  }

  .mt-md-10 {
    margin-top: 10px !important;
  }

  .mt-md-15 {
    margin-top: 15px !important;
  }

  .mt-md-20 {
    margin-top: 20px !important;
  }

  .mt-md-25 {
    margin-top: 25px !important;
  }

  .mt-md-30 {
    margin-top: 30px !important;
  }

  .mt-md-35 {
    margin-top: 35px !important;
  }

  .mt-md-40 {
    margin-top: 40px !important;
  }

  .mt-md-45 {
    margin-top: 45px !important;
  }

  .mt-md-50 {
    margin-top: 50px !important;
  }

  .mt-md-55 {
    margin-top: 55px !important;
  }

  .mt-md-60 {
    margin-top: 60px !important;
  }

  .mt-md-65 {
    margin-top: 65px !important;
  }

  .mt-md-70 {
    margin-top: 70px !important;
  }

  .mt-md-75 {
    margin-top: 75px !important;
  }

  .mt-md-80 {
    margin-top: 80px !important;
  }

  .mt-md-85 {
    margin-top: 85px !important;
  }

  .mt-md-90 {
    margin-top: 90px !important;
  }

  .mt-md-95 {
    margin-top: 95px !important;
  }

  .mt-md-100 {
    margin-top: 100px !important;
  }

  .mb-md-0 {
    margin-bottom: 0px !important;
  }

  .mb-md-5 {
    margin-bottom: 5px !important;
  }

  .mb-md-10 {
    margin-bottom: 10px !important;
  }

  .mb-md-15 {
    margin-bottom: 15px !important;
  }

  .mb-md-20 {
    margin-bottom: 20px !important;
  }

  .mb-md-25 {
    margin-bottom: 25px !important;
  }

  .mb-md-30 {
    margin-bottom: 30px !important;
  }

  .mb-md-35 {
    margin-bottom: 35px !important;
  }

  .mb-md-40 {
    margin-bottom: 40px !important;
  }

  .mb-md-45 {
    margin-bottom: 45px !important;
  }

  .mb-md-50 {
    margin-bottom: 50px !important;
  }

  .mb-md-55 {
    margin-bottom: 55px !important;
  }

  .mb-md-60 {
    margin-bottom: 60px !important;
  }

  .mb-md-65 {
    margin-bottom: 65px !important;
  }

  .mb-md-70 {
    margin-bottom: 70px !important;
  }

  .mb-md-75 {
    margin-bottom: 75px !important;
  }

  .mb-md-80 {
    margin-bottom: 80px !important;
  }

  .mb-md-85 {
    margin-bottom: 85px !important;
  }

  .mb-md-90 {
    margin-bottom: 90px !important;
  }

  .mb-md-95 {
    margin-bottom: 95px !important;
  }

  .mb-md-100 {
    margin-bottom: 100px !important;
  }

  .ml-md-0 {
    margin-left: 0px !important;
  }

  .ml-md-5 {
    margin-left: 5px !important;
  }

  .ml-md-10 {
    margin-left: 10px !important;
  }

  .ml-md-15 {
    margin-left: 15px !important;
  }

  .ml-md-20 {
    margin-left: 20px !important;
  }

  .ml-md-25 {
    margin-left: 25px !important;
  }

  .ml-md-30 {
    margin-left: 30px !important;
  }

  .ml-md-35 {
    margin-left: 35px !important;
  }

  .ml-md-40 {
    margin-left: 40px !important;
  }

  .ml-md-45 {
    margin-left: 45px !important;
  }

  .ml-md-50 {
    margin-left: 50px !important;
  }

  .ml-md-55 {
    margin-left: 55px !important;
  }

  .ml-md-60 {
    margin-left: 60px !important;
  }

  .ml-md-65 {
    margin-left: 65px !important;
  }

  .ml-md-70 {
    margin-left: 70px !important;
  }

  .ml-md-75 {
    margin-left: 75px !important;
  }

  .ml-md-80 {
    margin-left: 80px !important;
  }

  .ml-md-85 {
    margin-left: 85px !important;
  }

  .ml-md-90 {
    margin-left: 90px !important;
  }

  .ml-md-95 {
    margin-left: 95px !important;
  }

  .ml-md-100 {
    margin-left: 100px !important;
  }

  .mr-md-0 {
    margin-right: 0px !important;
  }

  .mr-md-5 {
    margin-right: 5px !important;
  }

  .mr-md-10 {
    margin-right: 10px !important;
  }

  .mr-md-15 {
    margin-right: 15px !important;
  }

  .mr-md-20 {
    margin-right: 20px !important;
  }

  .mr-md-25 {
    margin-right: 25px !important;
  }

  .mr-md-30 {
    margin-right: 30px !important;
  }

  .mr-md-35 {
    margin-right: 35px !important;
  }

  .mr-md-40 {
    margin-right: 40px !important;
  }

  .mr-md-45 {
    margin-right: 45px !important;
  }

  .mr-md-50 {
    margin-right: 50px !important;
  }

  .mr-md-55 {
    margin-right: 55px !important;
  }

  .mr-md-60 {
    margin-right: 60px !important;
  }

  .mr-md-65 {
    margin-right: 65px !important;
  }

  .mr-md-70 {
    margin-right: 70px !important;
  }

  .mr-md-75 {
    margin-right: 75px !important;
  }

  .mr-md-80 {
    margin-right: 80px !important;
  }

  .mr-md-85 {
    margin-right: 85px !important;
  }

  .mr-md-90 {
    margin-right: 90px !important;
  }

  .mr-md-95 {
    margin-right: 95px !important;
  }

  .mr-md-100 {
    margin-right: 100px !important;
  }

  .mx-md-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mx-md-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mx-md-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mx-md-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mx-md-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-md-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mx-md-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-md-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mx-md-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-md-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mx-md-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mx-md-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mx-md-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-md-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mx-md-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mx-md-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mx-md-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mx-md-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mx-md-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mx-md-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mx-md-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .my-md-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .my-md-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-md-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-md-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-md-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-md-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-md-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-md-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-md-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .my-md-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-md-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .my-md-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-md-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .my-md-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .my-md-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .my-md-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .my-md-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .my-md-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .my-md-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .my-md-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
@media (min-width: 992px) {
  .ma-lg-0 {
    margin: 0px !important;
  }

  .ma-lg-5 {
    margin: 5px !important;
  }

  .ma-lg-10 {
    margin: 10px !important;
  }

  .ma-lg-15 {
    margin: 15px !important;
  }

  .ma-lg-20 {
    margin: 20px !important;
  }

  .ma-lg-25 {
    margin: 25px !important;
  }

  .ma-lg-30 {
    margin: 30px !important;
  }

  .ma-lg-35 {
    margin: 35px !important;
  }

  .ma-lg-40 {
    margin: 40px !important;
  }

  .ma-lg-45 {
    margin: 45px !important;
  }

  .ma-lg-50 {
    margin: 50px !important;
  }

  .ma-lg-55 {
    margin: 55px !important;
  }

  .ma-lg-60 {
    margin: 60px !important;
  }

  .ma-lg-65 {
    margin: 65px !important;
  }

  .ma-lg-70 {
    margin: 70px !important;
  }

  .ma-lg-75 {
    margin: 75px !important;
  }

  .ma-lg-80 {
    margin: 80px !important;
  }

  .ma-lg-85 {
    margin: 85px !important;
  }

  .ma-lg-90 {
    margin: 90px !important;
  }

  .ma-lg-95 {
    margin: 95px !important;
  }

  .ma-lg-100 {
    margin: 100px !important;
  }

  .mt-lg-0 {
    margin-top: 0px !important;
  }

  .mt-lg-5 {
    margin-top: 5px !important;
  }

  .mt-lg-10 {
    margin-top: 10px !important;
  }

  .mt-lg-15 {
    margin-top: 15px !important;
  }

  .mt-lg-20 {
    margin-top: 20px !important;
  }

  .mt-lg-25 {
    margin-top: 25px !important;
  }

  .mt-lg-30 {
    margin-top: 30px !important;
  }

  .mt-lg-35 {
    margin-top: 35px !important;
  }

  .mt-lg-40 {
    margin-top: 40px !important;
  }

  .mt-lg-45 {
    margin-top: 45px !important;
  }

  .mt-lg-50 {
    margin-top: 50px !important;
  }

  .mt-lg-55 {
    margin-top: 55px !important;
  }

  .mt-lg-60 {
    margin-top: 60px !important;
  }

  .mt-lg-65 {
    margin-top: 65px !important;
  }

  .mt-lg-70 {
    margin-top: 70px !important;
  }

  .mt-lg-75 {
    margin-top: 75px !important;
  }

  .mt-lg-80 {
    margin-top: 80px !important;
  }

  .mt-lg-85 {
    margin-top: 85px !important;
  }

  .mt-lg-90 {
    margin-top: 90px !important;
  }

  .mt-lg-95 {
    margin-top: 95px !important;
  }

  .mt-lg-100 {
    margin-top: 100px !important;
  }

  .mb-lg-0 {
    margin-bottom: 0px !important;
  }

  .mb-lg-5 {
    margin-bottom: 5px !important;
  }

  .mb-lg-10 {
    margin-bottom: 10px !important;
  }

  .mb-lg-15 {
    margin-bottom: 15px !important;
  }

  .mb-lg-20 {
    margin-bottom: 20px !important;
  }

  .mb-lg-25 {
    margin-bottom: 25px !important;
  }

  .mb-lg-30 {
    margin-bottom: 30px !important;
  }

  .mb-lg-35 {
    margin-bottom: 35px !important;
  }

  .mb-lg-40 {
    margin-bottom: 40px !important;
  }

  .mb-lg-45 {
    margin-bottom: 45px !important;
  }

  .mb-lg-50 {
    margin-bottom: 50px !important;
  }

  .mb-lg-55 {
    margin-bottom: 55px !important;
  }

  .mb-lg-60 {
    margin-bottom: 60px !important;
  }

  .mb-lg-65 {
    margin-bottom: 65px !important;
  }

  .mb-lg-70 {
    margin-bottom: 70px !important;
  }

  .mb-lg-75 {
    margin-bottom: 75px !important;
  }

  .mb-lg-80 {
    margin-bottom: 80px !important;
  }

  .mb-lg-85 {
    margin-bottom: 85px !important;
  }

  .mb-lg-90 {
    margin-bottom: 90px !important;
  }

  .mb-lg-95 {
    margin-bottom: 95px !important;
  }

  .mb-lg-100 {
    margin-bottom: 100px !important;
  }

  .ml-lg-0 {
    margin-left: 0px !important;
  }

  .ml-lg-5 {
    margin-left: 5px !important;
  }

  .ml-lg-10 {
    margin-left: 10px !important;
  }

  .ml-lg-15 {
    margin-left: 15px !important;
  }

  .ml-lg-20 {
    margin-left: 20px !important;
  }

  .ml-lg-25 {
    margin-left: 25px !important;
  }

  .ml-lg-30 {
    margin-left: 30px !important;
  }

  .ml-lg-35 {
    margin-left: 35px !important;
  }

  .ml-lg-40 {
    margin-left: 40px !important;
  }

  .ml-lg-45 {
    margin-left: 45px !important;
  }

  .ml-lg-50 {
    margin-left: 50px !important;
  }

  .ml-lg-55 {
    margin-left: 55px !important;
  }

  .ml-lg-60 {
    margin-left: 60px !important;
  }

  .ml-lg-65 {
    margin-left: 65px !important;
  }

  .ml-lg-70 {
    margin-left: 70px !important;
  }

  .ml-lg-75 {
    margin-left: 75px !important;
  }

  .ml-lg-80 {
    margin-left: 80px !important;
  }

  .ml-lg-85 {
    margin-left: 85px !important;
  }

  .ml-lg-90 {
    margin-left: 90px !important;
  }

  .ml-lg-95 {
    margin-left: 95px !important;
  }

  .ml-lg-100 {
    margin-left: 100px !important;
  }

  .mr-lg-0 {
    margin-right: 0px !important;
  }

  .mr-lg-5 {
    margin-right: 5px !important;
  }

  .mr-lg-10 {
    margin-right: 10px !important;
  }

  .mr-lg-15 {
    margin-right: 15px !important;
  }

  .mr-lg-20 {
    margin-right: 20px !important;
  }

  .mr-lg-25 {
    margin-right: 25px !important;
  }

  .mr-lg-30 {
    margin-right: 30px !important;
  }

  .mr-lg-35 {
    margin-right: 35px !important;
  }

  .mr-lg-40 {
    margin-right: 40px !important;
  }

  .mr-lg-45 {
    margin-right: 45px !important;
  }

  .mr-lg-50 {
    margin-right: 50px !important;
  }

  .mr-lg-55 {
    margin-right: 55px !important;
  }

  .mr-lg-60 {
    margin-right: 60px !important;
  }

  .mr-lg-65 {
    margin-right: 65px !important;
  }

  .mr-lg-70 {
    margin-right: 70px !important;
  }

  .mr-lg-75 {
    margin-right: 75px !important;
  }

  .mr-lg-80 {
    margin-right: 80px !important;
  }

  .mr-lg-85 {
    margin-right: 85px !important;
  }

  .mr-lg-90 {
    margin-right: 90px !important;
  }

  .mr-lg-95 {
    margin-right: 95px !important;
  }

  .mr-lg-100 {
    margin-right: 100px !important;
  }

  .mx-lg-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mx-lg-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mx-lg-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mx-lg-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mx-lg-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-lg-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mx-lg-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-lg-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mx-lg-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-lg-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mx-lg-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mx-lg-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mx-lg-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-lg-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mx-lg-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mx-lg-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mx-lg-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mx-lg-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mx-lg-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mx-lg-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mx-lg-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .my-lg-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .my-lg-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-lg-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-lg-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-lg-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-lg-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-lg-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-lg-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-lg-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .my-lg-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-lg-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .my-lg-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-lg-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .my-lg-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .my-lg-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .my-lg-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .my-lg-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .my-lg-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .my-lg-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .my-lg-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
@media (min-width: 1200px) {
  .ma-xl-0 {
    margin: 0px !important;
  }

  .ma-xl-5 {
    margin: 5px !important;
  }

  .ma-xl-10 {
    margin: 10px !important;
  }

  .ma-xl-15 {
    margin: 15px !important;
  }

  .ma-xl-20 {
    margin: 20px !important;
  }

  .ma-xl-25 {
    margin: 25px !important;
  }

  .ma-xl-30 {
    margin: 30px !important;
  }

  .ma-xl-35 {
    margin: 35px !important;
  }

  .ma-xl-40 {
    margin: 40px !important;
  }

  .ma-xl-45 {
    margin: 45px !important;
  }

  .ma-xl-50 {
    margin: 50px !important;
  }

  .ma-xl-55 {
    margin: 55px !important;
  }

  .ma-xl-60 {
    margin: 60px !important;
  }

  .ma-xl-65 {
    margin: 65px !important;
  }

  .ma-xl-70 {
    margin: 70px !important;
  }

  .ma-xl-75 {
    margin: 75px !important;
  }

  .ma-xl-80 {
    margin: 80px !important;
  }

  .ma-xl-85 {
    margin: 85px !important;
  }

  .ma-xl-90 {
    margin: 90px !important;
  }

  .ma-xl-95 {
    margin: 95px !important;
  }

  .ma-xl-100 {
    margin: 100px !important;
  }

  .mt-xl-0 {
    margin-top: 0px !important;
  }

  .mt-xl-5 {
    margin-top: 5px !important;
  }

  .mt-xl-10 {
    margin-top: 10px !important;
  }

  .mt-xl-15 {
    margin-top: 15px !important;
  }

  .mt-xl-20 {
    margin-top: 20px !important;
  }

  .mt-xl-25 {
    margin-top: 25px !important;
  }

  .mt-xl-30 {
    margin-top: 30px !important;
  }

  .mt-xl-35 {
    margin-top: 35px !important;
  }

  .mt-xl-40 {
    margin-top: 40px !important;
  }

  .mt-xl-45 {
    margin-top: 45px !important;
  }

  .mt-xl-50 {
    margin-top: 50px !important;
  }

  .mt-xl-55 {
    margin-top: 55px !important;
  }

  .mt-xl-60 {
    margin-top: 60px !important;
  }

  .mt-xl-65 {
    margin-top: 65px !important;
  }

  .mt-xl-70 {
    margin-top: 70px !important;
  }

  .mt-xl-75 {
    margin-top: 75px !important;
  }

  .mt-xl-80 {
    margin-top: 80px !important;
  }

  .mt-xl-85 {
    margin-top: 85px !important;
  }

  .mt-xl-90 {
    margin-top: 90px !important;
  }

  .mt-xl-95 {
    margin-top: 95px !important;
  }

  .mt-xl-100 {
    margin-top: 100px !important;
  }

  .mb-xl-0 {
    margin-bottom: 0px !important;
  }

  .mb-xl-5 {
    margin-bottom: 5px !important;
  }

  .mb-xl-10 {
    margin-bottom: 10px !important;
  }

  .mb-xl-15 {
    margin-bottom: 15px !important;
  }

  .mb-xl-20 {
    margin-bottom: 20px !important;
  }

  .mb-xl-25 {
    margin-bottom: 25px !important;
  }

  .mb-xl-30 {
    margin-bottom: 30px !important;
  }

  .mb-xl-35 {
    margin-bottom: 35px !important;
  }

  .mb-xl-40 {
    margin-bottom: 40px !important;
  }

  .mb-xl-45 {
    margin-bottom: 45px !important;
  }

  .mb-xl-50 {
    margin-bottom: 50px !important;
  }

  .mb-xl-55 {
    margin-bottom: 55px !important;
  }

  .mb-xl-60 {
    margin-bottom: 60px !important;
  }

  .mb-xl-65 {
    margin-bottom: 65px !important;
  }

  .mb-xl-70 {
    margin-bottom: 70px !important;
  }

  .mb-xl-75 {
    margin-bottom: 75px !important;
  }

  .mb-xl-80 {
    margin-bottom: 80px !important;
  }

  .mb-xl-85 {
    margin-bottom: 85px !important;
  }

  .mb-xl-90 {
    margin-bottom: 90px !important;
  }

  .mb-xl-95 {
    margin-bottom: 95px !important;
  }

  .mb-xl-100 {
    margin-bottom: 100px !important;
  }

  .ml-xl-0 {
    margin-left: 0px !important;
  }

  .ml-xl-5 {
    margin-left: 5px !important;
  }

  .ml-xl-10 {
    margin-left: 10px !important;
  }

  .ml-xl-15 {
    margin-left: 15px !important;
  }

  .ml-xl-20 {
    margin-left: 20px !important;
  }

  .ml-xl-25 {
    margin-left: 25px !important;
  }

  .ml-xl-30 {
    margin-left: 30px !important;
  }

  .ml-xl-35 {
    margin-left: 35px !important;
  }

  .ml-xl-40 {
    margin-left: 40px !important;
  }

  .ml-xl-45 {
    margin-left: 45px !important;
  }

  .ml-xl-50 {
    margin-left: 50px !important;
  }

  .ml-xl-55 {
    margin-left: 55px !important;
  }

  .ml-xl-60 {
    margin-left: 60px !important;
  }

  .ml-xl-65 {
    margin-left: 65px !important;
  }

  .ml-xl-70 {
    margin-left: 70px !important;
  }

  .ml-xl-75 {
    margin-left: 75px !important;
  }

  .ml-xl-80 {
    margin-left: 80px !important;
  }

  .ml-xl-85 {
    margin-left: 85px !important;
  }

  .ml-xl-90 {
    margin-left: 90px !important;
  }

  .ml-xl-95 {
    margin-left: 95px !important;
  }

  .ml-xl-100 {
    margin-left: 100px !important;
  }

  .mr-xl-0 {
    margin-right: 0px !important;
  }

  .mr-xl-5 {
    margin-right: 5px !important;
  }

  .mr-xl-10 {
    margin-right: 10px !important;
  }

  .mr-xl-15 {
    margin-right: 15px !important;
  }

  .mr-xl-20 {
    margin-right: 20px !important;
  }

  .mr-xl-25 {
    margin-right: 25px !important;
  }

  .mr-xl-30 {
    margin-right: 30px !important;
  }

  .mr-xl-35 {
    margin-right: 35px !important;
  }

  .mr-xl-40 {
    margin-right: 40px !important;
  }

  .mr-xl-45 {
    margin-right: 45px !important;
  }

  .mr-xl-50 {
    margin-right: 50px !important;
  }

  .mr-xl-55 {
    margin-right: 55px !important;
  }

  .mr-xl-60 {
    margin-right: 60px !important;
  }

  .mr-xl-65 {
    margin-right: 65px !important;
  }

  .mr-xl-70 {
    margin-right: 70px !important;
  }

  .mr-xl-75 {
    margin-right: 75px !important;
  }

  .mr-xl-80 {
    margin-right: 80px !important;
  }

  .mr-xl-85 {
    margin-right: 85px !important;
  }

  .mr-xl-90 {
    margin-right: 90px !important;
  }

  .mr-xl-95 {
    margin-right: 95px !important;
  }

  .mr-xl-100 {
    margin-right: 100px !important;
  }

  .mx-xl-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mx-xl-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mx-xl-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mx-xl-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mx-xl-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mx-xl-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mx-xl-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mx-xl-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mx-xl-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mx-xl-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mx-xl-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mx-xl-55 {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mx-xl-60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mx-xl-65 {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mx-xl-70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mx-xl-75 {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mx-xl-80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mx-xl-85 {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mx-xl-90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mx-xl-95 {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mx-xl-100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .my-xl-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .my-xl-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .my-xl-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .my-xl-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .my-xl-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .my-xl-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .my-xl-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .my-xl-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .my-xl-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .my-xl-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .my-xl-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .my-xl-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .my-xl-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .my-xl-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .my-xl-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .my-xl-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .my-xl-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .my-xl-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .my-xl-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .my-xl-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .my-xl-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
}
/*Background Colors*/
.bg-sky-light-5 {
  background-color: #edf9fe !important;
}

.bg-sky-light-4 {
  background-color: #b2e6fa !important;
}

.bg-sky-light-3 {
  background-color: #7fd5f7 !important;
}

.bg-sky-light-2 {
  background-color: #52c7f5 !important;
}

.bg-sky-light-1 {
  background-color: #21b7f2 !important;
}

.bg-sky,
.bg-primary {
  background-color: #f4623a !important;
}

.bg-sky-dark-5 {
  background-color: #075875 !important;
}

.bg-sky-dark-4 {
  background-color: #02729a !important;
}

.bg-sky-dark-3 {
  background-color: #0285b4 !important;
}

.bg-sky-dark-2 {
  background-color: #0293c9 !important;
}

.bg-sky-dark-1 {
  background-color: #00a0df !important;
}

.bg-green-light-5 {
  background-color: #eff9f2 !important;
}

.bg-green-light-4 {
  background-color: #bce7c7 !important;
}

.bg-green-light-3 {
  background-color: #90d7a3 !important;
}

.bg-green-light-2 {
  background-color: #69c982 !important;
}

.bg-green-light-1 {
  background-color: #3fb95f !important;
}

.bg-green,
.bg-success {
  background-color: #22af47 !important;
}

.bg-green-dark-5 {
  background-color: #05592b !important;
}

.bg-green-dark-4 {
  background-color: #056b33 !important;
}

.bg-green-dark-3 {
  background-color: #138431 !important;
}

.bg-green-dark-2 {
  background-color: #179237 !important;
}

.bg-green-dark-1 {
  background-color: #1aa23e !important;
}

.bg-yellow-light-5 {
  background-color: #fff8eb !important;
}

.bg-yellow-light-4 {
  background-color: #ffecc2 !important;
}

.bg-yellow-light-3 {
  background-color: #ffdf9a !important;
}

.bg-yellow-light-2 {
  background-color: #ffd477 !important;
}

.bg-yellow-light-1 {
  background-color: #ffc750 !important;
}

.bg-yellow,
.bg-warning {
  background-color: #ffbf36 !important;
}

.bg-yellow-dark-5 {
  background-color: #cb7e03 !important;
}

.bg-yellow-dark-4 {
  background-color: #eb9000 !important;
}

.bg-yellow-dark-3 {
  background-color: #faa000 !important;
}

.bg-yellow-dark-2 {
  background-color: #ffac19 !important;
}

.bg-yellow-dark-1 {
  background-color: #ffb71d !important;
}

.bg-red-light-5 {
  background-color: #fff1f1 !important;
}

.bg-red-light-4 {
  background-color: #fdc5c3 !important;
}

.bg-red-light-3 {
  background-color: #fb9f9b !important;
}

.bg-red-light-2 {
  background-color: #fa7d77 !important;
}

.bg-red-light-1 {
  background-color: #f95851 !important;
}

.bg-red,
.bg-danger {
  background-color: #f83f37 !important;
}

.bg-red-dark-5 {
  background-color: #8b0c12 !important;
}

.bg-red-dark-4 {
  background-color: #b10911 !important;
}

.bg-red-dark-3 {
  background-color: #cf020d !important;
}

.bg-red-dark-2 {
  background-color: #ed1622 !important;
}

.bg-red-dark-1 {
  background-color: #ff2f26 !important;
}

.bg-blue-light-5 {
  background-color: #edf7fe !important;
}

.bg-blue-light-4 {
  background-color: #b2defa !important;
}

.bg-blue-light-3 {
  background-color: #7fc8f6 !important;
}

.bg-blue-light-2 {
  background-color: #52b5f3 !important;
}

.bg-blue-light-1 {
  background-color: #21a0f0 !important;
}

.bg-blue {
  background-color: #0092ee !important;
}

.bg-blue-dark-5 {
  background-color: #044067 !important;
}

.bg-blue-dark-4 {
  background-color: #004f82 !important;
}

.bg-blue-dark-3 {
  background-color: #0768a5 !important;
}

.bg-blue-dark-2 {
  background-color: #007fd0 !important;
}

.bg-blue-dark-1 {
  background-color: #0089e0 !important;
}

.bg-cyan-light-5 {
  background-color: #effafb !important;
}

.bg-cyan-light-4 {
  background-color: #bbebf0 !important;
}

.bg-cyan-light-3 {
  background-color: #8edde6 !important;
}

.bg-cyan-light-2 {
  background-color: #66d2dd !important;
}

.bg-cyan-light-1 {
  background-color: #3bc5d3 !important;
}

.bg-cyan,
.bg-info {
  background-color: #1ebccd !important;
}

.bg-cyan-dark-5 {
  background-color: #065459 !important;
}

.bg-cyan-dark-4 {
  background-color: #046b73 !important;
}

.bg-cyan-dark-3 {
  background-color: #0b858f !important;
}

.bg-cyan-dark-2 {
  background-color: #0e949f !important;
}

.bg-cyan-dark-1 {
  background-color: #16a9b9 !important;
}

.bg-grey-light-5,
.bg-secondary-light-5 {
  background-color: #f5f5f5 !important;
}

.bg-grey-light-4,
.bg-secondary-light-4 {
  background-color: #e2e2e2 !important;
}

.bg-grey-light-3,
.bg-secondary-light-3 {
  background-color: #cecece !important;
}

.bg-grey-light-2,
.bg-secondary-light-2 {
  background-color: #bdbdbd !important;
}

.bg-grey-light-1,
.bg-secondary-light-1 {
  background-color: #ababab !important;
}

.bg-secondary,
.bg-grey {
  background-color: #9e9e9e !important;
}

.bg-grey-dark-1,
.bg-secondary-dark-1 {
  background-color: #919191 !important;
}

.bg-grey-dark-2,
.bg-secondary-dark-2 {
  background-color: #858484 !important;
}

.bg-grey-dark-3,
.bg-secondary-dark-3 {
  background-color: #777474 !important;
}

.bg-grey-dark-4,
.bg-secondary-dark-4 {
  background-color: #666161 !important;
}

.bg-grey-dark-5,
.bg-secondary-dark-5 {
  background-color: #524c4c !important;
}

.bg-pink-light-5 {
  background-color: #feeff4 !important;
}

.bg-pink-light-4 {
  background-color: #fabacf !important;
}

.bg-pink-light-3 {
  background-color: #f68daf !important;
}

.bg-pink-light-2 {
  background-color: #f36493 !important;
}

.bg-pink-light-1 {
  background-color: #ef3975 !important;
}

.bg-pink {
  background-color: #ed1b60 !important;
}

.bg-pink-dark-5 {
  background-color: #6f1333 !important;
}

.bg-pink-dark-4 {
  background-color: #90143f !important;
}

.bg-pink-dark-3 {
  background-color: #a7194b !important;
}

.bg-pink-dark-2 {
  background-color: #be1b54 !important;
}

.bg-pink-dark-1 {
  background-color: #df195f !important;
}

.bg-purple-light-5 {
  background-color: #f9f0f9 !important;
}

.bg-purple-light-4 {
  background-color: #e6bde5 !important;
}

.bg-purple-light-3 {
  background-color: #d592d4 !important;
}

.bg-purple-light-2 {
  background-color: #c66cc5 !important;
}

.bg-purple-light-1 {
  background-color: #b642b5 !important;
}

.bg-purple {
  background-color: #ab26aa !important;
}

.bg-purple-dark-5 {
  background-color: #5d1864 !important;
}

.bg-purple-dark-4 {
  background-color: #701b76 !important;
}

.bg-purple-dark-3 {
  background-color: #7c2181 !important;
}

.bg-purple-dark-2 {
  background-color: #882787 !important;
}

.bg-purple-dark-1 {
  background-color: #9a3092 !important;
}

.bg-violet-light-5 {
  background-color: #f4f2fa !important;
}

.bg-violet-light-4 {
  background-color: #d1c5e8 !important;
}

.bg-violet-light-3 {
  background-color: #b29fd8 !important;
}

.bg-violet-light-2 {
  background-color: #977dcb !important;
}

.bg-violet-light-1 {
  background-color: #7a59bc !important;
}

.bg-violet {
  background-color: #6640b2 !important;
}

.bg-violet-dark-5 {
  background-color: #2d1453 !important;
}

.bg-violet-dark-4 {
  background-color: #3a196c !important;
}

.bg-violet-dark-3 {
  background-color: #482e7e !important;
}

.bg-violet-dark-2 {
  background-color: #5935a3 !important;
}

.bg-violet-dark-1 {
  background-color: #603bab !important;
}

.bg-indigo-light-5 {
  background-color: #f1f3f9 !important;
}

.bg-indigo-light-4 {
  background-color: #c4cce7 !important;
}

.bg-indigo-light-3 {
  background-color: #9caad8 !important;
}

.bg-indigo-light-2 {
  background-color: #798cca !important;
}

.bg-indigo-light-1 {
  background-color: #536bbb !important;
}

.bg-indigo {
  background-color: #3a55b1 !important;
}

.bg-indigo-dark-5 {
  background-color: #0a2042 !important;
}

.bg-indigo-dark-4 {
  background-color: #002663 !important;
}

.bg-indigo-dark-3 {
  background-color: #273c87 !important;
}

.bg-indigo-dark-2 {
  background-color: #2f4595 !important;
}

.bg-indigo-dark-1 {
  background-color: #3650ab !important;
}

.bg-teal-light-5 {
  background-color: #edf8f6 !important;
}

.bg-teal-light-4 {
  background-color: #b2e1da !important;
}

.bg-teal-light-3 {
  background-color: #7fcdc1 !important;
}

.bg-teal-light-2 {
  background-color: #52bbac !important;
}

.bg-teal-light-1 {
  background-color: #21a894 !important;
}

.bg-teal {
  background-color: #009b84 !important;
}

.bg-teal-dark-5 {
  background-color: #044b3b !important;
}

.bg-teal-dark-4 {
  background-color: #02624f !important;
}

.bg-teal-dark-3 {
  background-color: #026f58 !important;
}

.bg-teal-dark-2 {
  background-color: #028068 !important;
}

.bg-teal-dark-1 {
  background-color: #018975 !important;
}

.bg-neon-light-5 {
  background-color: #f3f9ec !important;
}

.bg-neon-light-4 {
  background-color: #dbedc6 !important;
}

.bg-neon-light-3 {
  background-color: #c3e0a0 !important;
}

.bg-neon-light-2 {
  background-color: #aed67e !important;
}

.bg-neon-light-1 {
  background-color: #97ca5a !important;
}

.bg-neon {
  background-color: #88c241 !important;
}

.bg-neon-dark-5 {
  background-color: #3d680f !important;
}

.bg-neon-dark-4 {
  background-color: #4c7f16 !important;
}

.bg-neon-dark-3 {
  background-color: #59970e !important;
}

.bg-neon-dark-2 {
  background-color: #67a41c !important;
}

.bg-neon-dark-1 {
  background-color: #79b62f !important;
}

.bg-lime-light-5 {
  background-color: #f9faeb !important;
}

.bg-lime-light-4 {
  background-color: #f1f4d0 !important;
}

.bg-lime-light-3 {
  background-color: #e7ecb0 !important;
}

.bg-lime-light-2 {
  background-color: #dfe594 !important;
}

.bg-lime-light-1 {
  background-color: #d6de76 !important;
}

.bg-lime {
  background-color: #d0d962 !important;
}

.bg-lime-dark-5 {
  background-color: #6f7208 !important;
}

.bg-lime-dark-4 {
  background-color: #878b09 !important;
}

.bg-lime-dark-3 {
  background-color: #9ea809 !important;
}

.bg-lime-dark-2 {
  background-color: #b7c11a !important;
}

.bg-lime-dark-1 {
  background-color: #c3cc34 !important;
}

.bg-sun-light-5 {
  background-color: #fffbe5 !important;
}

.bg-sun-light-4 {
  background-color: #fef7c2 !important;
}

.bg-sun-light-3 {
  background-color: #fef19a !important;
}

.bg-sun-light-2 {
  background-color: #feec76 !important;
}

.bg-sun-light-1 {
  background-color: #fde74f !important;
}

.bg-sun {
  background-color: #fde335 !important;
}

.bg-sun-dark-5 {
  background-color: #a48f03 !important;
}

.bg-sun-dark-4 {
  background-color: #bca300 !important;
}

.bg-sun-dark-3 {
  background-color: #cdb308 !important;
}

.bg-sun-dark-2 {
  background-color: #dfc414 !important;
}

.bg-sun-dark-1 {
  background-color: #ecd11e !important;
}

.bg-orange-light-5 {
  background-color: #fff4e9 !important;
}

.bg-orange-light-4 {
  background-color: #ffdfbe !important;
}

.bg-orange-light-3 {
  background-color: #ffca93 !important;
}

.bg-orange-light-2 {
  background-color: #ffb76d !important;
}

.bg-orange-light-1 {
  background-color: #ffa344 !important;
}

.bg-orange {
  background-color: #ff9528 !important;
}

.bg-orange-dark-5 {
  background-color: #c74c00 !important;
}

.bg-orange-dark-4 {
  background-color: #e05602 !important;
}

.bg-orange-dark-3 {
  background-color: #f16611 !important;
}

.bg-orange-dark-2 {
  background-color: #ff7800 !important;
}

.bg-orange-dark-1 {
  background-color: #ff880e !important;
}

.bg-pumpkin-light-5 {
  background-color: #fff4f0 !important;
}

.bg-pumpkin-light-4 {
  background-color: #ffcfbe !important;
}

.bg-pumpkin-light-3 {
  background-color: #ffaf93 !important;
}

.bg-pumpkin-light-2 {
  background-color: #ff936d !important;
}

.bg-pumpkin-light-1 {
  background-color: #ff7544 !important;
}

.bg-pumpkin {
  background-color: #ff6028 !important;
}

.bg-pumpkin-dark-5 {
  background-color: #ac2c00 !important;
}

.bg-pumpkin-dark-4 {
  background-color: #c63300 !important;
}

.bg-pumpkin-dark-3 {
  background-color: #dd3f08 !important;
}

.bg-pumpkin-dark-2 {
  background-color: #e8470f !important;
}

.bg-pumpkin-dark-1 {
  background-color: #fb551c !important;
}

.bg-brown-light-5 {
  background-color: #f6f3f2 !important;
}

.bg-brown-light-4 {
  background-color: #d7cbc8 !important;
}

.bg-brown-light-3 {
  background-color: #bca9a4 !important;
}

.bg-brown-light-2 {
  background-color: #a58b84 !important;
}

.bg-brown-light-1 {
  background-color: #8b6a61 !important;
}

.bg-brown {
  background-color: #7a5449 !important;
}

.bg-brown-dark-5 {
  background-color: #301811 !important;
}

.bg-brown-dark-4 {
  background-color: #44241b !important;
}

.bg-brown-dark-3 {
  background-color: #502f25 !important;
}

.bg-brown-dark-2 {
  background-color: #633d32 !important;
}

.bg-brown-dark-1 {
  background-color: #6f483d !important;
}

.bg-gold-light-5 {
  background-color: #fbf8f1 !important;
}

.bg-gold-light-4 {
  background-color: #ece0c5 !important;
}

.bg-gold-light-3 {
  background-color: #e0cc9f !important;
}

.bg-gold-light-2 {
  background-color: #d5ba7d !important;
}

.bg-gold-light-1 {
  background-color: #c9a658 !important;
}

.bg-gold {
  background-color: #c1993f !important;
}

.bg-gold-dark-5 {
  background-color: #6d4d05 !important;
}

.bg-gold-dark-4 {
  background-color: #85600d !important;
}

.bg-gold-dark-3 {
  background-color: #98731e !important;
}

.bg-gold-dark-2 {
  background-color: #a27b25 !important;
}

.bg-gold-dark-1 {
  background-color: #b48c32 !important;
}

.bg-smoke-light-5 {
  background-color: #f4f6f7 !important;
}

.bg-smoke-light-4 {
  background-color: #ced8dc !important;
}

.bg-smoke-light-3 {
  background-color: #aebec4 !important;
}

.bg-smoke-light-2 {
  background-color: #92a7b0 !important;
}

.bg-smoke-light-1 {
  background-color: #738e99 !important;
}

.bg-smoke {
  background-color: #5e7d8a !important;
}

.bg-smoke-dark-5 {
  background-color: #172a32 !important;
}

.bg-smoke-dark-4 {
  background-color: #233c46 !important;
}

.bg-smoke-dark-3 {
  background-color: #304e5a !important;
}

.bg-smoke-dark-2 {
  background-color: #40606d !important;
}

.bg-smoke-dark-1 {
  background-color: #4a6b78 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light {
  /*  background-color: #f5f5f6 !important; } */
  background-color: white !important;
}

.bg-light-10 {
  background-color: #eaecec !important;
}

.bg-light-15 {
  background-color: #e0e3e4 !important;
}

.bg-light-20 {
  background-color: #d6d9da !important;
}

.bg-light-30 {
  background-color: #c1c6c8 !important;
}

.bg-light-40 {
  background-color: #adb3b6 !important;
}

.bg-light-50 {
  background-color: #98a0a3 !important;
}

.bg-light-60 {
  background-color: #848d91 !important;
}

.bg-light-70 {
  background-color: #6f7a7f !important;
}

.bg-light-80 {
  background-color: #5b676d !important;
}

.bg-light-90 {
  background-color: #46545a !important;
}

.bg-dark {
  background-color: #324148 !important;
}

.bg-dark-20 {
  background-color: #2b383e !important;
}

.bg-dark-40 {
  background-color: #273238 !important;
}

.bg-dark-60 {
  background-color: #212a2f !important;
}

.bg-dark-80 {
  background-color: #1a2225 !important;
}

.bg-dark-100 {
  background-color: #0d1113 !important;
}

.bg-twitter {
  background-color: #00aced !important;
}

.bg-gradient-primary {
  background: #00acf0;
  background: linear-gradient(45deg, #00acf0, #6640b2) !important;
  background: -webkit-bg-linear-gradient(45deg, #00acf0, #6640b2) !important;
}

.bg-gradient-info {
  background: #1ebccd;
  background: linear-gradient(45deg, #1ebccd, #3a55b1) !important;
  background: -webkit-bg-linear-gradient(45deg, #1ebccd, #3a55b1) !important;
}

.bg-gradient-success {
  background: #22af47;
  background: linear-gradient(45deg, #22af47, #d0d962) !important;
  background: -webkit-bg-linear-gradient(45deg, #22af47, #d0d962) !important;
}

.bg-gradient-warning {
  background: #ffbf36;
  background: linear-gradient(45deg, #ffbf36, #ff6028) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffbf36, #ff6028) !important;
}

.bg-gradient-danger {
  background: #f83f37;
  background: linear-gradient(45deg, #f83f37, #ab26aa) !important;
  background: -webkit-bg-linear-gradient(45deg, #f83f37, #ab26aa) !important;
}

.bg-gradient-secondary {
  background: #9e9e9e;
  background: linear-gradient(45deg, #9e9e9e, #5e7d8a) !important;
  background: -webkit-bg-linear-gradient(45deg, #9e9e9e, #5e7d8a) !important;
}

.bg-gradient-light {
  background: #f5f5f6;
  background: linear-gradient(45deg, #f5f5f6, #9e9e9e) !important;
  background: -webkit-bg-linear-gradient(45deg, #f5f5f6, #9e9e9e) !important;
}

.bg-gradient-dark {
  background: #324148;
  background: linear-gradient(45deg, #324148, #7a5449) !important;
  background: -webkit-bg-linear-gradient(45deg, #324148, #7a5449) !important;
}

.bg-gradient-pony {
  background: #ed1b60;
  background: linear-gradient(45deg, #ed1b60, #1ebccd) !important;
  background: -webkit-bg-linear-gradient(45deg, #ed1b60, #1ebccd) !important;
}

.bg-gradient-space {
  background: #ab26aa;
  background: linear-gradient(45deg, #ab26aa, #fde335) !important;
  background: -webkit-bg-linear-gradient(45deg, #ab26aa, #fde335) !important;
}

.bg-gradient-streaks {
  background: #6640b2;
  background: linear-gradient(45deg, #6640b2, #009b84) !important;
  background: -webkit-bg-linear-gradient(45deg, #6640b2, #009b84) !important;
}

.bg-gradient-bunting {
  background: #3a55b1;
  background: linear-gradient(45deg, #3a55b1, #7a5449) !important;
  background: -webkit-bg-linear-gradient(45deg, #3a55b1, #7a5449) !important;
}

.bg-gradient-paradise {
  background: #0092ee;
  background: linear-gradient(45deg, #0092ee, #88c241) !important;
  background: -webkit-bg-linear-gradient(45deg, #0092ee, #88c241) !important;
}

.bg-gradient-heaven {
  background: #88c241;
  background: linear-gradient(45deg, #88c241, #00acf0) !important;
  background: -webkit-bg-linear-gradient(45deg, #88c241, #00acf0) !important;
}

.bg-gradient-honey {
  background: #d0d962;
  background: linear-gradient(45deg, #d0d962, #ff9528) !important;
  background: -webkit-bg-linear-gradient(45deg, #d0d962, #ff9528) !important;
}

.bg-gradient-warbler {
  background: #fde335;
  background: linear-gradient(45deg, #fde335, #9e9e9e) !important;
  background: -webkit-bg-linear-gradient(45deg, #fde335, #9e9e9e) !important;
}

.bg-gradient-dusk {
  background: #ff9528;
  background: linear-gradient(45deg, #ff9528, #6640b2) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff9528, #6640b2) !important;
}

.bg-gradient-citrine {
  background: #ff6028;
  background: linear-gradient(45deg, #ff6028, #7a5449) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff6028, #7a5449) !important;
}

.bg-gradient-royston {
  background: #7a5449;
  background: linear-gradient(45deg, #7a5449, #009b84) !important;
  background: -webkit-bg-linear-gradient(45deg, #7a5449, #009b84) !important;
}

.bg-gradient-ashes {
  background: #5e7d8a;
  background: linear-gradient(45deg, #5e7d8a, #324148) !important;
  background: -webkit-bg-linear-gradient(45deg, #5e7d8a, #324148) !important;
}

.bg-gradient-metal {
  background: #c1993f;
  background: linear-gradient(45deg, #c1993f, #9e9e9e) !important;
  background: -webkit-bg-linear-gradient(45deg, #c1993f, #9e9e9e) !important;
}

.bg-gradient-sunset {
  background: #009b84;
  background: linear-gradient(45deg, #009b84, #ff6028) !important;
  background: -webkit-bg-linear-gradient(45deg, #009b84, #ff6028) !important;
}

.bg-trans-light-10 {
  background: rgba(255, 255, 255, 0.1);
}

.bg-trans-light-15 {
  background: rgba(255, 255, 255, 0.15);
}

.bg-trans-light-20 {
  background: rgba(255, 255, 255, 0.2);
}

.bg-trans-light-25 {
  background: rgba(255, 255, 255, 0.25);
}

.bg-trans-light-30 {
  background: rgba(255, 255, 255, 0.3);
}

.bg-trans-light-35 {
  background: rgba(255, 255, 255, 0.35);
}

.bg-trans-light-40 {
  background: rgba(255, 255, 255, 0.4);
}

.bg-trans-light-45 {
  background: rgba(255, 255, 255, 0.45);
}

.bg-trans-light-50 {
  background: rgba(255, 255, 255, 0.5);
}

.bg-trans-light-55 {
  background: rgba(255, 255, 255, 0.55);
}

.bg-trans-light-60 {
  background: rgba(255, 255, 255, 0.6);
}

.bg-trans-light-65 {
  background: rgba(255, 255, 255, 0.65);
}

.bg-trans-light-70 {
  background: rgba(255, 255, 255, 0.7);
}

.bg-trans-light-75 {
  background: rgba(255, 255, 255, 0.75);
}

.bg-trans-light-80 {
  background: rgba(255, 255, 255, 0.8);
}

.bg-trans-light-85 {
  background: rgba(255, 255, 255, 0.85);
}

.bg-trans-light-90 {
  background: rgba(255, 255, 255, 0.9);
}

.bg-trans-dark-10 {
  background: rgba(26, 34, 37, 0.1);
}

.bg-trans-dark-15 {
  background: rgba(26, 34, 37, 0.15);
}

.bg-trans-dark-20 {
  background: rgba(26, 34, 37, 0.2);
}

.bg-trans-dark-25 {
  background: rgba(26, 34, 37, 0.25);
}

.bg-trans-dark-30 {
  background: rgba(26, 34, 37, 0.3);
}

.bg-trans-dark-35 {
  background: rgba(26, 34, 37, 0.35);
}

.bg-trans-dark-40 {
  background: rgba(26, 34, 37, 0.4);
}

.bg-trans-dark-45 {
  background: rgba(26, 34, 37, 0.45);
}

.bg-trans-dark-50 {
  background: rgba(26, 34, 37, 0.5);
}

.bg-trans-dark-55 {
  background: rgba(26, 34, 37, 0.55);
}

.bg-trans-dark-60 {
  background: rgba(26, 34, 37, 0.6);
}

.bg-trans-dark-65 {
  background: rgba(26, 34, 37, 0.65);
}

.bg-trans-dark-70 {
  background: rgba(26, 34, 37, 0.7);
}

.bg-trans-dark-75 {
  background: rgba(26, 34, 37, 0.75);
}

.bg-trans-dark-80 {
  background: rgba(26, 34, 37, 0.8);
}

.bg-trans-dark-85 {
  background: rgba(26, 34, 37, 0.85);
}

.bg-trans-dark-90 {
  background: rgba(26, 34, 37, 0.9);
}

.bg-trans-dark-95 {
  background: rgba(26, 34, 37, 0.95);
}

.overlay-wrap {
  position: relative;
}
.overlay-wrap .bg-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

/*Text Colors*/
.text-sky,
.text-primary {
  color: #f4623a !important;
}

.text-cyan,
.text-info {
  color: #1ebccd !important;
}

.text-green,
.text-success {
  color: #22af47 !important;
}

.text-yellow,
.text-warning {
  color: #ffbf36 !important;
}

.text-red,
.text-danger {
  color: #f83f37 !important;
}

.text-blue {
  color: #0092ee !important;
}

.text-pink {
  color: #ed1b60 !important;
}

.text-purple {
  color: #ab26aa !important;
}

.text-violet {
  color: #6640b2 !important;
}

.text-indigo {
  color: #3a55b1 !important;
}

.text-blue {
  color: #0092ee !important;
}

.text-teal {
  color: #009b84 !important;
}

.text-neon {
  color: #88c241 !important;
}

.text-lime {
  color: #d0d962 !important;
}

.text-sun {
  color: #fde335 !important;
}

.text-orange {
  color: #ff9528 !important;
}

.text-pumpkin {
  color: #ff6028 !important;
}

.text-brown {
  color: #7a5449 !important;
}

.text-gold {
  color: #c1993f !important;
}

.text-smoke,
.text-muted {
  color: #5e7d8a !important;
}

.text-grey,
.text-secondary {
  color: #9e9e9e !important;
}

.text-dark {
  color: #324148 !important;
}

.text-light {
  color: #848d91 !important;
}

.text-white {
  color: #fff !important;
}

.text-light-10 {
  color: #eaecec !important;
}

.text-light-15 {
  color: #e0e3e4 !important;
}

.text-light-20 {
  color: #d6d9da !important;
}

.text-light-30 {
  color: #c1c6c8 !important;
}

.text-light-40 {
  color: #adb3b6 !important;
}

.text-light-50 {
  color: #98a0a3 !important;
}

.text-light-60 {
  color: #848d91 !important;
}

.text-light-70 {
  color: #6f7a7f !important;
}

.text-light-80 {
  color: #5b676d !important;
}

.text-light-90 {
  color: #46545a !important;
}

.text-dark-20 {
  color: #2b383e !important;
}

.text-dark-40 {
  color: #273238 !important;
}

.text-dark-60 {
  color: #212a2f !important;
}

.text-dark-80 {
  color: #1a2225 !important;
}

.text-dark-100 {
  color: #0d1113 !important;
}

.text-disabled {
  color: #c1c6c8 !important;
}

/*Border*/
.border {
  border: 1px solid #eaecec !important;
}

.border-top {
  border-top: 1px solid #eaecec !important;
}

.border-bottom {
  border-bottom: 1px solid #eaecec !important;
}

.border-left {
  border-left: 1px solid #eaecec !important;
}

.border-right {
  border-right: 1px solid #eaecec !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-left {
  border-top-left-radius: 0.25rem !important;
}

.rounded-top-right {
  border-top-right-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-left-0 {
  border-top-left-radius: 0 !important;
}

.rounded-top-right-0 {
  border-top-right-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-right-0 {
  border-bottom-right-radius: 0 !important;
}

.rounded-0 {
  border-radius: 0px !important;
}

.rounded-1 {
  border-radius: 1px !important;
}

.rounded-2 {
  border-radius: 2px !important;
}

.rounded-3 {
  border-radius: 3px !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

.rounded-5 {
  border-radius: 5px !important;
}

.rounded-6 {
  border-radius: 6px !important;
}

.rounded-7 {
  border-radius: 7px !important;
}

.rounded-8 {
  border-radius: 8px !important;
}

.rounded-9 {
  border-radius: 9px !important;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.rounded-25 {
  border-radius: 25px !important;
}

.rounded-30 {
  border-radius: 30px !important;
}

.rounded-35 {
  border-radius: 35px !important;
}

.rounded-40 {
  border-radius: 40px !important;
}

.rounded-45 {
  border-radius: 45px !important;
}

.rounded-50 {
  border-radius: 50px !important;
}

.circle {
  border-radius: 50% !important;
}

.square {
  border-radius: 0 !important;
}

.border-primary,
.border-sky {
  border-color: #f4623a !important;
}

.border-info,
.border-cyan {
  border-color: #1ebccd !important;
}

.border-danger,
.border-red {
  border-color: #f83f37 !important;
}

.border-success,
.border-green {
  border-color: #22af47 !important;
}

.border-warning,
.border-yellow {
  border-color: #ffbf36 !important;
}

.border-pink {
  border-color: #ed1b60 !important;
}

.border-purple {
  border-color: #ab26aa !important;
}

.border-violet {
  border-color: #6640b2 !important;
}

.border-indigo {
  border-color: #3a55b1 !important;
}

.border-blue {
  border-color: #0092ee !important;
}

.border-teal {
  border-color: #009b84 !important;
}

.border-neon {
  border-color: #88c241 !important;
}

.border-lime {
  border-color: #d0d962 !important;
}

.border-sun {
  border-color: #fde335 !important;
}

.border-orange {
  border-color: #ff9528 !important;
}

.border-pumpkin {
  border-color: #ff6028 !important;
}

.border-brown {
  border-color: #7a5449 !important;
}

.border-gold {
  border-color: #c1993f !important;
}

.border-smoke {
  border-color: #5e7d8a !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: #f5f5f6 !important;
}

.border-light-10 {
  border-color: #eaecec !important;
}

.border-light-15 {
  border-color: #e0e3e4 !important;
}

.border-light-20 {
  border-color: #d6d9da !important;
}

.border-light-30 {
  border-color: #c1c6c8 !important;
}

.border-light-40 {
  border-color: #adb3b6 !important;
}

.border-light-50 {
  border-color: #98a0a3 !important;
}

.border-light-60 {
  border-color: #848d91 !important;
}

.border-light-70 {
  border-color: #6f7a7f !important;
}

.border-light-80 {
  border-color: #5b676d !important;
}

.border-light-90 {
  border-color: #46545a !important;
}

.border-dark {
  border-color: #324148 !important;
}

.border-dark-20 {
  border-color: #2b383e !important;
}

.border-dark-40 {
  border-color: #273238 !important;
}

.border-dark-60 {
  border-color: #212a2f !important;
}

.border-dark-80 {
  border-color: #1a2225 !important;
}

.border-dark-100 {
  border-color: #0d1113 !important;
}

.border-secondary {
  border-color: #9e9e9e !important;
}

.subtractive-example > .d-inline-block {
  border: 1px solid #adb3b6;
}

/*Colors*/
.color-palette-wrap .color-wrap {
  padding: 15px;
}

/*Shadow*/
.shadow-none {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1) !important;
}

.shadow-top {
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1) !important;
}

.shadow-bottom {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}

.shadow-left {
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.1) !important;
}

.shadow-right {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-sm.shadow-top {
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-sm.shadow-bottom {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-sm.shadow-left {
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-sm.shadow-right {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1) !important;
}

.shadow-hover-sm {
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.shadow-hover-sm:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-sm:hover.shadow-top {
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-sm:hover.shadow-bottom {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-sm:hover.shadow-left {
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-sm:hover.shadow-right {
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1) !important;
}

.shadow {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow.shadow-top {
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow.shadow-bottom {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow.shadow-left {
  box-shadow: -4px 0 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow.shadow-right {
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.1) !important;
}

.shadow-hover {
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.shadow-hover:hover {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover:hover.shadow-top {
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover:hover.shadow-bottom {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover:hover.shadow-left {
  box-shadow: -4px 0 5px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover:hover.shadow-right {
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-lg.shadow-top {
  box-shadow: 0 -6px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-lg.shadow-bottom {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-lg.shadow-left {
  box-shadow: -6px 0 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-lg.shadow-right {
  box-shadow: 6px 0 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow-hover-lg {
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.shadow-hover-lg:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-lg:hover.shadow-top {
  box-shadow: 0 -6px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-lg:hover.shadow-bottom {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-lg:hover.shadow-left {
  box-shadow: -6px 0 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-lg:hover.shadow-right {
  box-shadow: 6px 0 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow-xl {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-xl.shadow-top {
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-xl.shadow-bottom {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-xl.shadow-left {
  box-shadow: -8px 0 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-xl.shadow-right {
  box-shadow: 8px 0 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow-hover-xl {
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
}
.shadow-hover-xl:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-xl:hover.shadow-top {
  box-shadow: 0 -8px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-xl:hover.shadow-bottom {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-xl:hover.shadow-left {
  box-shadow: -8px 0 10px rgba(0, 0, 0, 0.1) !important;
}
.shadow-hover-xl:hover.shadow-right {
  box-shadow: 8px 0 10px rgba(0, 0, 0, 0.1) !important;
}

/*Font Size*/
.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-21 {
  font-size: 21px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-23 {
  font-size: 23px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-27 {
  font-size: 27px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-29 {
  font-size: 29px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-31 {
  font-size: 31px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-33 {
  font-size: 33px !important;
}

.font-34 {
  font-size: 34px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-37 {
  font-size: 37px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-39 {
  font-size: 39px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-41 {
  font-size: 41px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-43 {
  font-size: 43px !important;
}

.font-44 {
  font-size: 44px !important;
}

.font-45 {
  font-size: 45px !important;
}

.font-46 {
  font-size: 46px !important;
}

.font-47 {
  font-size: 47px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-49 {
  font-size: 49px !important;
}

.font-50 {
  font-size: 50px !important;
}

@media (min-width: 576px) {
  .font-sm-11 {
    font-size: 11px !important;
  }

  .font-sm-12 {
    font-size: 12px !important;
  }

  .font-sm-13 {
    font-size: 13px !important;
  }

  .font-sm-14 {
    font-size: 14px !important;
  }

  .font-sm-15 {
    font-size: 15px !important;
  }

  .font-sm-16 {
    font-size: 16px !important;
  }

  .font-sm-17 {
    font-size: 17px !important;
  }

  .font-sm-18 {
    font-size: 18px !important;
  }

  .font-sm-19 {
    font-size: 19px !important;
  }

  .font-sm-20 {
    font-size: 20px !important;
  }

  .font-sm-21 {
    font-size: 21px !important;
  }

  .font-sm-22 {
    font-size: 22px !important;
  }

  .font-sm-23 {
    font-size: 23px !important;
  }

  .font-sm-24 {
    font-size: 24px !important;
  }

  .font-sm-25 {
    font-size: 25px !important;
  }

  .font-sm-26 {
    font-size: 26px !important;
  }

  .font-sm-27 {
    font-size: 27px !important;
  }

  .font-sm-28 {
    font-size: 28px !important;
  }

  .font-sm-29 {
    font-size: 29px !important;
  }

  .font-sm-30 {
    font-size: 30px !important;
  }

  .font-sm-31 {
    font-size: 31px !important;
  }

  .font-sm-32 {
    font-size: 32px !important;
  }

  .font-sm-33 {
    font-size: 33px !important;
  }

  .font-sm-34 {
    font-size: 34px !important;
  }

  .font-sm-35 {
    font-size: 35px !important;
  }

  .font-sm-36 {
    font-size: 36px !important;
  }

  .font-sm-37 {
    font-size: 37px !important;
  }

  .font-sm-38 {
    font-size: 38px !important;
  }

  .font-sm-39 {
    font-size: 39px !important;
  }

  .font-sm-40 {
    font-size: 40px !important;
  }

  .font-sm-41 {
    font-size: 41px !important;
  }

  .font-sm-42 {
    font-size: 42px !important;
  }

  .font-sm-43 {
    font-size: 43px !important;
  }

  .font-sm-44 {
    font-size: 44px !important;
  }

  .font-sm-45 {
    font-size: 45px !important;
  }

  .font-sm-46 {
    font-size: 46px !important;
  }

  .font-sm-47 {
    font-size: 47px !important;
  }

  .font-sm-48 {
    font-size: 48px !important;
  }

  .font-sm-49 {
    font-size: 49px !important;
  }

  .font-sm-50 {
    font-size: 50px !important;
  }
}
@media (min-width: 768px) {
  .font-md-11 {
    font-size: 11px !important;
  }

  .font-md-12 {
    font-size: 12px !important;
  }

  .font-md-13 {
    font-size: 13px !important;
  }

  .font-md-14 {
    font-size: 14px !important;
  }

  .font-md-15 {
    font-size: 15px !important;
  }

  .font-md-16 {
    font-size: 16px !important;
  }

  .font-md-17 {
    font-size: 17px !important;
  }

  .font-md-18 {
    font-size: 18px !important;
  }

  .font-md-19 {
    font-size: 19px !important;
  }

  .font-md-20 {
    font-size: 20px !important;
  }

  .font-md-21 {
    font-size: 21px !important;
  }

  .font-md-22 {
    font-size: 22px !important;
  }

  .font-md-23 {
    font-size: 23px !important;
  }

  .font-md-24 {
    font-size: 24px !important;
  }

  .font-md-25 {
    font-size: 25px !important;
  }

  .font-md-26 {
    font-size: 26px !important;
  }

  .font-md-27 {
    font-size: 27px !important;
  }

  .font-md-28 {
    font-size: 28px !important;
  }

  .font-md-29 {
    font-size: 29px !important;
  }

  .font-md-30 {
    font-size: 30px !important;
  }

  .font-md-31 {
    font-size: 31px !important;
  }

  .font-md-32 {
    font-size: 32px !important;
  }

  .font-md-33 {
    font-size: 33px !important;
  }

  .font-md-34 {
    font-size: 34px !important;
  }

  .font-md-35 {
    font-size: 35px !important;
  }

  .font-md-36 {
    font-size: 36px !important;
  }

  .font-md-37 {
    font-size: 37px !important;
  }

  .font-md-38 {
    font-size: 38px !important;
  }

  .font-md-39 {
    font-size: 39px !important;
  }

  .font-md-40 {
    font-size: 40px !important;
  }

  .font-md-41 {
    font-size: 41px !important;
  }

  .font-md-42 {
    font-size: 42px !important;
  }

  .font-md-43 {
    font-size: 43px !important;
  }

  .font-md-44 {
    font-size: 44px !important;
  }

  .font-md-45 {
    font-size: 45px !important;
  }

  .font-md-46 {
    font-size: 46px !important;
  }

  .font-md-47 {
    font-size: 47px !important;
  }

  .font-md-48 {
    font-size: 48px !important;
  }

  .font-md-49 {
    font-size: 49px !important;
  }

  .font-md-50 {
    font-size: 50px !important;
  }
}
@media (min-width: 992px) {
  .font-lg-11 {
    font-size: 11px !important;
  }

  .font-lg-12 {
    font-size: 12px !important;
  }

  .font-lg-13 {
    font-size: 13px !important;
  }

  .font-lg-14 {
    font-size: 14px !important;
  }

  .font-lg-15 {
    font-size: 15px !important;
  }

  .font-lg-16 {
    font-size: 16px !important;
  }

  .font-lg-17 {
    font-size: 17px !important;
  }

  .font-lg-18 {
    font-size: 18px !important;
  }

  .font-lg-19 {
    font-size: 19px !important;
  }

  .font-lg-20 {
    font-size: 20px !important;
  }

  .font-lg-21 {
    font-size: 21px !important;
  }

  .font-lg-22 {
    font-size: 22px !important;
  }

  .font-lg-23 {
    font-size: 23px !important;
  }

  .font-lg-24 {
    font-size: 24px !important;
  }

  .font-lg-25 {
    font-size: 25px !important;
  }

  .font-lg-26 {
    font-size: 26px !important;
  }

  .font-lg-27 {
    font-size: 27px !important;
  }

  .font-lg-28 {
    font-size: 28px !important;
  }

  .font-lg-29 {
    font-size: 29px !important;
  }

  .font-lg-30 {
    font-size: 30px !important;
  }

  .font-lg-31 {
    font-size: 31px !important;
  }

  .font-lg-32 {
    font-size: 32px !important;
  }

  .font-lg-33 {
    font-size: 33px !important;
  }

  .font-lg-34 {
    font-size: 34px !important;
  }

  .font-lg-35 {
    font-size: 35px !important;
  }

  .font-lg-36 {
    font-size: 36px !important;
  }

  .font-lg-37 {
    font-size: 37px !important;
  }

  .font-lg-38 {
    font-size: 38px !important;
  }

  .font-lg-39 {
    font-size: 39px !important;
  }

  .font-lg-40 {
    font-size: 40px !important;
  }

  .font-lg-41 {
    font-size: 41px !important;
  }

  .font-lg-42 {
    font-size: 42px !important;
  }

  .font-lg-43 {
    font-size: 43px !important;
  }

  .font-lg-44 {
    font-size: 44px !important;
  }

  .font-lg-45 {
    font-size: 45px !important;
  }

  .font-lg-46 {
    font-size: 46px !important;
  }

  .font-lg-47 {
    font-size: 47px !important;
  }

  .font-lg-48 {
    font-size: 48px !important;
  }

  .font-lg-49 {
    font-size: 49px !important;
  }

  .font-lg-50 {
    font-size: 50px !important;
  }
}
@media (min-width: 1200px) {
  .font-xl-11 {
    font-size: 11px !important;
  }

  .font-xl-12 {
    font-size: 12px !important;
  }

  .font-xl-13 {
    font-size: 13px !important;
  }

  .font-xl-14 {
    font-size: 14px !important;
  }

  .font-xl-15 {
    font-size: 15px !important;
  }

  .font-xl-16 {
    font-size: 16px !important;
  }

  .font-xl-17 {
    font-size: 17px !important;
  }

  .font-xl-18 {
    font-size: 18px !important;
  }

  .font-xl-19 {
    font-size: 19px !important;
  }

  .font-xl-20 {
    font-size: 20px !important;
  }

  .font-xl-21 {
    font-size: 21px !important;
  }

  .font-xl-22 {
    font-size: 22px !important;
  }

  .font-xl-23 {
    font-size: 23px !important;
  }

  .font-xl-24 {
    font-size: 24px !important;
  }

  .font-xl-25 {
    font-size: 25px !important;
  }

  .font-xl-26 {
    font-size: 26px !important;
  }

  .font-xl-27 {
    font-size: 27px !important;
  }

  .font-xl-28 {
    font-size: 28px !important;
  }

  .font-xl-29 {
    font-size: 29px !important;
  }

  .font-xl-30 {
    font-size: 30px !important;
  }

  .font-xl-31 {
    font-size: 31px !important;
  }

  .font-xl-32 {
    font-size: 32px !important;
  }

  .font-xl-33 {
    font-size: 33px !important;
  }

  .font-xl-34 {
    font-size: 34px !important;
  }

  .font-xl-35 {
    font-size: 35px !important;
  }

  .font-xl-36 {
    font-size: 36px !important;
  }

  .font-xl-37 {
    font-size: 37px !important;
  }

  .font-xl-38 {
    font-size: 38px !important;
  }

  .font-xl-39 {
    font-size: 39px !important;
  }

  .font-xl-40 {
    font-size: 40px !important;
  }

  .font-xl-41 {
    font-size: 41px !important;
  }

  .font-xl-42 {
    font-size: 42px !important;
  }

  .font-xl-43 {
    font-size: 43px !important;
  }

  .font-xl-44 {
    font-size: 44px !important;
  }

  .font-xl-45 {
    font-size: 45px !important;
  }

  .font-xl-46 {
    font-size: 46px !important;
  }

  .font-xl-47 {
    font-size: 47px !important;
  }

  .font-xl-48 {
    font-size: 48px !important;
  }

  .font-xl-49 {
    font-size: 49px !important;
  }

  .font-xl-50 {
    font-size: 50px !important;
  }
}
/*Font Weight*/
.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

/*Text Case*/
.text-nonecase {
  text-transform: none !important;
}

/*Letter Spacing*/
.letter-spacing-5 {
  letter-spacing: 0.5px !important;
}

.letter-spacing-10 {
  letter-spacing: 1px !important;
}

.letter-spacing-15 {
  letter-spacing: 1.5px !important;
}

.letter-spacing-20 {
  letter-spacing: 2px !important;
}

.letter-spacing-25 {
  letter-spacing: 2.5px !important;
}

.letter-spacing-30 {
  letter-spacing: 3px !important;
}

.letter-spacing-35 {
  letter-spacing: 3.5px !important;
}

.letter-spacing-40 {
  letter-spacing: 4px !important;
}

.letter-spacing-45 {
  letter-spacing: 4.5px !important;
}

.letter-spacing-50 {
  letter-spacing: 5px !important;
}

/*Line Height*/
.line-height-0 {
  line-height: 0 !important;
}

.line-height-10 {
  line-height: 1 !important;
}

.line-height-11 {
  line-height: 1.1 !important;
}

.line-height-12 {
  line-height: 1.2 !important;
}

.line-height-13 {
  line-height: 1.3 !important;
}

.line-height-14 {
  line-height: 1.4 !important;
}

.line-height-15 {
  line-height: 1.5 !important;
}

.line-height-16 {
  line-height: 1.6 !important;
}

.line-height-17 {
  line-height: 1.7 !important;
}

.line-height-18 {
  line-height: 1.8 !important;
}

.line-height-19 {
  line-height: 1.9 !important;
}

.line-height-20 {
  line-height: 2 !important;
}

/*Width*/
.w-25p {
  width: 25px !important;
}

.w-30p {
  width: 30px !important;
}

.w-35p {
  width: 35px !important;
}

.w-40p {
  width: 40px !important;
}

.w-45p {
  width: 45px !important;
}

.w-50p {
  width: 50px !important;
}

.w-55p {
  width: 55px !important;
}

.w-60p {
  width: 60px !important;
}

.w-65p {
  width: 65px !important;
}

.w-70p {
  width: 70px !important;
}

.w-75p {
  width: 75px !important;
}

.w-80p {
  width: 80px !important;
}

.w-85p {
  width: 85px !important;
}

.w-90p {
  width: 90px !important;
}

.w-95p {
  width: 95px !important;
}

.w-100p {
  width: 100px !important;
}

.w-105p {
  width: 105px !important;
}

.w-110p {
  width: 110px !important;
}

.w-115p {
  width: 115px !important;
}

.w-120p {
  width: 120px !important;
}

.w-125p {
  width: 125px !important;
}

.w-130p {
  width: 130px !important;
}

.w-135p {
  width: 135px !important;
}

.w-140p {
  width: 140px !important;
}

.w-145p {
  width: 145px !important;
}

.w-150p {
  width: 150px !important;
}

.w-155p {
  width: 155px !important;
}

.w-160p {
  width: 160px !important;
}

.w-165p {
  width: 165px !important;
}

.w-170p {
  width: 170px !important;
}

.w-175p {
  width: 175px !important;
}

.w-180p {
  width: 180px !important;
}

.w-185p {
  width: 185px !important;
}

.w-190p {
  width: 190px !important;
}

.w-195p {
  width: 195px !important;
}

.w-200p {
  width: 200px !important;
}

.w-205p {
  width: 205px !important;
}

.w-210p {
  width: 210px !important;
}

.w-215p {
  width: 215px !important;
}

.w-220p {
  width: 220px !important;
}

.w-225p {
  width: 225px !important;
}

.w-230p {
  width: 230px !important;
}

.w-235p {
  width: 235px !important;
}

.w-240p {
  width: 240px !important;
}

.w-245p {
  width: 245px !important;
}

.w-250p {
  width: 250px !important;
}

.w-255p {
  width: 255px !important;
}

.w-260p {
  width: 260px !important;
}

.w-265p {
  width: 265px !important;
}

.w-270p {
  width: 270px !important;
}

.w-275p {
  width: 275px !important;
}

.w-280p {
  width: 280px !important;
}

.w-285p {
  width: 285px !important;
}

.w-290p {
  width: 290px !important;
}

.w-295p {
  width: 295px !important;
}

.w-300p {
  width: 300px !important;
}

.w-305p {
  width: 305px !important;
}

.w-310p {
  width: 310px !important;
}

.w-315p {
  width: 315px !important;
}

.w-320p {
  width: 320px !important;
}

.w-325p {
  width: 325px !important;
}

.w-330p {
  width: 330px !important;
}

.w-335p {
  width: 335px !important;
}

.w-340p {
  width: 340px !important;
}

.w-345p {
  width: 345px !important;
}

.w-350p {
  width: 350px !important;
}

.w-355p {
  width: 355px !important;
}

.w-360p {
  width: 360px !important;
}

.w-365p {
  width: 365px !important;
}

.w-370p {
  width: 370px !important;
}

.w-375p {
  width: 375px !important;
}

.w-380p {
  width: 380px !important;
}

.w-385p {
  width: 385px !important;
}

.w-390p {
  width: 390px !important;
}

.w-395p {
  width: 395px !important;
}

.w-400p {
  width: 400px !important;
}

.w-405p {
  width: 405px !important;
}

.w-410p {
  width: 410px !important;
}

.w-415p {
  width: 415px !important;
}

.w-420p {
  width: 420px !important;
}

.w-425p {
  width: 425px !important;
}

.w-430p {
  width: 430px !important;
}

.w-435p {
  width: 435px !important;
}

.w-440p {
  width: 440px !important;
}

.w-445p {
  width: 445px !important;
}

.w-450p {
  width: 450px !important;
}

.w-455p {
  width: 455px !important;
}

.w-460p {
  width: 460px !important;
}

.w-465p {
  width: 465px !important;
}

.w-470p {
  width: 470px !important;
}

.w-475p {
  width: 475px !important;
}

.w-480p {
  width: 480px !important;
}

.w-485p {
  width: 485px !important;
}

.w-490p {
  width: 490px !important;
}

.w-495p {
  width: 495px !important;
}

.w-500p {
  width: 500px !important;
}

.w-505p {
  width: 505px !important;
}

.w-510p {
  width: 510px !important;
}

.w-515p {
  width: 515px !important;
}

.w-520p {
  width: 520px !important;
}

.w-525p {
  width: 525px !important;
}

.w-530p {
  width: 530px !important;
}

.w-535p {
  width: 535px !important;
}

.w-540p {
  width: 540px !important;
}

.w-545p {
  width: 545px !important;
}

.w-550p {
  width: 550px !important;
}

.w-555p {
  width: 555px !important;
}

.w-560p {
  width: 560px !important;
}

.w-565p {
  width: 565px !important;
}

.w-570p {
  width: 570px !important;
}

.w-575p {
  width: 575px !important;
}

.w-580p {
  width: 580px !important;
}

.w-585p {
  width: 585px !important;
}

.w-590p {
  width: 590px !important;
}

.w-595p {
  width: 595px !important;
}

.w-600p {
  width: 600px !important;
}

.w-605p {
  width: 605px !important;
}

.w-610p {
  width: 610px !important;
}

.w-615p {
  width: 615px !important;
}

.w-620p {
  width: 620px !important;
}

.w-625p {
  width: 625px !important;
}

.w-630p {
  width: 630px !important;
}

.w-635p {
  width: 635px !important;
}

.w-640p {
  width: 640px !important;
}

.w-645p {
  width: 645px !important;
}

.w-650p {
  width: 650px !important;
}

.w-655p {
  width: 655px !important;
}

.w-660p {
  width: 660px !important;
}

.w-665p {
  width: 665px !important;
}

.w-670p {
  width: 670px !important;
}

.w-675p {
  width: 675px !important;
}

.w-680p {
  width: 680px !important;
}

.w-685p {
  width: 685px !important;
}

.w-690p {
  width: 690px !important;
}

.w-695p {
  width: 695px !important;
}

.w-700p {
  width: 700px !important;
}

.w-705p {
  width: 705px !important;
}

.w-710p {
  width: 710px !important;
}

.w-715p {
  width: 715px !important;
}

.w-720p {
  width: 720px !important;
}

.w-725p {
  width: 725px !important;
}

.w-730p {
  width: 730px !important;
}

.w-735p {
  width: 735px !important;
}

.w-740p {
  width: 740px !important;
}

.w-745p {
  width: 745px !important;
}

.w-750p {
  width: 750px !important;
}

.w-755p {
  width: 755px !important;
}

.w-760p {
  width: 760px !important;
}

.w-765p {
  width: 765px !important;
}

.w-770p {
  width: 770px !important;
}

.w-775p {
  width: 775px !important;
}

.w-780p {
  width: 780px !important;
}

.w-785p {
  width: 785px !important;
}

.w-790p {
  width: 790px !important;
}

.w-795p {
  width: 795px !important;
}

.w-800p {
  width: 800px !important;
}

@media (min-width: 576px) {
  .w-sm-25p {
    width: 25px !important;
  }

  .w-sm-30p {
    width: 30px !important;
  }

  .w-sm-35p {
    width: 35px !important;
  }

  .w-sm-40p {
    width: 40px !important;
  }

  .w-sm-45p {
    width: 45px !important;
  }

  .w-sm-50p {
    width: 50px !important;
  }

  .w-sm-55p {
    width: 55px !important;
  }

  .w-sm-60p {
    width: 60px !important;
  }

  .w-sm-65p {
    width: 65px !important;
  }

  .w-sm-70p {
    width: 70px !important;
  }

  .w-sm-75p {
    width: 75px !important;
  }

  .w-sm-80p {
    width: 80px !important;
  }

  .w-sm-85p {
    width: 85px !important;
  }

  .w-sm-90p {
    width: 90px !important;
  }

  .w-sm-95p {
    width: 95px !important;
  }

  .w-sm-100p {
    width: 100px !important;
  }

  .w-sm-105p {
    width: 105px !important;
  }

  .w-sm-110p {
    width: 110px !important;
  }

  .w-sm-115p {
    width: 115px !important;
  }

  .w-sm-120p {
    width: 120px !important;
  }

  .w-sm-125p {
    width: 125px !important;
  }

  .w-sm-130p {
    width: 130px !important;
  }

  .w-sm-135p {
    width: 135px !important;
  }

  .w-sm-140p {
    width: 140px !important;
  }

  .w-sm-145p {
    width: 145px !important;
  }

  .w-sm-150p {
    width: 150px !important;
  }

  .w-sm-155p {
    width: 155px !important;
  }

  .w-sm-160p {
    width: 160px !important;
  }

  .w-sm-165p {
    width: 165px !important;
  }

  .w-sm-170p {
    width: 170px !important;
  }

  .w-sm-175p {
    width: 175px !important;
  }

  .w-sm-180p {
    width: 180px !important;
  }

  .w-sm-185p {
    width: 185px !important;
  }

  .w-sm-190p {
    width: 190px !important;
  }

  .w-sm-195p {
    width: 195px !important;
  }

  .w-sm-200p {
    width: 200px !important;
  }

  .w-sm-205p {
    width: 205px !important;
  }

  .w-sm-210p {
    width: 210px !important;
  }

  .w-sm-215p {
    width: 215px !important;
  }

  .w-sm-220p {
    width: 220px !important;
  }

  .w-sm-225p {
    width: 225px !important;
  }

  .w-sm-230p {
    width: 230px !important;
  }

  .w-sm-235p {
    width: 235px !important;
  }

  .w-sm-240p {
    width: 240px !important;
  }

  .w-sm-245p {
    width: 245px !important;
  }

  .w-sm-250p {
    width: 250px !important;
  }

  .w-sm-255p {
    width: 255px !important;
  }

  .w-sm-260p {
    width: 260px !important;
  }

  .w-sm-265p {
    width: 265px !important;
  }

  .w-sm-270p {
    width: 270px !important;
  }

  .w-sm-275p {
    width: 275px !important;
  }

  .w-sm-280p {
    width: 280px !important;
  }

  .w-sm-285p {
    width: 285px !important;
  }

  .w-sm-290p {
    width: 290px !important;
  }

  .w-sm-295p {
    width: 295px !important;
  }

  .w-sm-300p {
    width: 300px !important;
  }

  .w-sm-305p {
    width: 305px !important;
  }

  .w-sm-310p {
    width: 310px !important;
  }

  .w-sm-315p {
    width: 315px !important;
  }

  .w-sm-320p {
    width: 320px !important;
  }

  .w-sm-325p {
    width: 325px !important;
  }

  .w-sm-330p {
    width: 330px !important;
  }

  .w-sm-335p {
    width: 335px !important;
  }

  .w-sm-340p {
    width: 340px !important;
  }

  .w-sm-345p {
    width: 345px !important;
  }

  .w-sm-350p {
    width: 350px !important;
  }

  .w-sm-355p {
    width: 355px !important;
  }

  .w-sm-360p {
    width: 360px !important;
  }

  .w-sm-365p {
    width: 365px !important;
  }

  .w-sm-370p {
    width: 370px !important;
  }

  .w-sm-375p {
    width: 375px !important;
  }

  .w-sm-380p {
    width: 380px !important;
  }

  .w-sm-385p {
    width: 385px !important;
  }

  .w-sm-390p {
    width: 390px !important;
  }

  .w-sm-395p {
    width: 395px !important;
  }

  .w-sm-400p {
    width: 400px !important;
  }

  .w-sm-405p {
    width: 405px !important;
  }

  .w-sm-410p {
    width: 410px !important;
  }

  .w-sm-415p {
    width: 415px !important;
  }

  .w-sm-420p {
    width: 420px !important;
  }

  .w-sm-425p {
    width: 425px !important;
  }

  .w-sm-430p {
    width: 430px !important;
  }

  .w-sm-435p {
    width: 435px !important;
  }

  .w-sm-440p {
    width: 440px !important;
  }

  .w-sm-445p {
    width: 445px !important;
  }

  .w-sm-450p {
    width: 450px !important;
  }

  .w-sm-455p {
    width: 455px !important;
  }

  .w-sm-460p {
    width: 460px !important;
  }

  .w-sm-465p {
    width: 465px !important;
  }

  .w-sm-470p {
    width: 470px !important;
  }

  .w-sm-475p {
    width: 475px !important;
  }

  .w-sm-480p {
    width: 480px !important;
  }

  .w-sm-485p {
    width: 485px !important;
  }

  .w-sm-490p {
    width: 490px !important;
  }

  .w-sm-495p {
    width: 495px !important;
  }

  .w-sm-500p {
    width: 500px !important;
  }

  .w-sm-505p {
    width: 505px !important;
  }

  .w-sm-510p {
    width: 510px !important;
  }

  .w-sm-515p {
    width: 515px !important;
  }

  .w-sm-520p {
    width: 520px !important;
  }

  .w-sm-525p {
    width: 525px !important;
  }

  .w-sm-530p {
    width: 530px !important;
  }

  .w-sm-535p {
    width: 535px !important;
  }

  .w-sm-540p {
    width: 540px !important;
  }

  .w-sm-545p {
    width: 545px !important;
  }

  .w-sm-550p {
    width: 550px !important;
  }

  .w-sm-555p {
    width: 555px !important;
  }

  .w-sm-560p {
    width: 560px !important;
  }

  .w-sm-565p {
    width: 565px !important;
  }

  .w-sm-570p {
    width: 570px !important;
  }

  .w-sm-575p {
    width: 575px !important;
  }

  .w-sm-580p {
    width: 580px !important;
  }

  .w-sm-585p {
    width: 585px !important;
  }

  .w-sm-590p {
    width: 590px !important;
  }

  .w-sm-595p {
    width: 595px !important;
  }

  .w-sm-600p {
    width: 600px !important;
  }

  .w-sm-605p {
    width: 605px !important;
  }

  .w-sm-610p {
    width: 610px !important;
  }

  .w-sm-615p {
    width: 615px !important;
  }

  .w-sm-620p {
    width: 620px !important;
  }

  .w-sm-625p {
    width: 625px !important;
  }

  .w-sm-630p {
    width: 630px !important;
  }

  .w-sm-635p {
    width: 635px !important;
  }

  .w-sm-640p {
    width: 640px !important;
  }

  .w-sm-645p {
    width: 645px !important;
  }

  .w-sm-650p {
    width: 650px !important;
  }

  .w-sm-655p {
    width: 655px !important;
  }

  .w-sm-660p {
    width: 660px !important;
  }

  .w-sm-665p {
    width: 665px !important;
  }

  .w-sm-670p {
    width: 670px !important;
  }

  .w-sm-675p {
    width: 675px !important;
  }

  .w-sm-680p {
    width: 680px !important;
  }

  .w-sm-685p {
    width: 685px !important;
  }

  .w-sm-690p {
    width: 690px !important;
  }

  .w-sm-695p {
    width: 695px !important;
  }

  .w-sm-700p {
    width: 700px !important;
  }

  .w-sm-705p {
    width: 705px !important;
  }

  .w-sm-710p {
    width: 710px !important;
  }

  .w-sm-715p {
    width: 715px !important;
  }

  .w-sm-720p {
    width: 720px !important;
  }

  .w-sm-725p {
    width: 725px !important;
  }

  .w-sm-730p {
    width: 730px !important;
  }

  .w-sm-735p {
    width: 735px !important;
  }

  .w-sm-740p {
    width: 740px !important;
  }

  .w-sm-745p {
    width: 745px !important;
  }

  .w-sm-750p {
    width: 750px !important;
  }

  .w-sm-755p {
    width: 755px !important;
  }

  .w-sm-760p {
    width: 760px !important;
  }

  .w-sm-765p {
    width: 765px !important;
  }

  .w-sm-770p {
    width: 770px !important;
  }

  .w-sm-775p {
    width: 775px !important;
  }

  .w-sm-780p {
    width: 780px !important;
  }

  .w-sm-785p {
    width: 785px !important;
  }

  .w-sm-790p {
    width: 790px !important;
  }

  .w-sm-795p {
    width: 795px !important;
  }

  .w-sm-800p {
    width: 800px !important;
  }
}
@media (min-width: 768px) {
  .w-md-25p {
    width: 25px !important;
  }

  .w-md-30p {
    width: 30px !important;
  }

  .w-md-35p {
    width: 35px !important;
  }

  .w-md-40p {
    width: 40px !important;
  }

  .w-md-45p {
    width: 45px !important;
  }

  .w-md-50p {
    width: 50px !important;
  }

  .w-md-55p {
    width: 55px !important;
  }

  .w-md-60p {
    width: 60px !important;
  }

  .w-md-65p {
    width: 65px !important;
  }

  .w-md-70p {
    width: 70px !important;
  }

  .w-md-75p {
    width: 75px !important;
  }

  .w-md-80p {
    width: 80px !important;
  }

  .w-md-85p {
    width: 85px !important;
  }

  .w-md-90p {
    width: 90px !important;
  }

  .w-md-95p {
    width: 95px !important;
  }

  .w-md-100p {
    width: 100px !important;
  }

  .w-md-105p {
    width: 105px !important;
  }

  .w-md-110p {
    width: 110px !important;
  }

  .w-md-115p {
    width: 115px !important;
  }

  .w-md-120p {
    width: 120px !important;
  }

  .w-md-125p {
    width: 125px !important;
  }

  .w-md-130p {
    width: 130px !important;
  }

  .w-md-135p {
    width: 135px !important;
  }

  .w-md-140p {
    width: 140px !important;
  }

  .w-md-145p {
    width: 145px !important;
  }

  .w-md-150p {
    width: 150px !important;
  }

  .w-md-155p {
    width: 155px !important;
  }

  .w-md-160p {
    width: 160px !important;
  }

  .w-md-165p {
    width: 165px !important;
  }

  .w-md-170p {
    width: 170px !important;
  }

  .w-md-175p {
    width: 175px !important;
  }

  .w-md-180p {
    width: 180px !important;
  }

  .w-md-185p {
    width: 185px !important;
  }

  .w-md-190p {
    width: 190px !important;
  }

  .w-md-195p {
    width: 195px !important;
  }

  .w-md-200p {
    width: 200px !important;
  }

  .w-md-205p {
    width: 205px !important;
  }

  .w-md-210p {
    width: 210px !important;
  }

  .w-md-215p {
    width: 215px !important;
  }

  .w-md-220p {
    width: 220px !important;
  }

  .w-md-225p {
    width: 225px !important;
  }

  .w-md-230p {
    width: 230px !important;
  }

  .w-md-235p {
    width: 235px !important;
  }

  .w-md-240p {
    width: 240px !important;
  }

  .w-md-245p {
    width: 245px !important;
  }

  .w-md-250p {
    width: 250px !important;
  }

  .w-md-255p {
    width: 255px !important;
  }

  .w-md-260p {
    width: 260px !important;
  }

  .w-md-265p {
    width: 265px !important;
  }

  .w-md-270p {
    width: 270px !important;
  }

  .w-md-275p {
    width: 275px !important;
  }

  .w-md-280p {
    width: 280px !important;
  }

  .w-md-285p {
    width: 285px !important;
  }

  .w-md-290p {
    width: 290px !important;
  }

  .w-md-295p {
    width: 295px !important;
  }

  .w-md-300p {
    width: 300px !important;
  }

  .w-md-305p {
    width: 305px !important;
  }

  .w-md-310p {
    width: 310px !important;
  }

  .w-md-315p {
    width: 315px !important;
  }

  .w-md-320p {
    width: 320px !important;
  }

  .w-md-325p {
    width: 325px !important;
  }

  .w-md-330p {
    width: 330px !important;
  }

  .w-md-335p {
    width: 335px !important;
  }

  .w-md-340p {
    width: 340px !important;
  }

  .w-md-345p {
    width: 345px !important;
  }

  .w-md-350p {
    width: 350px !important;
  }

  .w-md-355p {
    width: 355px !important;
  }

  .w-md-360p {
    width: 360px !important;
  }

  .w-md-365p {
    width: 365px !important;
  }

  .w-md-370p {
    width: 370px !important;
  }

  .w-md-375p {
    width: 375px !important;
  }

  .w-md-380p {
    width: 380px !important;
  }

  .w-md-385p {
    width: 385px !important;
  }

  .w-md-390p {
    width: 390px !important;
  }

  .w-md-395p {
    width: 395px !important;
  }

  .w-md-400p {
    width: 400px !important;
  }

  .w-md-405p {
    width: 405px !important;
  }

  .w-md-410p {
    width: 410px !important;
  }

  .w-md-415p {
    width: 415px !important;
  }

  .w-md-420p {
    width: 420px !important;
  }

  .w-md-425p {
    width: 425px !important;
  }

  .w-md-430p {
    width: 430px !important;
  }

  .w-md-435p {
    width: 435px !important;
  }

  .w-md-440p {
    width: 440px !important;
  }

  .w-md-445p {
    width: 445px !important;
  }

  .w-md-450p {
    width: 450px !important;
  }

  .w-md-455p {
    width: 455px !important;
  }

  .w-md-460p {
    width: 460px !important;
  }

  .w-md-465p {
    width: 465px !important;
  }

  .w-md-470p {
    width: 470px !important;
  }

  .w-md-475p {
    width: 475px !important;
  }

  .w-md-480p {
    width: 480px !important;
  }

  .w-md-485p {
    width: 485px !important;
  }

  .w-md-490p {
    width: 490px !important;
  }

  .w-md-495p {
    width: 495px !important;
  }

  .w-md-500p {
    width: 500px !important;
  }

  .w-md-505p {
    width: 505px !important;
  }

  .w-md-510p {
    width: 510px !important;
  }

  .w-md-515p {
    width: 515px !important;
  }

  .w-md-520p {
    width: 520px !important;
  }

  .w-md-525p {
    width: 525px !important;
  }

  .w-md-530p {
    width: 530px !important;
  }

  .w-md-535p {
    width: 535px !important;
  }

  .w-md-540p {
    width: 540px !important;
  }

  .w-md-545p {
    width: 545px !important;
  }

  .w-md-550p {
    width: 550px !important;
  }

  .w-md-555p {
    width: 555px !important;
  }

  .w-md-560p {
    width: 560px !important;
  }

  .w-md-565p {
    width: 565px !important;
  }

  .w-md-570p {
    width: 570px !important;
  }

  .w-md-575p {
    width: 575px !important;
  }

  .w-md-580p {
    width: 580px !important;
  }

  .w-md-585p {
    width: 585px !important;
  }

  .w-md-590p {
    width: 590px !important;
  }

  .w-md-595p {
    width: 595px !important;
  }

  .w-md-600p {
    width: 600px !important;
  }

  .w-md-605p {
    width: 605px !important;
  }

  .w-md-610p {
    width: 610px !important;
  }

  .w-md-615p {
    width: 615px !important;
  }

  .w-md-620p {
    width: 620px !important;
  }

  .w-md-625p {
    width: 625px !important;
  }

  .w-md-630p {
    width: 630px !important;
  }

  .w-md-635p {
    width: 635px !important;
  }

  .w-md-640p {
    width: 640px !important;
  }

  .w-md-645p {
    width: 645px !important;
  }

  .w-md-650p {
    width: 650px !important;
  }

  .w-md-655p {
    width: 655px !important;
  }

  .w-md-660p {
    width: 660px !important;
  }

  .w-md-665p {
    width: 665px !important;
  }

  .w-md-670p {
    width: 670px !important;
  }

  .w-md-675p {
    width: 675px !important;
  }

  .w-md-680p {
    width: 680px !important;
  }

  .w-md-685p {
    width: 685px !important;
  }

  .w-md-690p {
    width: 690px !important;
  }

  .w-md-695p {
    width: 695px !important;
  }

  .w-md-700p {
    width: 700px !important;
  }

  .w-md-705p {
    width: 705px !important;
  }

  .w-md-710p {
    width: 710px !important;
  }

  .w-md-715p {
    width: 715px !important;
  }

  .w-md-720p {
    width: 720px !important;
  }

  .w-md-725p {
    width: 725px !important;
  }

  .w-md-730p {
    width: 730px !important;
  }

  .w-md-735p {
    width: 735px !important;
  }

  .w-md-740p {
    width: 740px !important;
  }

  .w-md-745p {
    width: 745px !important;
  }

  .w-md-750p {
    width: 750px !important;
  }

  .w-md-755p {
    width: 755px !important;
  }

  .w-md-760p {
    width: 760px !important;
  }

  .w-md-765p {
    width: 765px !important;
  }

  .w-md-770p {
    width: 770px !important;
  }

  .w-md-775p {
    width: 775px !important;
  }

  .w-md-780p {
    width: 780px !important;
  }

  .w-md-785p {
    width: 785px !important;
  }

  .w-md-790p {
    width: 790px !important;
  }

  .w-md-795p {
    width: 795px !important;
  }

  .w-md-800p {
    width: 800px !important;
  }
}
@media (min-width: 992px) {
  .w-lg-25p {
    width: 25px !important;
  }

  .w-lg-30p {
    width: 30px !important;
  }

  .w-lg-35p {
    width: 35px !important;
  }

  .w-lg-40p {
    width: 40px !important;
  }

  .w-lg-45p {
    width: 45px !important;
  }

  .w-lg-50p {
    width: 50px !important;
  }

  .w-lg-55p {
    width: 55px !important;
  }

  .w-lg-60p {
    width: 60px !important;
  }

  .w-lg-65p {
    width: 65px !important;
  }

  .w-lg-70p {
    width: 70px !important;
  }

  .w-lg-75p {
    width: 75px !important;
  }

  .w-lg-80p {
    width: 80px !important;
  }

  .w-lg-85p {
    width: 85px !important;
  }

  .w-lg-90p {
    width: 90px !important;
  }

  .w-lg-95p {
    width: 95px !important;
  }

  .w-lg-100p {
    width: 100px !important;
  }

  .w-lg-105p {
    width: 105px !important;
  }

  .w-lg-110p {
    width: 110px !important;
  }

  .w-lg-115p {
    width: 115px !important;
  }

  .w-lg-120p {
    width: 120px !important;
  }

  .w-lg-125p {
    width: 125px !important;
  }

  .w-lg-130p {
    width: 130px !important;
  }

  .w-lg-135p {
    width: 135px !important;
  }

  .w-lg-140p {
    width: 140px !important;
  }

  .w-lg-145p {
    width: 145px !important;
  }

  .w-lg-150p {
    width: 150px !important;
  }

  .w-lg-155p {
    width: 155px !important;
  }

  .w-lg-160p {
    width: 160px !important;
  }

  .w-lg-165p {
    width: 165px !important;
  }

  .w-lg-170p {
    width: 170px !important;
  }

  .w-lg-175p {
    width: 175px !important;
  }

  .w-lg-180p {
    width: 180px !important;
  }

  .w-lg-185p {
    width: 185px !important;
  }

  .w-lg-190p {
    width: 190px !important;
  }

  .w-lg-195p {
    width: 195px !important;
  }

  .w-lg-200p {
    width: 200px !important;
  }

  .w-lg-205p {
    width: 205px !important;
  }

  .w-lg-210p {
    width: 210px !important;
  }

  .w-lg-215p {
    width: 215px !important;
  }

  .w-lg-220p {
    width: 220px !important;
  }

  .w-lg-225p {
    width: 225px !important;
  }

  .w-lg-230p {
    width: 230px !important;
  }

  .w-lg-235p {
    width: 235px !important;
  }

  .w-lg-240p {
    width: 240px !important;
  }

  .w-lg-245p {
    width: 245px !important;
  }

  .w-lg-250p {
    width: 250px !important;
  }

  .w-lg-255p {
    width: 255px !important;
  }

  .w-lg-260p {
    width: 260px !important;
  }

  .w-lg-265p {
    width: 265px !important;
  }

  .w-lg-270p {
    width: 270px !important;
  }

  .w-lg-275p {
    width: 275px !important;
  }

  .w-lg-280p {
    width: 280px !important;
  }

  .w-lg-285p {
    width: 285px !important;
  }

  .w-lg-290p {
    width: 290px !important;
  }

  .w-lg-295p {
    width: 295px !important;
  }

  .w-lg-300p {
    width: 300px !important;
  }

  .w-lg-305p {
    width: 305px !important;
  }

  .w-lg-310p {
    width: 310px !important;
  }

  .w-lg-315p {
    width: 315px !important;
  }

  .w-lg-320p {
    width: 320px !important;
  }

  .w-lg-325p {
    width: 325px !important;
  }

  .w-lg-330p {
    width: 330px !important;
  }

  .w-lg-335p {
    width: 335px !important;
  }

  .w-lg-340p {
    width: 340px !important;
  }

  .w-lg-345p {
    width: 345px !important;
  }

  .w-lg-350p {
    width: 350px !important;
  }

  .w-lg-355p {
    width: 355px !important;
  }

  .w-lg-360p {
    width: 360px !important;
  }

  .w-lg-365p {
    width: 365px !important;
  }

  .w-lg-370p {
    width: 370px !important;
  }

  .w-lg-375p {
    width: 375px !important;
  }

  .w-lg-380p {
    width: 380px !important;
  }

  .w-lg-385p {
    width: 385px !important;
  }

  .w-lg-390p {
    width: 390px !important;
  }

  .w-lg-395p {
    width: 395px !important;
  }

  .w-lg-400p {
    width: 400px !important;
  }

  .w-lg-405p {
    width: 405px !important;
  }

  .w-lg-410p {
    width: 410px !important;
  }

  .w-lg-415p {
    width: 415px !important;
  }

  .w-lg-420p {
    width: 420px !important;
  }

  .w-lg-425p {
    width: 425px !important;
  }

  .w-lg-430p {
    width: 430px !important;
  }

  .w-lg-435p {
    width: 435px !important;
  }

  .w-lg-440p {
    width: 440px !important;
  }

  .w-lg-445p {
    width: 445px !important;
  }

  .w-lg-450p {
    width: 450px !important;
  }

  .w-lg-455p {
    width: 455px !important;
  }

  .w-lg-460p {
    width: 460px !important;
  }

  .w-lg-465p {
    width: 465px !important;
  }

  .w-lg-470p {
    width: 470px !important;
  }

  .w-lg-475p {
    width: 475px !important;
  }

  .w-lg-480p {
    width: 480px !important;
  }

  .w-lg-485p {
    width: 485px !important;
  }

  .w-lg-490p {
    width: 490px !important;
  }

  .w-lg-495p {
    width: 495px !important;
  }

  .w-lg-500p {
    width: 500px !important;
  }

  .w-lg-505p {
    width: 505px !important;
  }

  .w-lg-510p {
    width: 510px !important;
  }

  .w-lg-515p {
    width: 515px !important;
  }

  .w-lg-520p {
    width: 520px !important;
  }

  .w-lg-525p {
    width: 525px !important;
  }

  .w-lg-530p {
    width: 530px !important;
  }

  .w-lg-535p {
    width: 535px !important;
  }

  .w-lg-540p {
    width: 540px !important;
  }

  .w-lg-545p {
    width: 545px !important;
  }

  .w-lg-550p {
    width: 550px !important;
  }

  .w-lg-555p {
    width: 555px !important;
  }

  .w-lg-560p {
    width: 560px !important;
  }

  .w-lg-565p {
    width: 565px !important;
  }

  .w-lg-570p {
    width: 570px !important;
  }

  .w-lg-575p {
    width: 575px !important;
  }

  .w-lg-580p {
    width: 580px !important;
  }

  .w-lg-585p {
    width: 585px !important;
  }

  .w-lg-590p {
    width: 590px !important;
  }

  .w-lg-595p {
    width: 595px !important;
  }

  .w-lg-600p {
    width: 600px !important;
  }

  .w-lg-605p {
    width: 605px !important;
  }

  .w-lg-610p {
    width: 610px !important;
  }

  .w-lg-615p {
    width: 615px !important;
  }

  .w-lg-620p {
    width: 620px !important;
  }

  .w-lg-625p {
    width: 625px !important;
  }

  .w-lg-630p {
    width: 630px !important;
  }

  .w-lg-635p {
    width: 635px !important;
  }

  .w-lg-640p {
    width: 640px !important;
  }

  .w-lg-645p {
    width: 645px !important;
  }

  .w-lg-650p {
    width: 650px !important;
  }

  .w-lg-655p {
    width: 655px !important;
  }

  .w-lg-660p {
    width: 660px !important;
  }

  .w-lg-665p {
    width: 665px !important;
  }

  .w-lg-670p {
    width: 670px !important;
  }

  .w-lg-675p {
    width: 675px !important;
  }

  .w-lg-680p {
    width: 680px !important;
  }

  .w-lg-685p {
    width: 685px !important;
  }

  .w-lg-690p {
    width: 690px !important;
  }

  .w-lg-695p {
    width: 695px !important;
  }

  .w-lg-700p {
    width: 700px !important;
  }

  .w-lg-705p {
    width: 705px !important;
  }

  .w-lg-710p {
    width: 710px !important;
  }

  .w-lg-715p {
    width: 715px !important;
  }

  .w-lg-720p {
    width: 720px !important;
  }

  .w-lg-725p {
    width: 725px !important;
  }

  .w-lg-730p {
    width: 730px !important;
  }

  .w-lg-735p {
    width: 735px !important;
  }

  .w-lg-740p {
    width: 740px !important;
  }

  .w-lg-745p {
    width: 745px !important;
  }

  .w-lg-750p {
    width: 750px !important;
  }

  .w-lg-755p {
    width: 755px !important;
  }

  .w-lg-760p {
    width: 760px !important;
  }

  .w-lg-765p {
    width: 765px !important;
  }

  .w-lg-770p {
    width: 770px !important;
  }

  .w-lg-775p {
    width: 775px !important;
  }

  .w-lg-780p {
    width: 780px !important;
  }

  .w-lg-785p {
    width: 785px !important;
  }

  .w-lg-790p {
    width: 790px !important;
  }

  .w-lg-795p {
    width: 795px !important;
  }

  .w-lg-800p {
    width: 800px !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-25p {
    width: 25px !important;
  }

  .w-xl-30p {
    width: 30px !important;
  }

  .w-xl-35p {
    width: 35px !important;
  }

  .w-xl-40p {
    width: 40px !important;
  }

  .w-xl-45p {
    width: 45px !important;
  }

  .w-xl-50p {
    width: 50px !important;
  }

  .w-xl-55p {
    width: 55px !important;
  }

  .w-xl-60p {
    width: 60px !important;
  }

  .w-xl-65p {
    width: 65px !important;
  }

  .w-xl-70p {
    width: 70px !important;
  }

  .w-xl-75p {
    width: 75px !important;
  }

  .w-xl-80p {
    width: 80px !important;
  }

  .w-xl-85p {
    width: 85px !important;
  }

  .w-xl-90p {
    width: 90px !important;
  }

  .w-xl-95p {
    width: 95px !important;
  }

  .w-xl-100p {
    width: 100px !important;
  }

  .w-xl-105p {
    width: 105px !important;
  }

  .w-xl-110p {
    width: 110px !important;
  }

  .w-xl-115p {
    width: 115px !important;
  }

  .w-xl-120p {
    width: 120px !important;
  }

  .w-xl-125p {
    width: 125px !important;
  }

  .w-xl-130p {
    width: 130px !important;
  }

  .w-xl-135p {
    width: 135px !important;
  }

  .w-xl-140p {
    width: 140px !important;
  }

  .w-xl-145p {
    width: 145px !important;
  }

  .w-xl-150p {
    width: 150px !important;
  }

  .w-xl-155p {
    width: 155px !important;
  }

  .w-xl-160p {
    width: 160px !important;
  }

  .w-xl-165p {
    width: 165px !important;
  }

  .w-xl-170p {
    width: 170px !important;
  }

  .w-xl-175p {
    width: 175px !important;
  }

  .w-xl-180p {
    width: 180px !important;
  }

  .w-xl-185p {
    width: 185px !important;
  }

  .w-xl-190p {
    width: 190px !important;
  }

  .w-xl-195p {
    width: 195px !important;
  }

  .w-xl-200p {
    width: 200px !important;
  }

  .w-xl-205p {
    width: 205px !important;
  }

  .w-xl-210p {
    width: 210px !important;
  }

  .w-xl-215p {
    width: 215px !important;
  }

  .w-xl-220p {
    width: 220px !important;
  }

  .w-xl-225p {
    width: 225px !important;
  }

  .w-xl-230p {
    width: 230px !important;
  }

  .w-xl-235p {
    width: 235px !important;
  }

  .w-xl-240p {
    width: 240px !important;
  }

  .w-xl-245p {
    width: 245px !important;
  }

  .w-xl-250p {
    width: 250px !important;
  }

  .w-xl-255p {
    width: 255px !important;
  }

  .w-xl-260p {
    width: 260px !important;
  }

  .w-xl-265p {
    width: 265px !important;
  }

  .w-xl-270p {
    width: 270px !important;
  }

  .w-xl-275p {
    width: 275px !important;
  }

  .w-xl-280p {
    width: 280px !important;
  }

  .w-xl-285p {
    width: 285px !important;
  }

  .w-xl-290p {
    width: 290px !important;
  }

  .w-xl-295p {
    width: 295px !important;
  }

  .w-xl-300p {
    width: 300px !important;
  }

  .w-xl-305p {
    width: 305px !important;
  }

  .w-xl-310p {
    width: 310px !important;
  }

  .w-xl-315p {
    width: 315px !important;
  }

  .w-xl-320p {
    width: 320px !important;
  }

  .w-xl-325p {
    width: 325px !important;
  }

  .w-xl-330p {
    width: 330px !important;
  }

  .w-xl-335p {
    width: 335px !important;
  }

  .w-xl-340p {
    width: 340px !important;
  }

  .w-xl-345p {
    width: 345px !important;
  }

  .w-xl-350p {
    width: 350px !important;
  }

  .w-xl-355p {
    width: 355px !important;
  }

  .w-xl-360p {
    width: 360px !important;
  }

  .w-xl-365p {
    width: 365px !important;
  }

  .w-xl-370p {
    width: 370px !important;
  }

  .w-xl-375p {
    width: 375px !important;
  }

  .w-xl-380p {
    width: 380px !important;
  }

  .w-xl-385p {
    width: 385px !important;
  }

  .w-xl-390p {
    width: 390px !important;
  }

  .w-xl-395p {
    width: 395px !important;
  }

  .w-xl-400p {
    width: 400px !important;
  }

  .w-xl-405p {
    width: 405px !important;
  }

  .w-xl-410p {
    width: 410px !important;
  }

  .w-xl-415p {
    width: 415px !important;
  }

  .w-xl-420p {
    width: 420px !important;
  }

  .w-xl-425p {
    width: 425px !important;
  }

  .w-xl-430p {
    width: 430px !important;
  }

  .w-xl-435p {
    width: 435px !important;
  }

  .w-xl-440p {
    width: 440px !important;
  }

  .w-xl-445p {
    width: 445px !important;
  }

  .w-xl-450p {
    width: 450px !important;
  }

  .w-xl-455p {
    width: 455px !important;
  }

  .w-xl-460p {
    width: 460px !important;
  }

  .w-xl-465p {
    width: 465px !important;
  }

  .w-xl-470p {
    width: 470px !important;
  }

  .w-xl-475p {
    width: 475px !important;
  }

  .w-xl-480p {
    width: 480px !important;
  }

  .w-xl-485p {
    width: 485px !important;
  }

  .w-xl-490p {
    width: 490px !important;
  }

  .w-xl-495p {
    width: 495px !important;
  }

  .w-xl-500p {
    width: 500px !important;
  }

  .w-xl-505p {
    width: 505px !important;
  }

  .w-xl-510p {
    width: 510px !important;
  }

  .w-xl-515p {
    width: 515px !important;
  }

  .w-xl-520p {
    width: 520px !important;
  }

  .w-xl-525p {
    width: 525px !important;
  }

  .w-xl-530p {
    width: 530px !important;
  }

  .w-xl-535p {
    width: 535px !important;
  }

  .w-xl-540p {
    width: 540px !important;
  }

  .w-xl-545p {
    width: 545px !important;
  }

  .w-xl-550p {
    width: 550px !important;
  }

  .w-xl-555p {
    width: 555px !important;
  }

  .w-xl-560p {
    width: 560px !important;
  }

  .w-xl-565p {
    width: 565px !important;
  }

  .w-xl-570p {
    width: 570px !important;
  }

  .w-xl-575p {
    width: 575px !important;
  }

  .w-xl-580p {
    width: 580px !important;
  }

  .w-xl-585p {
    width: 585px !important;
  }

  .w-xl-590p {
    width: 590px !important;
  }

  .w-xl-595p {
    width: 595px !important;
  }

  .w-xl-600p {
    width: 600px !important;
  }

  .w-xl-605p {
    width: 605px !important;
  }

  .w-xl-610p {
    width: 610px !important;
  }

  .w-xl-615p {
    width: 615px !important;
  }

  .w-xl-620p {
    width: 620px !important;
  }

  .w-xl-625p {
    width: 625px !important;
  }

  .w-xl-630p {
    width: 630px !important;
  }

  .w-xl-635p {
    width: 635px !important;
  }

  .w-xl-640p {
    width: 640px !important;
  }

  .w-xl-645p {
    width: 645px !important;
  }

  .w-xl-650p {
    width: 650px !important;
  }

  .w-xl-655p {
    width: 655px !important;
  }

  .w-xl-660p {
    width: 660px !important;
  }

  .w-xl-665p {
    width: 665px !important;
  }

  .w-xl-670p {
    width: 670px !important;
  }

  .w-xl-675p {
    width: 675px !important;
  }

  .w-xl-680p {
    width: 680px !important;
  }

  .w-xl-685p {
    width: 685px !important;
  }

  .w-xl-690p {
    width: 690px !important;
  }

  .w-xl-695p {
    width: 695px !important;
  }

  .w-xl-700p {
    width: 700px !important;
  }

  .w-xl-705p {
    width: 705px !important;
  }

  .w-xl-710p {
    width: 710px !important;
  }

  .w-xl-715p {
    width: 715px !important;
  }

  .w-xl-720p {
    width: 720px !important;
  }

  .w-xl-725p {
    width: 725px !important;
  }

  .w-xl-730p {
    width: 730px !important;
  }

  .w-xl-735p {
    width: 735px !important;
  }

  .w-xl-740p {
    width: 740px !important;
  }

  .w-xl-745p {
    width: 745px !important;
  }

  .w-xl-750p {
    width: 750px !important;
  }

  .w-xl-755p {
    width: 755px !important;
  }

  .w-xl-760p {
    width: 760px !important;
  }

  .w-xl-765p {
    width: 765px !important;
  }

  .w-xl-770p {
    width: 770px !important;
  }

  .w-xl-775p {
    width: 775px !important;
  }

  .w-xl-780p {
    width: 780px !important;
  }

  .w-xl-785p {
    width: 785px !important;
  }

  .w-xl-790p {
    width: 790px !important;
  }

  .w-xl-795p {
    width: 795px !important;
  }

  .w-xl-800p {
    width: 800px !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-25p {
    width: 25px !important;
  }

  .w-xxl-30p {
    width: 30px !important;
  }

  .w-xxl-35p {
    width: 35px !important;
  }

  .w-xxl-40p {
    width: 40px !important;
  }

  .w-xxl-45p {
    width: 45px !important;
  }

  .w-xxl-50p {
    width: 50px !important;
  }

  .w-xxl-55p {
    width: 55px !important;
  }

  .w-xxl-60p {
    width: 60px !important;
  }

  .w-xxl-65p {
    width: 65px !important;
  }

  .w-xxl-70p {
    width: 70px !important;
  }

  .w-xxl-75p {
    width: 75px !important;
  }

  .w-xxl-80p {
    width: 80px !important;
  }

  .w-xxl-85p {
    width: 85px !important;
  }

  .w-xxl-90p {
    width: 90px !important;
  }

  .w-xxl-95p {
    width: 95px !important;
  }

  .w-xxl-100p {
    width: 100px !important;
  }

  .w-xxl-105p {
    width: 105px !important;
  }

  .w-xxl-110p {
    width: 110px !important;
  }

  .w-xxl-115p {
    width: 115px !important;
  }

  .w-xxl-120p {
    width: 120px !important;
  }

  .w-xxl-125p {
    width: 125px !important;
  }

  .w-xxl-130p {
    width: 130px !important;
  }

  .w-xxl-135p {
    width: 135px !important;
  }

  .w-xxl-140p {
    width: 140px !important;
  }

  .w-xxl-145p {
    width: 145px !important;
  }

  .w-xxl-150p {
    width: 150px !important;
  }

  .w-xxl-155p {
    width: 155px !important;
  }

  .w-xxl-160p {
    width: 160px !important;
  }

  .w-xxl-165p {
    width: 165px !important;
  }

  .w-xxl-170p {
    width: 170px !important;
  }

  .w-xxl-175p {
    width: 175px !important;
  }

  .w-xxl-180p {
    width: 180px !important;
  }

  .w-xxl-185p {
    width: 185px !important;
  }

  .w-xxl-190p {
    width: 190px !important;
  }

  .w-xxl-195p {
    width: 195px !important;
  }

  .w-xxl-200p {
    width: 200px !important;
  }

  .w-xxl-205p {
    width: 205px !important;
  }

  .w-xxl-210p {
    width: 210px !important;
  }

  .w-xxl-215p {
    width: 215px !important;
  }

  .w-xxl-220p {
    width: 220px !important;
  }

  .w-xxl-225p {
    width: 225px !important;
  }

  .w-xxl-230p {
    width: 230px !important;
  }

  .w-xxl-235p {
    width: 235px !important;
  }

  .w-xxl-240p {
    width: 240px !important;
  }

  .w-xxl-245p {
    width: 245px !important;
  }

  .w-xxl-250p {
    width: 250px !important;
  }

  .w-xxl-255p {
    width: 255px !important;
  }

  .w-xxl-260p {
    width: 260px !important;
  }

  .w-xxl-265p {
    width: 265px !important;
  }

  .w-xxl-270p {
    width: 270px !important;
  }

  .w-xxl-275p {
    width: 275px !important;
  }

  .w-xxl-280p {
    width: 280px !important;
  }

  .w-xxl-285p {
    width: 285px !important;
  }

  .w-xxl-290p {
    width: 290px !important;
  }

  .w-xxl-295p {
    width: 295px !important;
  }

  .w-xxl-300p {
    width: 300px !important;
  }

  .w-xxl-305p {
    width: 305px !important;
  }

  .w-xxl-310p {
    width: 310px !important;
  }

  .w-xxl-315p {
    width: 315px !important;
  }

  .w-xxl-320p {
    width: 320px !important;
  }

  .w-xxl-325p {
    width: 325px !important;
  }

  .w-xxl-330p {
    width: 330px !important;
  }

  .w-xxl-335p {
    width: 335px !important;
  }

  .w-xxl-340p {
    width: 340px !important;
  }

  .w-xxl-345p {
    width: 345px !important;
  }

  .w-xxl-350p {
    width: 350px !important;
  }

  .w-xxl-355p {
    width: 355px !important;
  }

  .w-xxl-360p {
    width: 360px !important;
  }

  .w-xxl-365p {
    width: 365px !important;
  }

  .w-xxl-370p {
    width: 370px !important;
  }

  .w-xxl-375p {
    width: 375px !important;
  }

  .w-xxl-380p {
    width: 380px !important;
  }

  .w-xxl-385p {
    width: 385px !important;
  }

  .w-xxl-390p {
    width: 390px !important;
  }

  .w-xxl-395p {
    width: 395px !important;
  }

  .w-xxl-400p {
    width: 400px !important;
  }

  .w-xxl-405p {
    width: 405px !important;
  }

  .w-xxl-410p {
    width: 410px !important;
  }

  .w-xxl-415p {
    width: 415px !important;
  }

  .w-xxl-420p {
    width: 420px !important;
  }

  .w-xxl-425p {
    width: 425px !important;
  }

  .w-xxl-430p {
    width: 430px !important;
  }

  .w-xxl-435p {
    width: 435px !important;
  }

  .w-xxl-440p {
    width: 440px !important;
  }

  .w-xxl-445p {
    width: 445px !important;
  }

  .w-xxl-450p {
    width: 450px !important;
  }

  .w-xxl-455p {
    width: 455px !important;
  }

  .w-xxl-460p {
    width: 460px !important;
  }

  .w-xxl-465p {
    width: 465px !important;
  }

  .w-xxl-470p {
    width: 470px !important;
  }

  .w-xxl-475p {
    width: 475px !important;
  }

  .w-xxl-480p {
    width: 480px !important;
  }

  .w-xxl-485p {
    width: 485px !important;
  }

  .w-xxl-490p {
    width: 490px !important;
  }

  .w-xxl-495p {
    width: 495px !important;
  }

  .w-xxl-500p {
    width: 500px !important;
  }

  .w-xxl-505p {
    width: 505px !important;
  }

  .w-xxl-510p {
    width: 510px !important;
  }

  .w-xxl-515p {
    width: 515px !important;
  }

  .w-xxl-520p {
    width: 520px !important;
  }

  .w-xxl-525p {
    width: 525px !important;
  }

  .w-xxl-530p {
    width: 530px !important;
  }

  .w-xxl-535p {
    width: 535px !important;
  }

  .w-xxl-540p {
    width: 540px !important;
  }

  .w-xxl-545p {
    width: 545px !important;
  }

  .w-xxl-550p {
    width: 550px !important;
  }

  .w-xxl-555p {
    width: 555px !important;
  }

  .w-xxl-560p {
    width: 560px !important;
  }

  .w-xxl-565p {
    width: 565px !important;
  }

  .w-xxl-570p {
    width: 570px !important;
  }

  .w-xxl-575p {
    width: 575px !important;
  }

  .w-xxl-580p {
    width: 580px !important;
  }

  .w-xxl-585p {
    width: 585px !important;
  }

  .w-xxl-590p {
    width: 590px !important;
  }

  .w-xxl-595p {
    width: 595px !important;
  }

  .w-xxl-600p {
    width: 600px !important;
  }

  .w-xxl-605p {
    width: 605px !important;
  }

  .w-xxl-610p {
    width: 610px !important;
  }

  .w-xxl-615p {
    width: 615px !important;
  }

  .w-xxl-620p {
    width: 620px !important;
  }

  .w-xxl-625p {
    width: 625px !important;
  }

  .w-xxl-630p {
    width: 630px !important;
  }

  .w-xxl-635p {
    width: 635px !important;
  }

  .w-xxl-640p {
    width: 640px !important;
  }

  .w-xxl-645p {
    width: 645px !important;
  }

  .w-xxl-650p {
    width: 650px !important;
  }

  .w-xxl-655p {
    width: 655px !important;
  }

  .w-xxl-660p {
    width: 660px !important;
  }

  .w-xxl-665p {
    width: 665px !important;
  }

  .w-xxl-670p {
    width: 670px !important;
  }

  .w-xxl-675p {
    width: 675px !important;
  }

  .w-xxl-680p {
    width: 680px !important;
  }

  .w-xxl-685p {
    width: 685px !important;
  }

  .w-xxl-690p {
    width: 690px !important;
  }

  .w-xxl-695p {
    width: 695px !important;
  }

  .w-xxl-700p {
    width: 700px !important;
  }

  .w-xxl-705p {
    width: 705px !important;
  }

  .w-xxl-710p {
    width: 710px !important;
  }

  .w-xxl-715p {
    width: 715px !important;
  }

  .w-xxl-720p {
    width: 720px !important;
  }

  .w-xxl-725p {
    width: 725px !important;
  }

  .w-xxl-730p {
    width: 730px !important;
  }

  .w-xxl-735p {
    width: 735px !important;
  }

  .w-xxl-740p {
    width: 740px !important;
  }

  .w-xxl-745p {
    width: 745px !important;
  }

  .w-xxl-750p {
    width: 750px !important;
  }

  .w-xxl-755p {
    width: 755px !important;
  }

  .w-xxl-760p {
    width: 760px !important;
  }

  .w-xxl-765p {
    width: 765px !important;
  }

  .w-xxl-770p {
    width: 770px !important;
  }

  .w-xxl-775p {
    width: 775px !important;
  }

  .w-xxl-780p {
    width: 780px !important;
  }

  .w-xxl-785p {
    width: 785px !important;
  }

  .w-xxl-790p {
    width: 790px !important;
  }

  .w-xxl-795p {
    width: 795px !important;
  }

  .w-xxl-800p {
    width: 800px !important;
  }
}
.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

@media (min-width: 576px) {
  .w-sm-5 {
    width: 5% !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-20 {
    width: 20% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-55 {
    width: 55% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-70 {
    width: 70% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-95 {
    width: 95% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .w-md-5 {
    width: 5% !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-20 {
    width: 20% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-95 {
    width: 95% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-5 {
    width: 5% !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-20 {
    width: 20% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-95 {
    width: 95% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-5 {
    width: 5% !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-20 {
    width: 20% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-55 {
    width: 55% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-95 {
    width: 95% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }
}
@media (min-width: 1400px) {
  .w-xxl-5 {
    width: 5% !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-15 {
    width: 15% !important;
  }

  .w-xxl-20 {
    width: 20% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-35 {
    width: 35% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-45 {
    width: 45% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-55 {
    width: 55% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-65 {
    width: 65% !important;
  }

  .w-xxl-70 {
    width: 70% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .w-xxl-95 {
    width: 95% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }
}
/*Max Width*/
.mw-25p {
  max-width: 25px !important;
}

.mw-50p {
  max-width: 50px !important;
}

.mw-75p {
  max-width: 75px !important;
}

.mw-100p {
  max-width: 100px !important;
}

.mw-125p {
  max-width: 125px !important;
}

.mw-150p {
  max-width: 150px !important;
}

.mw-175p {
  max-width: 175px !important;
}

.mw-200p {
  max-width: 200px !important;
}

.mw-225p {
  max-width: 225px !important;
}

.mw-250p {
  max-width: 250px !important;
}

.mw-275p {
  max-width: 275px !important;
}

.mw-300p {
  max-width: 300px !important;
}

.mw-325p {
  max-width: 325px !important;
}

.mw-350p {
  max-width: 350px !important;
}

.mw-375p {
  max-width: 375px !important;
}

.mw-400p {
  max-width: 400px !important;
}

.mw-425p {
  max-width: 425px !important;
}

.mw-450p {
  max-width: 450px !important;
}

.mw-475p {
  max-width: 475px !important;
}

.mw-500p {
  max-width: 500px !important;
}

.mw-525p {
  max-width: 525px !important;
}

.mw-550p {
  max-width: 550px !important;
}

.mw-575p {
  max-width: 575px !important;
}

.mw-600p {
  max-width: 600px !important;
}

.mw-625p {
  max-width: 625px !important;
}

.mw-650p {
  max-width: 650px !important;
}

.mw-675p {
  max-width: 675px !important;
}

.mw-700p {
  max-width: 700px !important;
}

.mw-725p {
  max-width: 725px !important;
}

.mw-750p {
  max-width: 750px !important;
}

.mw-775p {
  max-width: 775px !important;
}

.mw-800p {
  max-width: 800px !important;
}

/*Min Width*/
.mnw-0 {
  min-width: 0 !important;
}

.mnw-25p {
  min-width: 25px !important;
}

.mnw-50p {
  min-width: 50px !important;
}

.mnw-75p {
  min-width: 75px !important;
}

.mnw-100p {
  min-width: 100px !important;
}

.mnw-125p {
  min-width: 125px !important;
}

.mnw-150p {
  min-width: 150px !important;
}

.mnw-175p {
  min-width: 175px !important;
}

.mnw-200p {
  min-width: 200px !important;
}

.mnw-225p {
  min-width: 225px !important;
}

.mnw-250p {
  min-width: 250px !important;
}

.mnw-275p {
  min-width: 275px !important;
}

.mnw-300p {
  min-width: 300px !important;
}

.mnw-325p {
  min-width: 325px !important;
}

.mnw-350p {
  min-width: 350px !important;
}

.mnw-375p {
  min-width: 375px !important;
}

.mnw-400p {
  min-width: 400px !important;
}

.mnw-425p {
  min-width: 425px !important;
}

.mnw-450p {
  min-width: 450px !important;
}

.mnw-475p {
  min-width: 475px !important;
}

.mnw-500p {
  min-width: 500px !important;
}

.mnw-525p {
  min-width: 525px !important;
}

.mnw-550p {
  min-width: 550px !important;
}

.mnw-575p {
  min-width: 575px !important;
}

.mnw-600p {
  min-width: 600px !important;
}

.mnw-625p {
  min-width: 625px !important;
}

.mnw-650p {
  min-width: 650px !important;
}

.mnw-675p {
  min-width: 675px !important;
}

.mnw-700p {
  min-width: 700px !important;
}

.mnw-725p {
  min-width: 725px !important;
}

.mnw-750p {
  min-width: 750px !important;
}

.mnw-775p {
  min-width: 775px !important;
}

.mnw-800p {
  min-width: 800px !important;
}

/*Height*/
.h-25p {
  height: 25px !important;
}

.h-30p {
  height: 30px !important;
}

.h-35p {
  height: 35px !important;
}

.h-40p {
  height: 40px !important;
}

.h-45p {
  height: 45px !important;
}

.h-50p {
  height: 50px !important;
}

.h-55p {
  height: 55px !important;
}

.h-60p {
  height: 60px !important;
}

.h-65p {
  height: 65px !important;
}

.h-70p {
  height: 70px !important;
}

.h-75p {
  height: 75px !important;
}

.h-80p {
  height: 80px !important;
}

.h-85p {
  height: 85px !important;
}

.h-90p {
  height: 90px !important;
}

.h-95p {
  height: 95px !important;
}

.h-100p {
  height: 100px !important;
}

.h-105p {
  height: 105px !important;
}

.h-110p {
  height: 110px !important;
}

.h-115p {
  height: 115px !important;
}

.h-120p {
  height: 120px !important;
}

.h-125p {
  height: 125px !important;
}

.h-130p {
  height: 130px !important;
}

.h-135p {
  height: 135px !important;
}

.h-140p {
  height: 140px !important;
}

.h-145p {
  height: 145px !important;
}

.h-150p {
  height: 150px !important;
}

.h-155p {
  height: 155px !important;
}

.h-160p {
  height: 160px !important;
}

.h-165p {
  height: 165px !important;
}

.h-170p {
  height: 170px !important;
}

.h-175p {
  height: 175px !important;
}

.h-180p {
  height: 180px !important;
}

.h-185p {
  height: 185px !important;
}

.h-190p {
  height: 190px !important;
}

.h-195p {
  height: 195px !important;
}

.h-200p {
  height: 200px !important;
}

.h-205p {
  height: 205px !important;
}

.h-210p {
  height: 210px !important;
}

.h-215p {
  height: 215px !important;
}

.h-220p {
  height: 220px !important;
}

.h-225p {
  height: 225px !important;
}

.h-230p {
  height: 230px !important;
}

.h-235p {
  height: 235px !important;
}

.h-240p {
  height: 240px !important;
}

.h-245p {
  height: 245px !important;
}

.h-250p {
  height: 250px !important;
}

.h-255p {
  height: 255px !important;
}

.h-260p {
  height: 260px !important;
}

.h-265p {
  height: 265px !important;
}

.h-270p {
  height: 270px !important;
}

.h-275p {
  height: 275px !important;
}

.h-280p {
  height: 280px !important;
}

.h-285p {
  height: 285px !important;
}

.h-290p {
  height: 290px !important;
}

.h-295p {
  height: 295px !important;
}

.h-300p {
  height: 300px !important;
}

.h-305p {
  height: 305px !important;
}

.h-310p {
  height: 310px !important;
}

.h-315p {
  height: 315px !important;
}

.h-320p {
  height: 320px !important;
}

.h-325p {
  height: 325px !important;
}

.h-330p {
  height: 330px !important;
}

.h-335p {
  height: 335px !important;
}

.h-340p {
  height: 340px !important;
}

.h-345p {
  height: 345px !important;
}

.h-350p {
  height: 350px !important;
}

.h-355p {
  height: 355px !important;
}

.h-360p {
  height: 360px !important;
}

.h-365p {
  height: 365px !important;
}

.h-370p {
  height: 370px !important;
}

.h-375p {
  height: 375px !important;
}

.h-380p {
  height: 380px !important;
}

.h-385p {
  height: 385px !important;
}

.h-390p {
  height: 390px !important;
}

.h-395p {
  height: 395px !important;
}

.h-400p {
  height: 400px !important;
}

.h-405p {
  height: 405px !important;
}

.h-410p {
  height: 410px !important;
}

.h-415p {
  height: 415px !important;
}

.h-420p {
  height: 420px !important;
}

.h-425p {
  height: 425px !important;
}

.h-430p {
  height: 430px !important;
}

.h-435p {
  height: 435px !important;
}

.h-440p {
  height: 440px !important;
}

.h-445p {
  height: 445px !important;
}

.h-450p {
  height: 450px !important;
}

.h-455p {
  height: 455px !important;
}

.h-460p {
  height: 460px !important;
}

.h-465p {
  height: 465px !important;
}

.h-470p {
  height: 470px !important;
}

.h-475p {
  height: 475px !important;
}

.h-480p {
  height: 480px !important;
}

.h-485p {
  height: 485px !important;
}

.h-490p {
  height: 490px !important;
}

.h-495p {
  height: 495px !important;
}

.h-500p {
  height: 500px !important;
}

.h-505p {
  height: 505px !important;
}

.h-510p {
  height: 510px !important;
}

.h-515p {
  height: 515px !important;
}

.h-520p {
  height: 520px !important;
}

.h-525p {
  height: 525px !important;
}

.h-530p {
  height: 530px !important;
}

.h-535p {
  height: 535px !important;
}

.h-540p {
  height: 540px !important;
}

.h-545p {
  height: 545px !important;
}

.h-550p {
  height: 550px !important;
}

.h-555p {
  height: 555px !important;
}

.h-560p {
  height: 560px !important;
}

.h-565p {
  height: 565px !important;
}

.h-570p {
  height: 570px !important;
}

.h-575p {
  height: 575px !important;
}

.h-580p {
  height: 580px !important;
}

.h-585p {
  height: 585px !important;
}

.h-590p {
  height: 590px !important;
}

.h-595p {
  height: 595px !important;
}

.h-600p {
  height: 600px !important;
}

.h-605p {
  height: 605px !important;
}

.h-610p {
  height: 610px !important;
}

.h-615p {
  height: 615px !important;
}

.h-620p {
  height: 620px !important;
}

.h-625p {
  height: 625px !important;
}

.h-630p {
  height: 630px !important;
}

.h-635p {
  height: 635px !important;
}

.h-640p {
  height: 640px !important;
}

.h-645p {
  height: 645px !important;
}

.h-650p {
  height: 650px !important;
}

.h-655p {
  height: 655px !important;
}

.h-660p {
  height: 660px !important;
}

.h-665p {
  height: 665px !important;
}

.h-670p {
  height: 670px !important;
}

.h-675p {
  height: 675px !important;
}

.h-680p {
  height: 680px !important;
}

.h-685p {
  height: 685px !important;
}

.h-690p {
  height: 690px !important;
}

.h-695p {
  height: 695px !important;
}

.h-700p {
  height: 700px !important;
}

.h-705p {
  height: 705px !important;
}

.h-710p {
  height: 710px !important;
}

.h-715p {
  height: 715px !important;
}

.h-720p {
  height: 720px !important;
}

.h-725p {
  height: 725px !important;
}

.h-730p {
  height: 730px !important;
}

.h-735p {
  height: 735px !important;
}

.h-740p {
  height: 740px !important;
}

.h-745p {
  height: 745px !important;
}

.h-750p {
  height: 750px !important;
}

.h-755p {
  height: 755px !important;
}

.h-760p {
  height: 760px !important;
}

.h-765p {
  height: 765px !important;
}

.h-770p {
  height: 770px !important;
}

.h-775p {
  height: 775px !important;
}

.h-780p {
  height: 780px !important;
}

.h-785p {
  height: 785px !important;
}

.h-790p {
  height: 790px !important;
}

.h-795p {
  height: 795px !important;
}

.h-800p {
  height: 800px !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.h-100 {
  height: 100% !important;
}

/*Max Height*/
.mh-100p {
  max-height: 100px !important;
}

.mh-125p {
  max-height: 125px !important;
}

.mh-150p {
  max-height: 150px !important;
}

.mh-175p {
  max-height: 175px !important;
}

.mh-200p {
  max-height: 200px !important;
}

.mh-225p {
  max-height: 225px !important;
}

.mh-250p {
  max-height: 250px !important;
}

.mh-275p {
  max-height: 275px !important;
}

.mh-300p {
  max-height: 300px !important;
}

.mh-325p {
  max-height: 325px !important;
}

.mh-350p {
  max-height: 350px !important;
}

.mh-375p {
  max-height: 375px !important;
}

.mh-400p {
  max-height: 400px !important;
}

.mh-425p {
  max-height: 425px !important;
}

.mh-450p {
  max-height: 450px !important;
}

.mh-475p {
  max-height: 475px !important;
}

.mh-500p {
  max-height: 500px !important;
}

.mh-525p {
  max-height: 525px !important;
}

.mh-550p {
  max-height: 550px !important;
}

.mh-575p {
  max-height: 575px !important;
}

.mh-600p {
  max-height: 600px !important;
}

.mh-625p {
  max-height: 625px !important;
}

.mh-650p {
  max-height: 650px !important;
}

.mh-675p {
  max-height: 675px !important;
}

.mh-700p {
  max-height: 700px !important;
}

.mh-725p {
  max-height: 725px !important;
}

.mh-750p {
  max-height: 750px !important;
}

.mh-775p {
  max-height: 775px !important;
}

.mh-800p {
  max-height: 800px !important;
}

/*Min Height*/
.mnh-100p {
  min-height: 100px !important;
}

.mnh-125p {
  min-height: 125px !important;
}

.mnh-150p {
  min-height: 150px !important;
}

.mnh-175p {
  min-height: 175px !important;
}

.mnh-200p {
  min-height: 200px !important;
}

.mnh-225p {
  min-height: 225px !important;
}

.mnh-250p {
  min-height: 250px !important;
}

.mnh-275p {
  min-height: 275px !important;
}

.mnh-300p {
  min-height: 300px !important;
}

.mnh-325p {
  min-height: 325px !important;
}

.mnh-350p {
  min-height: 350px !important;
}

.mnh-375p {
  min-height: 375px !important;
}

.mnh-400p {
  min-height: 400px !important;
}

.mnh-425p {
  min-height: 425px !important;
}

.mnh-450p {
  min-height: 450px !important;
}

.mnh-475p {
  min-height: 475px !important;
}

.mnh-500p {
  min-height: 500px !important;
}

.mnh-525p {
  min-height: 525px !important;
}

.mnh-550p {
  min-height: 550px !important;
}

.mnh-575p {
  min-height: 575px !important;
}

.mnh-600p {
  min-height: 600px !important;
}

.mnh-625p {
  min-height: 625px !important;
}

.mnh-650p {
  min-height: 650px !important;
}

.mnh-675p {
  min-height: 675px !important;
}

.mnh-700p {
  min-height: 700px !important;
}

.mnh-725p {
  min-height: 725px !important;
}

.mnh-750p {
  min-height: 750px !important;
}

.mnh-775p {
  min-height: 775px !important;
}

.mnh-800p {
  min-height: 800px !important;
}

/*Hr*/
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #eaecec;
}
hr.hr-light {
  border-top: 1px solid #f5f5f6;
}
hr.hr-light-10 {
  border-top: 1px solid #eaecec;
}
hr.hr-light-15 {
  border-top: 1px solid #e0e3e4;
}
hr.hr-light-20 {
  border-top: 1px solid #d6d9da;
}
hr.hr-light-30 {
  border-top: 1px solid #c1c6c8;
}
hr.hr-dark {
  border-top: 1px solid #324148;
}
hr.hr-dark-20 {
  border-top: 1px solid #2b383e;
}
hr.hr-dark-40 {
  border-top: 1px solid #273238;
}
hr.hr-white {
  border-top: 1px solid #fff;
}
hr.hr-primary {
  border-top: 1px solid #00acf0;
}
hr.hr-success {
  border-top: 1px solid #22af47;
}
hr.hr-info {
  border-top: 1px solid #1ebccd;
}
hr.hr-warning {
  border-top: 1px solid #ffbf36;
}
hr.hr-danger {
  border-top: 1px solid #f83f37;
}
hr.hr-secondary {
  border-top: 1px solid #9e9e9e;
}
hr.hr-soft-primary {
  border-top: 1px solid #b2e6fa;
}
hr.hr-soft-success {
  border-top: 1px solid #bce7c7;
}
hr.hr-soft-info {
  border-top: 1px solid #bbebf0;
}
hr.hr-soft-warning {
  border-top: 1px solid #ffecc2;
}
hr.hr-soft-danger {
  border-top: 1px solid #fdc5c3;
}
hr.hr-soft-secondary {
  border-top: 1px solid #e2e2e2;
}
hr.hr-2 {
  border-width: 2px !important;
}
hr.hr-3 {
  border-width: 3px !important;
}

/*Opacity*/
.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-35 {
  opacity: 0.35 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-45 {
  opacity: 0.45 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-55 {
  opacity: 0.55 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-65 {
  opacity: 0.65 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-85 {
  opacity: 0.85 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-95 {
  opacity: 0.95 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

/*Div Sizes*/
.d-8 {
  height: 8px !important;
  width: 8px !important;
}

.d-10 {
  height: 10px !important;
  width: 10px !important;
}

.d-12 {
  height: 12px !important;
  width: 12px !important;
}

.d-14 {
  height: 14px !important;
  width: 14px !important;
}

.d-16 {
  height: 16px !important;
  width: 16px !important;
}

.d-18 {
  height: 18px !important;
  width: 18px !important;
}

.d-20 {
  height: 20px !important;
  width: 20px !important;
}

.d-22 {
  height: 22px !important;
  width: 22px !important;
}

.d-24 {
  height: 24px !important;
  width: 24px !important;
}

.d-26 {
  height: 26px !important;
  width: 26px !important;
}

.d-28 {
  height: 28px !important;
  width: 28px !important;
}

.d-30 {
  height: 30px !important;
  width: 30px !important;
}

.d-32 {
  height: 32px !important;
  width: 32px !important;
}

.d-34 {
  height: 34px !important;
  width: 34px !important;
}

.d-36 {
  height: 36px !important;
  width: 36px !important;
}

.d-38 {
  height: 38px !important;
  width: 38px !important;
}

.d-40 {
  height: 40px !important;
  width: 40px !important;
}

.d-42 {
  height: 42px !important;
  width: 42px !important;
}

.d-44 {
  height: 44px !important;
  width: 44px !important;
}

.d-46 {
  height: 46px !important;
  width: 46px !important;
}

.d-48 {
  height: 48px !important;
  width: 48px !important;
}

.d-50 {
  height: 50px !important;
  width: 50px !important;
}

.d-54 {
  height: 54px !important;
  width: 54px !important;
}

.d-58 {
  height: 58px !important;
  width: 58px !important;
}

.d-62 {
  height: 62px !important;
  width: 62px !important;
}

.d-66 {
  height: 66px !important;
  width: 66px !important;
}

.d-70 {
  height: 70px !important;
  width: 70px !important;
}

.d-74 {
  height: 74px !important;
  width: 74px !important;
}

.d-78 {
  height: 78px !important;
  width: 78px !important;
}

.d-82 {
  height: 82px !important;
  width: 82px !important;
}

.d-86 {
  height: 86px !important;
  width: 86px !important;
}

.d-90 {
  height: 90px !important;
  width: 90px !important;
}

.d-94 {
  height: 94px !important;
  width: 94px !important;
}

.d-98 {
  height: 98px !important;
  width: 98px !important;
}

.d-100 {
  height: 100px !important;
  width: 100px !important;
}

.d-110 {
  height: 110px !important;
  width: 110px !important;
}

.d-120 {
  height: 120px !important;
  width: 120px !important;
}

.d-130 {
  height: 130px !important;
  width: 130px !important;
}

.d-140 {
  height: 140px !important;
  width: 140px !important;
}

.d-150 {
  height: 150px !important;
  width: 150px !important;
}

/*Z-Index*/
.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

.z-index-6 {
  z-index: 6 !important;
}

.z-index-7 {
  z-index: 7 !important;
}

.z-index-8 {
  z-index: 8 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-10 {
  z-index: 10 !important;
}

/*Loader*/
.preloader-it {
  background: #fff;
  position: fixed;
  z-index: 10001;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.loader-pendulums {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  margin: 0 auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 4rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid #eaecec;
}
.loader-pendulums:before,
.loader-pendulums:after {
  content: "";
  width: 1em;
  height: 1em;
  left: -0.1em;
  top: -0.1em;
  padding: 0.1em;
  display: block;
  border-radius: 50%;
  position: absolute;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  border: 0.1em solid;
  border-color: #adb3b6 transparent transparent transparent;
  -webkit-animation: pendulum infinite 2s cubic-bezier(0.3, 1.65, 0.7, -0.65);
  animation: pendulum infinite 2s cubic-bezier(0.3, 1.65, 0.7, -0.65);
}
.loader-pendulums:before {
  border-color: #324148 transparent transparent transparent;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  -webkit-animation-name: pendulum2;
  animation-name: pendulum2;
}

@-webkit-keyframes pendulum {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes pendulum {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes pendulum2 {
  from {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(520deg);
    -moz-transform: rotate(520deg);
    -ms-transform: rotate(520deg);
    -o-transform: rotate(520deg);
    transform: rotate(520deg);
  }
}
@keyframes pendulum2 {
  from {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  to {
    -webkit-transform: rotate(520deg);
    -moz-transform: rotate(520deg);
    -ms-transform: rotate(520deg);
    -o-transform: rotate(520deg);
    transform: rotate(520deg);
  }
}
/*Scrollbar*/
.slimScrollBar {
  right: 0 !important;
}

.nicescroll-rails.nicescroll-rails-vr {
  z-index: 999 !important;
}

/*Typography*/
.blockquote {
  font-size: 18px;
  padding: 15px 20px;
  background: #f5f5f6;
  color: #324148;
  border-left: 3px solid #00acf0;
}
.blockquote > p {
  margin-bottom: 5px;
}

.blockquote-footer {
  font-size: 76%;
}

.mark,
mark {
  padding: 3px 6px;
  background-color: #fef7c2;
  color: #324148;
  border-radius: 3px;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

code {
  background-color: #edf9fe;
  border-radius: 3px;
  padding: 3px 6px;
  font-size: 87.5%;
  color: #00acf0;
}

ul.list-ul {
  list-style: none;
  counter-reset: li;
}
ul.list-ul > li {
  padding-left: 30px;
}
ul.list-ul > li:before {
  content: "\2022";
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 26px;
  margin-left: -20px;
  position: relative;
  left: -10px;
  padding-top: 1px;
  top: 5px;
  line-height: 12px;
}

ol.list-ol {
  list-style: none;
  counter-reset: li;
}
ol.list-ol > li {
  padding-left: 30px;
  counter-increment: li;
}
ol.list-ol > li:before {
  content: counter(li);
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #eaecec;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  margin-left: -20px;
  position: relative;
  left: -10px;
  padding: 1px;
  top: -1px;
}

ul.list-icon {
  list-style: none;
}
ul.list-icon > li {
  padding-left: 30px;
  counter-increment: li;
}
ul.list-icon > li .feather-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  margin-left: -20px;
  position: relative;
  left: -10px;
  padding: 1px;
  top: -1px;
}

.display-1 {
  font-size: 5rem;
}

.display-2 {
  font-size: 4.5rem;
}

.display-3 {
  font-size: 3.5rem;
}

.display-4 {
  font-size: 2.5rem;
}

.display-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 576px) {
  .display-1 {
    font-size: 3.5rem;
  }

  .display-2 {
    font-size: 3rem;
  }

  .display-3 {
    font-size: 2.75rem;
  }

  .display-4 {
    font-size: 2.25rem;
  }
}

.btn-primary,
.btn-sky {
  color: #fff;
  background-color: #00acf0;
  border-color: #00acf0;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-sky:hover,
.btn-sky:focus {
  color: #fff;
  background-color: #00a0df;
  border-color: #00a0df;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-sky:not(:disabled):not(.disabled).active,
.btn-sky:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0293c9;
  border-color: #0293c9;
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-sky.disabled,
.btn-sky:disabled {
  color: #fff;
  background-color: #7fd5f7;
  border-color: #7fd5f7;
}

.btn-info,
.btn-cyan {
  color: #fff;
  background-color: #1ebccd;
  border-color: #1ebccd;
}
.btn-info:hover,
.btn-info:focus,
.btn-cyan:hover,
.btn-cyan:focus {
  color: #fff;
  background-color: #16a9b9;
  border-color: #16a9b9;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled).active,
.btn-cyan:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0e949f;
  border-color: #0e949f;
}
.btn-info.disabled,
.btn-info:disabled,
.btn-cyan.disabled,
.btn-cyan:disabled {
  color: #fff;
  background-color: #8edde6;
  border-color: #8edde6;
}

.btn-success,
.btn-green {
  color: #fff;
  background-color: #22af47;
  border-color: #22af47;
}
.btn-success:hover,
.btn-success:focus,
.btn-green:hover,
.btn-green:focus {
  color: #fff;
  background-color: #1aa23e;
  border-color: #1aa23e;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.btn-green:not(:disabled):not(.disabled).active,
.btn-green:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #179237;
  border-color: #179237;
}
.btn-success.disabled,
.btn-success:disabled,
.btn-green.disabled,
.btn-green:disabled {
  color: #fff;
  background-color: #90d7a3;
  border-color: #90d7a3;
}

.btn-warning,
.btn-yellow {
  color: #fff;
  background-color: #ffbf36;
  border-color: #ffbf36;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-yellow:hover,
.btn-yellow:focus {
  color: #fff;
  background-color: #ffb71d;
  border-color: #ffb71d;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-yellow:not(:disabled):not(.disabled).active,
.btn-yellow:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ffac19;
  border-color: #ffac19;
}
.btn-warning.disabled,
.btn-warning:disabled,
.btn-yellow.disabled,
.btn-yellow:disabled {
  color: #fff;
  background-color: #ffdf9a;
  border-color: #ffdf9a;
}

.btn-danger,
.btn-red {
  color: #fff;
  background-color: #f83f37;
  border-color: #f83f37;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-red:hover,
.btn-red:focus {
  color: #fff;
  background-color: #ff2f26;
  border-color: #ff2f26;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-red:not(:disabled):not(.disabled).active,
.btn-red:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ed1622;
  border-color: #ed1622;
}
.btn-danger.disabled,
.btn-danger:disabled,
.btn-red.disabled,
.btn-red:disabled {
  color: #fff;
  background-color: #fb9f9b;
  border-color: #fb9f9b;
}

.btn-secondary,
.btn-grey {
  color: #fff;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-grey:hover,
.btn-grey:focus {
  color: #fff;
  background-color: #919191;
  border-color: #919191;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.btn-grey:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #858484;
  border-color: #858484;
}
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-grey.disabled,
.btn-grey:disabled {
  color: #fff;
  background-color: #cecece;
  border-color: #cecece;
}

.btn-pink {
  color: #fff;
  background-color: #ed1b60;
  border-color: #ed1b60;
}
.btn-pink:hover,
.btn-pink:focus {
  color: #fff;
  background-color: #df195f;
  border-color: #df195f;
}
.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #be1b54;
  border-color: #be1b54;
}
.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #f68daf;
  border-color: #f68daf;
}

.btn-purple {
  color: #fff;
  background-color: #ab26aa;
  border-color: #ab26aa;
}
.btn-purple:hover,
.btn-purple:focus {
  color: #fff;
  background-color: #9a3092;
  border-color: #9a3092;
}
.btn-purple:not(:disabled):not(.disabled).active,
.btn-purple:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #882787;
  border-color: #882787;
}
.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #d592d4;
  border-color: #d592d4;
}

.btn-violet {
  color: #fff;
  background-color: #6640b2;
  border-color: #6640b2;
}
.btn-violet:hover,
.btn-violet:focus {
  color: #fff;
  background-color: #603bab;
  border-color: #603bab;
}
.btn-violet:not(:disabled):not(.disabled).active,
.btn-violet:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #5935a3;
  border-color: #5935a3;
}
.btn-violet.disabled,
.btn-violet:disabled {
  color: #fff;
  background-color: #b29fd8;
  border-color: #b29fd8;
}

.btn-indigo {
  color: #fff;
  background-color: #3a55b1;
  border-color: #3a55b1;
}
.btn-indigo:hover,
.btn-indigo:focus {
  color: #fff;
  background-color: #3650ab;
  border-color: #3650ab;
}
.btn-indigo:not(:disabled):not(.disabled).active,
.btn-indigo:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #2f4595;
  border-color: #2f4595;
}
.btn-indigo.disabled,
.btn-indigo:disabled {
  color: #fff;
  background-color: #9caad8;
  border-color: #9caad8;
}

.btn-blue {
  color: #fff;
  background-color: #0092ee;
  border-color: #0092ee;
}
.btn-blue:hover,
.btn-blue:focus {
  color: #fff;
  background-color: #0089e0;
  border-color: #0089e0;
}
.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #007fd0;
  border-color: #007fd0;
}
.btn-blue.disabled,
.btn-blue:disabled {
  color: #fff;
  background-color: #7fc8f6;
  border-color: #7fc8f6;
}

.btn-teal {
  color: #fff;
  background-color: #009b84;
  border-color: #009b84;
}
.btn-teal:hover,
.btn-teal:focus {
  color: #fff;
  background-color: #018975;
  border-color: #018975;
}
.btn-teal:not(:disabled):not(.disabled).active,
.btn-teal:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #028068;
  border-color: #028068;
}
.btn-teal.disabled,
.btn-teal:disabled {
  color: #fff;
  background-color: #7fcdc1;
  border-color: #7fcdc1;
}

.btn-neon {
  color: #fff;
  background-color: #88c241;
  border-color: #88c241;
}
.btn-neon:hover,
.btn-neon:focus {
  color: #fff;
  background-color: #79b62f;
  border-color: #79b62f;
}
.btn-neon:not(:disabled):not(.disabled).active,
.btn-neon:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #67a41c;
  border-color: #67a41c;
}
.btn-neon.disabled,
.btn-neon:disabled {
  color: #fff;
  background-color: #c3e0a0;
  border-color: #c3e0a0;
}

.btn-lime {
  color: #324148;
  background-color: #d0d962;
  border-color: #d0d962;
}
.btn-lime:hover,
.btn-lime:focus {
  color: #324148;
  background-color: #c3cc34;
  border-color: #c3cc34;
}
.btn-lime:not(:disabled):not(.disabled).active,
.btn-lime:not(:disabled):not(.disabled):active {
  color: #324148;
  background-color: #b7c11a;
  border-color: #b7c11a;
}
.btn-lime.disabled,
.btn-lime:disabled {
  color: #324148;
  background-color: #e7ecb0;
  border-color: #e7ecb0;
}

.btn-sun {
  color: #324148;
  background-color: #fde335;
  border-color: #fde335;
}
.btn-sun:hover,
.btn-sun:focus {
  color: #324148;
  background-color: #ecd11e;
  border-color: #ecd11e;
}
.btn-sun:not(:disabled):not(.disabled).active,
.btn-sun:not(:disabled):not(.disabled):active {
  color: #324148;
  background-color: #dfc414;
  border-color: #dfc414;
}
.btn-sun.disabled,
.btn-sun:disabled {
  color: #324148;
  background-color: #fef19a;
  border-color: #fef19a;
}

.btn-orange {
  color: #fff;
  background-color: #ff9528;
  border-color: #ff9528;
}
.btn-orange:hover,
.btn-orange:focus {
  color: #fff;
  background-color: #ff880e;
  border-color: #ff880e;
}
.btn-orange:not(:disabled):not(.disabled).active,
.btn-orange:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ff7800;
  border-color: #ff7800;
}
.btn-orange.disabled,
.btn-orange:disabled {
  color: #fff;
  background-color: #ffca93;
  border-color: #ffca93;
}

.btn-pumpkin {
  color: #fff;
  background-color: #ff6028;
  border-color: #ff6028;
}
.btn-pumpkin:hover,
.btn-pumpkin:focus {
  color: #fff;
  background-color: #fb551c;
  border-color: #fb551c;
}
.btn-pumpkin:not(:disabled):not(.disabled).active,
.btn-pumpkin:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #e8470f;
  border-color: #e8470f;
}
.btn-pumpkin.disabled,
.btn-pumpkin:disabled {
  color: #fff;
  background-color: #ffaf93;
  border-color: #ffaf93;
}

.btn-brown {
  color: #fff;
  background-color: #7a5449;
  border-color: #7a5449;
}
.btn-brown:hover,
.btn-brown:focus {
  color: #fff;
  background-color: #6f483d;
  border-color: #6f483d;
}
.btn-brown:not(:disabled):not(.disabled).active,
.btn-brown:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #633d32;
  border-color: #633d32;
}
.btn-brown.disabled,
.btn-brown:disabled {
  color: #fff;
  background-color: #bca9a4;
  border-color: #bca9a4;
}

.btn-gold {
  color: #fff;
  background-color: #c1993f;
  border-color: #c1993f;
}
.btn-gold:hover,
.btn-gold:focus {
  color: #fff;
  background-color: #b48c32;
  border-color: #b48c32;
}
.btn-gold:not(:disabled):not(.disabled).active,
.btn-gold:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #a27b25;
  border-color: #a27b25;
}
.btn-gold.disabled,
.btn-gold:disabled {
  color: #fff;
  background-color: #e0cc9f;
  border-color: #e0cc9f;
}

.btn-smoke {
  color: #fff;
  background-color: #5e7d8a;
  border-color: #5e7d8a;
}
.btn-smoke:hover,
.btn-smoke:focus {
  color: #fff;
  background-color: #4a6b78;
  border-color: #4a6b78;
}
.btn-smoke:not(:disabled):not(.disabled).active,
.btn-smoke:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #40606d;
  border-color: #40606d;
}
.btn-smoke.disabled,
.btn-smoke:disabled {
  color: #fff;
  background-color: #aebec4;
  border-color: #aebec4;
}

.btn-dark {
  color: #fff;
  background-color: #324148;
  border-color: #324148;
}
.btn-dark:hover,
.btn-dark:focus {
  color: #fff;
  background-color: #2b383e;
  border-color: #2b383e;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #273238;
  border-color: #273238;
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #adb3b6;
  border-color: #adb3b6;
}

.btn-light {
  color: #324148;
  background-color: #eaecec;
  border-color: #eaecec;
}
.btn-light:hover,
.btn-light:focus {
  color: #324148;
  background-color: #d6d9da;
  border-color: #d6d9da;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active {
  color: #324148;
  background-color: #c1c6c8;
  border-color: #c1c6c8;
}
.btn-light.disabled,
.btn-light:disabled {
  color: #324148;
  background-color: #f5f5f6;
  border-color: #f5f5f6;
}

.btn-gradient-primary {
  color: #fff;
  border: none;
  background: #00acf0;
  background: linear-gradient(45deg, #00acf0, #6640b2) !important;
  background: -webkit-bg-linear-gradient(45deg, #00acf0, #6640b2) !important;
}
.btn-gradient-primary:hover,
.btn-gradient-primary:focus {
  color: #fff;
  background: #00a0df;
  background: linear-gradient(45deg, #00a0df, #603bab) !important;
  background: -webkit-bg-linear-gradient(45deg, #00a0df, #603bab) !important;
}
.btn-gradient-primary:not(:disabled):not(.disabled).active,
.btn-gradient-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #0293c9;
  background: linear-gradient(45deg, #0293c9, #5935a3) !important;
  background: -webkit-bg-linear-gradient(45deg, #0293c9, #5935a3) !important;
}
.btn-gradient-primary.disabled,
.btn-gradient-primary:disabled {
  color: #fff;
  background: #7fd5f7;
  background: linear-gradient(45deg, #7fd5f7, #b29fd8) !important;
  background: -webkit-bg-linear-gradient(45deg, #7fd5f7, #b29fd8) !important;
}

.btn-gradient-info {
  color: #fff;
  border: none;
  background: #1ebccd;
  background: linear-gradient(45deg, #1ebccd, #3a55b1) !important;
  background: -webkit-bg-linear-gradient(45deg, #1ebccd, #3a55b1) !important;
}
.btn-gradient-info:hover,
.btn-gradient-info:focus {
  color: #fff;
  background: #16a9b9;
  background: linear-gradient(45deg, #16a9b9, #3650ab) !important;
  background: -webkit-bg-linear-gradient(45deg, #16a9b9, #3650ab) !important;
}
.btn-gradient-info:not(:disabled):not(.disabled).active,
.btn-gradient-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #0e949f;
  background: linear-gradient(45deg, #0e949f, #2f4595) !important;
  background: -webkit-bg-linear-gradient(45deg, #0e949f, #2f4595) !important;
}
.btn-gradient-info.disabled,
.btn-gradient-info:disabled {
  color: #fff;
  background: #8edde6;
  background: linear-gradient(45deg, #8edde6, #9caad8) !important;
  background: -webkit-bg-linear-gradient(45deg, #8edde6, #9caad8) !important;
}

.btn-gradient-success {
  color: #fff;
  border: none;
  background: #22af47;
  background: linear-gradient(45deg, #22af47, #d0d962) !important;
  background: -webkit-bg-linear-gradient(45deg, #22af47, #d0d962) !important;
}
.btn-gradient-success:hover,
.btn-gradient-success:focus {
  color: #fff;
  background: #1aa23e;
  background: linear-gradient(45deg, #1aa23e, #c3cc34) !important;
  background: -webkit-bg-linear-gradient(45deg, #1aa23e, #c3cc34) !important;
}
.btn-gradient-success:not(:disabled):not(.disabled).active,
.btn-gradient-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #179237;
  background: linear-gradient(45deg, #179237, #b7c11a) !important;
  background: -webkit-bg-linear-gradient(45deg, #179237, #b7c11a) !important;
}
.btn-gradient-success.disabled,
.btn-gradient-success:disabled {
  color: #fff;
  background: #90d7a3;
  background: linear-gradient(45deg, #90d7a3, #e7ecb0) !important;
  background: -webkit-bg-linear-gradient(45deg, #90d7a3, #e7ecb0) !important;
}

.btn-gradient-warning {
  color: #fff;
  border: none;
  background: #ffbf36;
  background: linear-gradient(45deg, #ffbf36, #ff6028) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffbf36, #ff6028) !important;
}
.btn-gradient-warning:hover,
.btn-gradient-warning:focus {
  color: #fff;
  background: #ffb71d;
  background: linear-gradient(45deg, #ffb71d, #fb551c) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffb71d, #fb551c) !important;
}
.btn-gradient-warning:not(:disabled):not(.disabled).active,
.btn-gradient-warning:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #ffac19;
  background: linear-gradient(45deg, #ffac19, #e8470f) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffac19, #e8470f) !important;
}
.btn-gradient-warning.disabled,
.btn-gradient-warning:disabled {
  color: #fff;
  background: #ffdf9a;
  background: linear-gradient(45deg, #ffdf9a, #ffaf93) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffdf9a, #ffaf93) !important;
}

.btn-gradient-danger {
  color: #fff;
  border: none;
  background: #f83f37;
  background: linear-gradient(45deg, #f83f37, #ab26aa) !important;
  background: -webkit-bg-linear-gradient(45deg, #f83f37, #ab26aa) !important;
}
.btn-gradient-danger:hover,
.btn-gradient-danger:focus {
  color: #fff;
  background: #ff2f26;
  background: linear-gradient(45deg, #ff2f26, #9a3092) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff2f26, #9a3092) !important;
}
.btn-gradient-danger:not(:disabled):not(.disabled).active,
.btn-gradient-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #ed1622;
  background: linear-gradient(45deg, #ed1622, #882787) !important;
  background: -webkit-bg-linear-gradient(45deg, #ed1622, #882787) !important;
}
.btn-gradient-danger.disabled,
.btn-gradient-danger:disabled {
  color: #fff;
  background: #fb9f9b;
  background: linear-gradient(45deg, #fb9f9b, #d592d4) !important;
  background: -webkit-bg-linear-gradient(45deg, #fb9f9b, #d592d4) !important;
}

.btn-gradient-secondary {
  color: #fff;
  border: none;
  background: #9e9e9e;
  background: linear-gradient(45deg, #9e9e9e, #5e7d8a) !important;
  background: -webkit-bg-linear-gradient(45deg, #9e9e9e, #5e7d8a) !important;
}
.btn-gradient-secondary:hover,
.btn-gradient-secondary:focus {
  color: #fff;
  background: #919191;
  background: linear-gradient(45deg, #919191, #4a6b78) !important;
  background: -webkit-bg-linear-gradient(45deg, #919191, #4a6b78) !important;
}
.btn-gradient-secondary:not(:disabled):not(.disabled).active,
.btn-gradient-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #858484;
  background: linear-gradient(45deg, #858484, #40606d) !important;
  background: -webkit-bg-linear-gradient(45deg, #858484, #40606d) !important;
}
.btn-gradient-secondary.disabled,
.btn-gradient-secondary:disabled {
  color: #fff;
  background: #cecece;
  background: linear-gradient(45deg, #cecece, #aebec4) !important;
  background: -webkit-bg-linear-gradient(45deg, #cecece, #aebec4) !important;
}

.btn-gradient-light {
  color: #324148;
  border: none;
  background: #f5f5f6;
  background: linear-gradient(45deg, #f5f5f6, #9e9e9e) !important;
  background: -webkit-bg-linear-gradient(45deg, #f5f5f6, #9e9e9e) !important;
}
.btn-gradient-light:hover,
.btn-gradient-light:focus {
  color: #324148;
  background: #e0e3e4;
  background: linear-gradient(45deg, #e0e3e4, #919191) !important;
  background: -webkit-bg-linear-gradient(45deg, #e0e3e4, #919191) !important;
}
.btn-gradient-light:not(:disabled):not(.disabled).active,
.btn-gradient-light:not(:disabled):not(.disabled):active {
  color: #324148;
  background: #c1c6c8;
  background: linear-gradient(45deg, #c1c6c8, #858484) !important;
  background: -webkit-bg-linear-gradient(45deg, #c1c6c8, #858484) !important;
}
.btn-gradient-light.disabled,
.btn-gradient-light:disabled {
  color: #324148;
  background: #f5f5f6;
  background: linear-gradient(45deg, #f5f5f6, #cecece) !important;
  background: -webkit-bg-linear-gradient(45deg, #f5f5f6, #cecece) !important;
}

.btn-gradient-dark {
  color: #fff;
  border: none;
  background: #324148;
  background: linear-gradient(45deg, #324148, #7a5449) !important;
  background: -webkit-bg-linear-gradient(45deg, #324148, #7a5449) !important;
}
.btn-gradient-dark:hover,
.btn-gradient-dark:focus {
  color: #fff;
  background: #2b383e;
  background: linear-gradient(45deg, #2b383e, #6f483d) !important;
  background: -webkit-bg-linear-gradient(45deg, #2b383e, #6f483d) !important;
}
.btn-gradient-dark:not(:disabled):not(.disabled).active,
.btn-gradient-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #273238;
  background: linear-gradient(45deg, #273238, #633d32) !important;
  background: -webkit-bg-linear-gradient(45deg, #273238, #633d32) !important;
}
.btn-gradient-dark.disabled,
.btn-gradient-dark:disabled {
  color: #fff;
  background: #324148;
  background: linear-gradient(45deg, #324148, #bca9a4) !important;
  background: -webkit-bg-linear-gradient(45deg, #324148, #bca9a4) !important;
}

.btn-gradient-pony {
  color: #fff;
  border: none;
  background: #ed1b60;
  background: linear-gradient(45deg, #ed1b60, #1ebccd) !important;
  background: -webkit-bg-linear-gradient(45deg, #ed1b60, #1ebccd) !important;
}
.btn-gradient-pony:hover,
.btn-gradient-pony:focus {
  color: #fff;
  background: #df195f;
  background: linear-gradient(45deg, #df195f, #16a9b9) !important;
  background: -webkit-bg-linear-gradient(45deg, #df195f, #16a9b9) !important;
}
.btn-gradient-pony:not(:disabled):not(.disabled).active,
.btn-gradient-pony:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #be1b54;
  background: linear-gradient(45deg, #be1b54, #0e949f) !important;
  background: -webkit-bg-linear-gradient(45deg, #be1b54, #0e949f) !important;
}
.btn-gradient-pony.disabled,
.btn-gradient-pony:disabled {
  color: #fff;
  background: #f68daf;
  background: linear-gradient(45deg, #f68daf, #8edde6) !important;
  background: -webkit-bg-linear-gradient(45deg, #f68daf, #8edde6) !important;
}

.btn-gradient-space {
  color: #fff;
  border: none;
  background: #ab26aa;
  background: linear-gradient(45deg, #ab26aa, #fde335) !important;
  background: -webkit-bg-linear-gradient(45deg, #ab26aa, #fde335) !important;
}
.btn-gradient-space:hover,
.btn-gradient-space:focus {
  color: #fff;
  background: #9a3092;
  background: linear-gradient(45deg, #9a3092, #ecd11e) !important;
  background: -webkit-bg-linear-gradient(45deg, #9a3092, #ecd11e) !important;
}
.btn-gradient-space:not(:disabled):not(.disabled).active,
.btn-gradient-space:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #882787;
  background: linear-gradient(45deg, #882787, #dfc414) !important;
  background: -webkit-bg-linear-gradient(45deg, #882787, #dfc414) !important;
}
.btn-gradient-space.disabled,
.btn-gradient-space:disabled {
  color: #fff;
  background: #d592d4;
  background: linear-gradient(45deg, #d592d4, #fef19a) !important;
  background: -webkit-bg-linear-gradient(45deg, #d592d4, #fef19a) !important;
}

.btn-gradient-streaks {
  color: #fff;
  border: none;
  background: #6640b2;
  background: linear-gradient(45deg, #6640b2, #009b84) !important;
  background: -webkit-bg-linear-gradient(45deg, #6640b2, #009b84) !important;
}
.btn-gradient-streaks:hover,
.btn-gradient-streaks:focus {
  color: #fff;
  background: #603bab;
  background: linear-gradient(45deg, #603bab, #018975) !important;
  background: -webkit-bg-linear-gradient(45deg, #603bab, #018975) !important;
}
.btn-gradient-streaks:not(:disabled):not(.disabled).active,
.btn-gradient-streaks:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #5935a3;
  background: linear-gradient(45deg, #5935a3, #028068) !important;
  background: -webkit-bg-linear-gradient(45deg, #5935a3, #028068) !important;
}
.btn-gradient-streaks.disabled,
.btn-gradient-streaks:disabled {
  color: #fff;
  background: #b29fd8;
  background: linear-gradient(45deg, #b29fd8, #7fcdc1) !important;
  background: -webkit-bg-linear-gradient(45deg, #b29fd8, #7fcdc1) !important;
}

.btn-gradient-bunting {
  color: #fff;
  border: none;
  background: #3a55b1;
  background: linear-gradient(45deg, #3a55b1, #7a5449) !important;
  background: -webkit-bg-linear-gradient(45deg, #3a55b1, #7a5449) !important;
}
.btn-gradient-bunting:hover,
.btn-gradient-bunting:focus {
  color: #fff;
  background: #3650ab;
  background: linear-gradient(45deg, #3650ab, #6f483d) !important;
  background: -webkit-bg-linear-gradient(45deg, #3650ab, #6f483d) !important;
}
.btn-gradient-bunting:not(:disabled):not(.disabled).active,
.btn-gradient-bunting:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #2f4595;
  background: linear-gradient(45deg, #2f4595, #633d32) !important;
  background: -webkit-bg-linear-gradient(45deg, #2f4595, #633d32) !important;
}
.btn-gradient-bunting.disabled,
.btn-gradient-bunting:disabled {
  color: #fff;
  background: #9caad8;
  background: linear-gradient(45deg, #9caad8, #bca9a4) !important;
  background: -webkit-bg-linear-gradient(45deg, #9caad8, #bca9a4) !important;
}

.btn-gradient-paradise {
  color: #fff;
  border: none;
  background: #0092ee;
  background: linear-gradient(45deg, #0092ee, #88c241) !important;
  background: -webkit-bg-linear-gradient(45deg, #0092ee, #88c241) !important;
}
.btn-gradient-paradise:hover,
.btn-gradient-paradise:focus {
  color: #fff;
  background: #0089e0;
  background: linear-gradient(45deg, #0089e0, #79b62f) !important;
  background: -webkit-bg-linear-gradient(45deg, #0089e0, #79b62f) !important;
}
.btn-gradient-paradise:not(:disabled):not(.disabled).active,
.btn-gradient-paradise:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #007fd0;
  background: linear-gradient(45deg, #007fd0, #67a41c) !important;
  background: -webkit-bg-linear-gradient(45deg, #007fd0, #67a41c) !important;
}
.btn-gradient-paradise.disabled,
.btn-gradient-paradise:disabled {
  color: #fff;
  background: #7fc8f6;
  background: linear-gradient(45deg, #7fc8f6, #c3e0a0) !important;
  background: -webkit-bg-linear-gradient(45deg, #7fc8f6, #c3e0a0) !important;
}

.btn-gradient-heaven {
  color: #fff;
  border: none;
  background: #88c241;
  background: linear-gradient(45deg, #88c241, #00acf0) !important;
  background: -webkit-bg-linear-gradient(45deg, #88c241, #00acf0) !important;
}
.btn-gradient-heaven:hover,
.btn-gradient-heaven:focus {
  color: #fff;
  background: #79b62f;
  background: linear-gradient(45deg, #79b62f, #00a0df) !important;
  background: -webkit-bg-linear-gradient(45deg, #79b62f, #00a0df) !important;
}
.btn-gradient-heaven:not(:disabled):not(.disabled).active,
.btn-gradient-heaven:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #67a41c;
  background: linear-gradient(45deg, #67a41c, #0293c9) !important;
  background: -webkit-bg-linear-gradient(45deg, #67a41c, #0293c9) !important;
}
.btn-gradient-heaven.disabled,
.btn-gradient-heaven:disabled {
  color: #fff;
  background: #c3e0a0;
  background: linear-gradient(45deg, #c3e0a0, #7fd5f7) !important;
  background: -webkit-bg-linear-gradient(45deg, #c3e0a0, #7fd5f7) !important;
}

.btn-gradient-honey {
  color: #fff;
  border: none;
  background: #d0d962;
  background: linear-gradient(45deg, #d0d962, #ff9528) !important;
  background: -webkit-bg-linear-gradient(45deg, #d0d962, #ff9528) !important;
}
.btn-gradient-honey:hover,
.btn-gradient-honey:focus {
  color: #fff;
  background: #c3cc34;
  background: linear-gradient(45deg, #c3cc34, #ff880e) !important;
  background: -webkit-bg-linear-gradient(45deg, #c3cc34, #ff880e) !important;
}
.btn-gradient-honey:not(:disabled):not(.disabled).active,
.btn-gradient-honey:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #b7c11a;
  background: linear-gradient(45deg, #b7c11a, #ff7800) !important;
  background: -webkit-bg-linear-gradient(45deg, #b7c11a, #ff7800) !important;
}
.btn-gradient-honey.disabled,
.btn-gradient-honey:disabled {
  color: #fff;
  background: #e7ecb0;
  background: linear-gradient(45deg, #e7ecb0, #ffca93) !important;
  background: -webkit-bg-linear-gradient(45deg, #e7ecb0, #ffca93) !important;
}

.btn-gradient-warbler {
  color: #fff;
  border: none;
  background: #fde335;
  background: linear-gradient(45deg, #fde335, #9e9e9e) !important;
  background: -webkit-bg-linear-gradient(45deg, #fde335, #9e9e9e) !important;
}
.btn-gradient-warbler:hover,
.btn-gradient-warbler:focus {
  color: #fff;
  background: #ecd11e;
  background: linear-gradient(45deg, #ecd11e, #919191) !important;
  background: -webkit-bg-linear-gradient(45deg, #ecd11e, #919191) !important;
}
.btn-gradient-warbler:not(:disabled):not(.disabled).active,
.btn-gradient-warbler:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #dfc414;
  background: linear-gradient(45deg, #dfc414, #858484) !important;
  background: -webkit-bg-linear-gradient(45deg, #dfc414, #858484) !important;
}
.btn-gradient-warbler.disabled,
.btn-gradient-warbler:disabled {
  color: #fff;
  background: #fef19a;
  background: linear-gradient(45deg, #fef19a, #cecece) !important;
  background: -webkit-bg-linear-gradient(45deg, #fef19a, #cecece) !important;
}

.btn-gradient-dusk {
  color: #fff;
  border: none;
  background: #ff9528;
  background: linear-gradient(45deg, #ff9528, #6640b2) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff9528, #6640b2) !important;
}
.btn-gradient-dusk:hover,
.btn-gradient-dusk:focus {
  color: #fff;
  background: #ff880e;
  background: linear-gradient(45deg, #ff880e, #603bab) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff880e, #603bab) !important;
}
.btn-gradient-dusk:not(:disabled):not(.disabled).active,
.btn-gradient-dusk:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #ff7800;
  background: linear-gradient(45deg, #ff7800, #5935a3) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff7800, #5935a3) !important;
}
.btn-gradient-dusk.disabled,
.btn-gradient-dusk:disabled {
  color: #fff;
  background: #ffca93;
  background: linear-gradient(45deg, #ffca93, #b29fd8) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffca93, #b29fd8) !important;
}

.btn-gradient-citrine {
  color: #fff;
  border: none;
  background: #ff6028;
  background: linear-gradient(45deg, #ff6028, #7a5449) !important;
  background: -webkit-bg-linear-gradient(45deg, #ff6028, #7a5449) !important;
}
.btn-gradient-citrine:hover,
.btn-gradient-citrine:focus {
  color: #fff;
  background: #fb551c;
  background: linear-gradient(45deg, #fb551c, #6f483d) !important;
  background: -webkit-bg-linear-gradient(45deg, #fb551c, #6f483d) !important;
}
.btn-gradient-citrine:not(:disabled):not(.disabled).active,
.btn-gradient-citrine:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #e8470f;
  background: linear-gradient(45deg, #e8470f, #633d32) !important;
  background: -webkit-bg-linear-gradient(45deg, #e8470f, #633d32) !important;
}
.btn-gradient-citrine.disabled,
.btn-gradient-citrine:disabled {
  color: #fff;
  background: #ffaf93;
  background: linear-gradient(45deg, #ffaf93, #bca9a4) !important;
  background: -webkit-bg-linear-gradient(45deg, #ffaf93, #bca9a4) !important;
}

.btn-gradient-royston {
  color: #fff;
  border: none;
  background: #7a5449;
  background: linear-gradient(45deg, #7a5449, #009b84) !important;
  background: -webkit-bg-linear-gradient(45deg, #7a5449, #009b84) !important;
}
.btn-gradient-royston:hover,
.btn-gradient-royston:focus {
  color: #fff;
  background: #6f483d;
  background: linear-gradient(45deg, #6f483d, #018975) !important;
  background: -webkit-bg-linear-gradient(45deg, #6f483d, #018975) !important;
}
.btn-gradient-royston:not(:disabled):not(.disabled).active,
.btn-gradient-royston:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #633d32;
  background: linear-gradient(45deg, #633d32, #028068) !important;
  background: -webkit-bg-linear-gradient(45deg, #633d32, #028068) !important;
}
.btn-gradient-royston.disabled,
.btn-gradient-royston:disabled {
  color: #fff;
  background: #bca9a4;
  background: linear-gradient(45deg, #bca9a4, #7fcdc1) !important;
  background: -webkit-bg-linear-gradient(45deg, #bca9a4, #7fcdc1) !important;
}

.btn-gradient-ashes {
  color: #fff;
  border: none;
  background: #5e7d8a;
  background: linear-gradient(45deg, #5e7d8a, #324148) !important;
  background: -webkit-bg-linear-gradient(45deg, #5e7d8a, #324148) !important;
}
.btn-gradient-ashes:hover,
.btn-gradient-ashes:focus {
  color: #fff;
  background: #4a6b78;
  background: linear-gradient(45deg, #4a6b78, #2b383e) !important;
  background: -webkit-bg-linear-gradient(45deg, #4a6b78, #2b383e) !important;
}
.btn-gradient-ashes:not(:disabled):not(.disabled).active,
.btn-gradient-ashes:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #40606d;
  background: linear-gradient(45deg, #40606d, #273238) !important;
  background: -webkit-bg-linear-gradient(45deg, #40606d, #273238) !important;
}
.btn-gradient-ashes.disabled,
.btn-gradient-ashes:disabled {
  color: #fff;
  background: #aebec4;
  background: linear-gradient(45deg, #aebec4, #324148) !important;
  background: -webkit-bg-linear-gradient(45deg, #aebec4, #324148) !important;
}

.btn-gradient-metal {
  color: #fff;
  border: none;
  background: #c1993f;
  background: linear-gradient(45deg, #c1993f, #9e9e9e) !important;
  background: -webkit-bg-linear-gradient(45deg, #c1993f, #9e9e9e) !important;
}
.btn-gradient-metal:hover,
.btn-gradient-metal:focus {
  color: #fff;
  background: #b48c32;
  background: linear-gradient(45deg, #b48c32, #919191) !important;
  background: -webkit-bg-linear-gradient(45deg, #b48c32, #919191) !important;
}
.btn-gradient-metal:not(:disabled):not(.disabled).active,
.btn-gradient-metal:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #a27b25;
  background: linear-gradient(45deg, #a27b25, #858484) !important;
  background: -webkit-bg-linear-gradient(45deg, #a27b25, #858484) !important;
}
.btn-gradient-metal.disabled,
.btn-gradient-metal:disabled {
  color: #fff;
  background: #e0cc9f;
  background: linear-gradient(45deg, #e0cc9f, #cecece) !important;
  background: -webkit-bg-linear-gradient(45deg, #e0cc9f, #cecece) !important;
}

.btn-gradient-sunset {
  color: #fff;
  border: none;
  background: #009b84;
  background: linear-gradient(45deg, #009b84, #ff6028) !important;
  background: -webkit-bg-linear-gradient(45deg, #009b84, #ff6028) !important;
}
.btn-gradient-sunset:hover,
.btn-gradient-sunset:focus {
  color: #fff;
  background: #018975;
  background: linear-gradient(45deg, #018975, #fb551c) !important;
  background: -webkit-bg-linear-gradient(45deg, #018975, #fb551c) !important;
}
.btn-gradient-sunset:not(:disabled):not(.disabled).active,
.btn-gradient-sunset:not(:disabled):not(.disabled):active {
  color: #fff;
  background: #028068;
  background: linear-gradient(45deg, #028068, #e8470f) !important;
  background: -webkit-bg-linear-gradient(45deg, #028068, #e8470f) !important;
}
.btn-gradient-sunset.disabled,
.btn-gradient-sunset:disabled {
  color: #fff;
  background: #7fcdc1;
  background: linear-gradient(45deg, #7fcdc1, #ffaf93) !important;
  background: -webkit-bg-linear-gradient(45deg, #7fcdc1, #ffaf93) !important;
}

.btn-outline-primary,
.btn-outline-primary,
.btn-outline-sky {
  color: #00acf0;
  background-color: transparent;
  background-image: none;
  border-color: #00acf0;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-sky:hover,
.btn-outline-sky:focus {
  color: #fff;
  background-color: #00a0df;
  border-color: #00a0df;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-sky:not(:disabled):not(.disabled).active,
.btn-outline-sky:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0293c9;
  border-color: #0293c9;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-sky.disabled,
.btn-outline-sky:disabled {
  color: #fff;
  background-color: #7fd5f7;
  border-color: #7fd5f7;
}

.btn-outline-info,
.btn-outline-cyan {
  color: #1ebccd;
  background-color: transparent;
  background-image: none;
  border-color: #1ebccd;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-cyan:hover,
.btn-outline-cyan:focus {
  color: #fff;
  background-color: #16a9b9;
  border-color: #16a9b9;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled).active,
.btn-outline-cyan:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #0e949f;
  border-color: #0e949f;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled,
.btn-outline-cyan.disabled,
.btn-outline-cyan:disabled {
  color: #fff;
  background-color: #8edde6;
  border-color: #8edde6;
}

.btn-outline-success,
.btn-outline-green {
  color: #22af47;
  background-color: transparent;
  background-image: none;
  border-color: #22af47;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-green:hover,
.btn-outline-green:focus {
  color: #fff;
  background-color: #1aa23e;
  border-color: #1aa23e;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.btn-outline-green:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #179237;
  border-color: #179237;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled,
.btn-outline-green.disabled,
.btn-outline-green:disabled {
  color: #fff;
  background-color: #90d7a3;
  border-color: #90d7a3;
}

.btn-outline-warning,
.btn-outline-yellow {
  color: #ffbf36;
  background-color: transparent;
  background-image: none;
  border-color: #ffbf36;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-yellow:hover,
.btn-outline-yellow:focus {
  color: #fff;
  background-color: #ffb71d;
  border-color: #ffb71d;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-yellow:not(:disabled):not(.disabled).active,
.btn-outline-yellow:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ffac19;
  border-color: #ffac19;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled,
.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
  color: #fff;
  background-color: #ffdf9a;
  border-color: #ffdf9a;
}

.btn-outline-danger,
.btn-outline-red {
  color: #f83f37;
  background-color: transparent;
  background-image: none;
  border-color: #f83f37;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-red:hover,
.btn-outline-red:focus {
  color: #fff;
  background-color: #ff2f26;
  border-color: #ff2f26;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-red:not(:disabled):not(.disabled).active,
.btn-outline-red:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ed1622;
  border-color: #ed1622;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled,
.btn-outline-red.disabled,
.btn-outline-red:disabled {
  color: #fff;
  background-color: #fb9f9b;
  border-color: #fb9f9b;
}

.btn-outline-secondary,
.btn-outline-grey {
  color: #9e9e9e;
  background-color: transparent;
  background-image: none;
  border-color: #9e9e9e;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-grey:hover,
.btn-outline-grey:focus {
  color: #fff;
  background-color: #919191;
  border-color: #919191;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-grey:not(:disabled):not(.disabled).active,
.btn-outline-grey:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #858484;
  border-color: #858484;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled,
.btn-outline-grey.disabled,
.btn-outline-grey:disabled {
  color: #fff;
  background-color: #cecece;
  border-color: #cecece;
}

.btn-outline-pink {
  color: #ed1b60;
  background-color: transparent;
  background-image: none;
  border-color: #ed1b60;
}
.btn-outline-pink:hover,
.btn-outline-pink:focus {
  color: #fff;
  background-color: #df195f;
  border-color: #df195f;
}
.btn-outline-pink:not(:disabled):not(.disabled).active,
.btn-outline-pink:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #be1b54;
  border-color: #be1b54;
}
.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #fff;
  background-color: #f68daf;
  border-color: #f68daf;
}

.btn-outline-purple {
  color: #ab26aa;
  background-color: transparent;
  background-image: none;
  border-color: #ab26aa;
}
.btn-outline-purple:hover,
.btn-outline-purple:focus {
  color: #fff;
  background-color: #9a3092;
  border-color: #9a3092;
}
.btn-outline-purple:not(:disabled):not(.disabled).active,
.btn-outline-purple:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #882787;
  border-color: #882787;
}
.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #fff;
  background-color: #d592d4;
  border-color: #d592d4;
}

.btn-outline-violet {
  color: #6640b2;
  background-color: transparent;
  background-image: none;
  border-color: #6640b2;
}
.btn-outline-violet:hover,
.btn-outline-violet:focus {
  color: #fff;
  background-color: #603bab;
  border-color: #603bab;
}
.btn-outline-violet:not(:disabled):not(.disabled).active,
.btn-outline-violet:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #5935a3;
  border-color: #5935a3;
}
.btn-outline-violet.disabled,
.btn-outline-violet:disabled {
  color: #fff;
  background-color: #b29fd8;
  border-color: #b29fd8;
}

.btn-outline-indigo {
  color: #3a55b1;
  background-color: transparent;
  background-image: none;
  border-color: #3a55b1;
}
.btn-outline-indigo:hover,
.btn-outline-indigo:focus {
  color: #fff;
  background-color: #3650ab;
  border-color: #3650ab;
}
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.btn-outline-indigo:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #2f4595;
  border-color: #2f4595;
}
.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
  color: #fff;
  background-color: #9caad8;
  border-color: #9caad8;
}

.btn-outline-blue {
  color: #0092ee;
  background-color: transparent;
  background-image: none;
  border-color: #0092ee;
}
.btn-outline-blue:hover,
.btn-outline-blue:focus {
  color: #fff;
  background-color: #0089e0;
  border-color: #0089e0;
}
.btn-outline-blue:not(:disabled):not(.disabled).active,
.btn-outline-blue:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #007fd0;
  border-color: #007fd0;
}
.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  color: #fff;
  background-color: #7fc8f6;
  border-color: #7fc8f6;
}

.btn-outline-teal {
  color: #009b84;
  background-color: transparent;
  background-image: none;
  border-color: #009b84;
}
.btn-outline-teal:hover,
.btn-outline-teal:focus {
  color: #fff;
  background-color: #018975;
  border-color: #018975;
}
.btn-outline-teal:not(:disabled):not(.disabled).active,
.btn-outline-teal:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #028068;
  border-color: #028068;
}
.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
  color: #fff;
  background-color: #7fcdc1;
  border-color: #7fcdc1;
}

.btn-outline-neon {
  color: #88c241;
  background-color: transparent;
  background-image: none;
  border-color: #88c241;
}
.btn-outline-neon:hover,
.btn-outline-neon:focus {
  color: #fff;
  background-color: #79b62f;
  border-color: #79b62f;
}
.btn-outline-neon:not(:disabled):not(.disabled).active,
.btn-outline-neon:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #67a41c;
  border-color: #67a41c;
}
.btn-outline-neon.disabled,
.btn-outline-neon:disabled {
  color: #fff;
  background-color: #c3e0a0;
  border-color: #c3e0a0;
}

.btn-outline-lime {
  color: #d0d962;
  background-color: transparent;
  background-image: none;
  border-color: #d0d962;
}
.btn-outline-lime:hover,
.btn-outline-lime:focus {
  color: #324148;
  background-color: #c3cc34;
  border-color: #c3cc34;
}
.btn-outline-lime:not(:disabled):not(.disabled).active,
.btn-outline-lime:not(:disabled):not(.disabled):active {
  color: #324148;
  background-color: #b7c11a;
  border-color: #b7c11a;
}
.btn-outline-lime.disabled,
.btn-outline-lime:disabled {
  color: #324148;
  background-color: #e7ecb0;
  border-color: #e7ecb0;
}

.btn-outline-sun {
  color: #fde335;
  background-color: transparent;
  background-image: none;
  border-color: #fde335;
}
.btn-outline-sun:hover,
.btn-outline-sun:focus {
  color: #324148;
  background-color: #ecd11e;
  border-color: #ecd11e;
}
.btn-outline-sun:not(:disabled):not(.disabled).active,
.btn-outline-sun:not(:disabled):not(.disabled):active {
  color: #324148;
  background-color: #dfc414;
  border-color: #dfc414;
}
.btn-outline-sun.disabled,
.btn-outline-sun:disabled {
  color: #324148;
  background-color: #fef19a;
  border-color: #fef19a;
}

.btn-outline-orange {
  color: #ff9528;
  background-color: transparent;
  background-image: none;
  border-color: #ff9528;
}
.btn-outline-orange:hover,
.btn-outline-orange:focus {
  color: #fff;
  background-color: #ff880e;
  border-color: #ff880e;
}
.btn-outline-orange:not(:disabled):not(.disabled).active,
.btn-outline-orange:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ff7800;
  border-color: #ff7800;
}
.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #fff;
  background-color: #ffca93;
  border-color: #ffca93;
}

.btn-outline-pumpkin {
  color: #ff6028;
  background-color: transparent;
  background-image: none;
  border-color: #ff6028;
}
.btn-outline-pumpkin:hover,
.btn-outline-pumpkin:focus {
  color: #fff;
  background-color: #fb551c;
  border-color: #fb551c;
}
.btn-outline-pumpkin:not(:disabled):not(.disabled).active,
.btn-outline-pumpkin:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #e8470f;
  border-color: #e8470f;
}
.btn-outline-pumpkin.disabled,
.btn-outline-pumpkin:disabled {
  color: #fff;
  background-color: #ffaf93;
  border-color: #ffaf93;
}

.btn-outline-brown {
  color: #7a5449;
  background-color: transparent;
  background-image: none;
  border-color: #7a5449;
}
.btn-outline-brown:hover,
.btn-outline-brown:focus {
  color: #fff;
  background-color: #6f483d;
  border-color: #6f483d;
}
.btn-outline-brown:not(:disabled):not(.disabled).active,
.btn-outline-brown:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #633d32;
  border-color: #633d32;
}
.btn-outline-brown.disabled,
.btn-outline-brown:disabled {
  color: #fff;
  background-color: #bca9a4;
  border-color: #bca9a4;
}

.btn-outline-gold {
  color: #c1993f;
  background-color: transparent;
  background-image: none;
  border-color: #c1993f;
}
.btn-outline-gold:hover,
.btn-outline-gold:focus {
  color: #fff;
  background-color: #b48c32;
  border-color: #b48c32;
}
.btn-outline-gold:not(:disabled):not(.disabled).active,
.btn-outline-gold:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #a27b25;
  border-color: #a27b25;
}
.btn-outline-gold.disabled,
.btn-outline-gold:disabled {
  color: #fff;
  background-color: #e0cc9f;
  border-color: #e0cc9f;
}

.btn-outline-smoke {
  color: #5e7d8a;
  background-color: transparent;
  background-image: none;
  border-color: #5e7d8a;
}
.btn-outline-smoke:hover,
.btn-outline-smoke:focus {
  color: #fff;
  background-color: #4a6b78;
  border-color: #4a6b78;
}
.btn-outline-smoke:not(:disabled):not(.disabled).active,
.btn-outline-smoke:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #40606d;
  border-color: #40606d;
}
.btn-outline-smoke.disabled,
.btn-outline-smoke:disabled {
  color: #fff;
  background-color: #aebec4;
  border-color: #aebec4;
}

.btn-outline-dark {
  color: #324148;
  background-color: transparent;
  background-image: none;
  border-color: #324148;
}
.btn-outline-dark:hover,
.btn-outline-dark:focus {
  color: #fff;
  background-color: #2b383e;
  border-color: #2b383e;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #273238;
  border-color: #273238;
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #fff;
  background-color: #adb3b6;
  border-color: #adb3b6;
}

.btn-outline-light {
  color: #324148;
  background-color: transparent;
  background-image: none;
  border-color: #eaecec;
}
.btn-outline-light:hover,
.btn-outline-light:focus {
  color: #324148;
  background-color: #d6d9da;
  border-color: #d6d9da;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #324148;
  background-color: #c1c6c8;
  border-color: #c1c6c8;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #324148;
  background-color: #f5f5f6;
  border-color: #f5f5f6;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 172, 240, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0.8);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 172, 240, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0);
  }
}

/*Table*/
.table thead th {
  border-top: none;
  border-bottom: none;
  vertical-align: middle;
}
.table.table-dark thead th {
  background: #324148;
}
.table th {
  font-weight: 500;
  color: #324148;
  font-size: 14px;
  text-transform: capitalize;
}
.table td,
.table th {
  border-top: 1px solid #eaecec;
  vertical-align: middle;
  padding: 0.75rem 1.25rem;
}
.table.table-hover tbody tr:hover,
.table.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #f5f5f6;
}
.table.table-bordered {
  border: none;
}
.table.table-bordered td {
  border-color: #eaecec;
  border-top: none;
}
.table.table-bordered th {
  border-top: 1px solid #eaecec;
  border-bottom: 1px solid #eaecec;
}
.table.table-flush td,
.table.table-flush th {
  border-top: none;
  border-bottom: none;
}
.table .thead-primary th,
.table .thead-blue th,
.table .thead-success th,
.table .thead-green th,
.table .thead-info th,
.table .thead-cyan th,
.table .thead-warning th,
.table .thead-yellow th,
.table .thead-danger th,
.table .thead-red th,
.table .thead-secondary th,
.table .thead-grey th {
  color: #fff;
}
.table .thead-primary th,
.table .thead-sky th {
  background-color: #00acf0;
}
.table .thead-success th,
.table .thead-green th {
  background-color: #22af47;
}
.table .thead-info th,
.table .thead-cyan th {
  background-color: #1ebccd;
}
.table .thead-warning th,
.table .thead-yellow th {
  background-color: #ffbf36;
}
.table .thead-danger th,
.table .thead-red th {
  background-color: #f83f37;
}
.table .thead-secondary th,
.table .thead-grey th {
  background-color: #9e9e9e;
}
.table .thead-pink th {
  background-color: #ed1b60;
}
.table .thead-purple th {
  background-color: #ab26aa;
}
.table .thead-violet th {
  background-color: #6640b2;
}
.table .thead-indigo th {
  background-color: #3a55b1;
}
.table .thead-blue th {
  background-color: #0092ee;
}
.table .thead-teal th {
  background-color: #009b84;
}
.table .thead-neon th {
  background-color: #88c241;
}
.table .thead-lime th {
  background-color: #d0d962;
}
.table .thead-sun th {
  background-color: #fde335;
}
.table .thead-orange th {
  background-color: #ff9528;
}
.table .thead-pumpkin th {
  background-color: #ff6028;
}
.table .thead-brown th {
  background-color: #7a5449;
}
.table .thead-gold th {
  background-color: #c1993f;
}
.table .thead-smoke th {
  background-color: #5e7d8a;
}
.table .thead-active th {
  background-color: #5e7d8a;
  color: #fff;
}
.table .thead-light th {
  background-color: #eaecec;
}
.table .thead-dark th {
  background-color: #324148;
  color: #fff;
}

.table-lg td,
.table-lg th {
  padding: 0.75rem 1.5rem;
}

.table-sm td,
.table-sm th {
  padding: 0.45rem 1.25rem;
}
.table-sm td {
  font-size: 15px;
}

.table-primary,
.table-sky {
  background-color: #edf9fe;
}
.table-primary > td,
.table-primary > th,
.table-sky > td,
.table-sky > th {
  background-color: #edf9fe;
}

.table-success,
.table-green {
  background-color: #eff9f2;
}
.table-success > td,
.table-success > th,
.table-green > td,
.table-green > th {
  background-color: #eff9f2;
}

.table-info,
.table-cyan {
  background-color: #effafb;
}
.table-info > td,
.table-info > th,
.table-cyan > td,
.table-cyan > th {
  background-color: #effafb;
}

.table-warning,
.table-yellow {
  background-color: #fff8eb;
}
.table-warning > td,
.table-warning > th,
.table-yellow > td,
.table-yellow > th {
  background-color: #fff8eb;
}

.table-danger,
.table-red {
  background-color: #fff1f1;
}
.table-danger > td,
.table-danger > th,
.table-red > td,
.table-red > th {
  background-color: #fff1f1;
}

.table-active {
  background-color: #f4f6f7;
}
.table-active > td,
.table-active > th {
  background-color: #f4f6f7;
}

.table-grey,
.table-secondary {
  background-color: #f5f5f5;
}
.table-grey > td,
.table-grey > th,
.table-secondary > td,
.table-secondary > th {
  background-color: #f5f5f5;
}

.table-pink {
  background-color: #feeff4;
}
.table-pink > td,
.table-pink > th {
  background-color: #feeff4;
}

.table-purple {
  background-color: #f9f0f9;
}
.table-purple > td,
.table-purple > th {
  background-color: #f9f0f9;
}

.table-violet {
  background-color: #f4f2fa;
}
.table-violet > td,
.table-violet > th {
  background-color: #f4f2fa;
}

.table-indigo {
  background-color: #f1f3f9;
}
.table-indigo > td,
.table-indigo > th {
  background-color: #f1f3f9;
}

.table-blue {
  background-color: #edf7fe;
}
.table-blue > td,
.table-blue > th {
  background-color: #edf7fe;
}

.table-teal {
  background-color: #edf8f6;
}
.table-teal > td,
.table-teal > th {
  background-color: #edf8f6;
}

.table-neon {
  background-color: #f3f9ec;
}
.table-neon > td,
.table-neon > th {
  background-color: #f3f9ec;
}

.table-lime {
  background-color: #f9faeb;
}
.table-lime > td,
.table-lime > th {
  background-color: #f9faeb;
}

.table-orange {
  background-color: #fff4e9;
}
.table-orange > td,
.table-orange > th {
  background-color: #fff4e9;
}

.table-pumpkin {
  background-color: #fff4f0;
}
.table-pumpkin > td,
.table-pumpkin > th {
  background-color: #fff4f0;
}

.table-brown {
  background-color: #f6f3f2;
}
.table-brown > td,
.table-brown > th {
  background-color: #f6f3f2;
}

.table-gold {
  background-color: #fbf8f1;
}
.table-gold > td,
.table-gold > th {
  background-color: #fbf8f1;
}

.table-smoke {
  background-color: #f4f6f7;
}
.table-smoke > td,
.table-smoke > th {
  background-color: #f4f6f7;
}

.table-light {
  background-color: #f5f5f6;
}
.table-light > td,
.table-light > th {
  background-color: #f5f5f6;
}

.table-dark {
  background: #2b383e;
}
.table-dark td,
.table-dark th {
  border-top-color: #2b383e;
}
.table-dark th {
  color: #fff;
}
.table-dark > td,
.table-dark > th {
  color: #fff;
  background: #2b383e;
}
.table-dark.table.table-bordered td,
.table-dark.table.table-bordered th {
  border-color: #2b383e;
}

@media (max-width: 1200px) {
  .table-responsive .table > tbody > tr > td,
  .table-responsive .table > tbody > tr > th,
  .table-responsive .table > tfoot > tr > td,
  .table-responsive .table > tfoot > tr > th,
  .table-responsive .table > thead > tr > td,
  .table-responsive .table > thead > tr > th {
    white-space: nowrap;
  }
}
/*Data Table*/
table.dataTable {
  box-sizing: border-box;
}
table.dataTable th,
table.dataTable td {
  box-sizing: border-box;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 0.75rem 1.25rem;
}
table.dataTable thead td,
table.dataTable thead th {
  border-bottom: none;
  font-weight: 500;
  position: relative;
}
table.dataTable thead td.sorting::before,
table.dataTable thead td.sorting_asc::before,
table.dataTable thead td.sorting_desc::before,
table.dataTable thead td.sorting::after,
table.dataTable thead td.sorting_asc::after,
table.dataTable thead td.sorting_desc::after,
table.dataTable thead th.sorting::before,
table.dataTable thead th.sorting_asc::before,
table.dataTable thead th.sorting_desc::before,
table.dataTable thead th.sorting::after,
table.dataTable thead th.sorting_asc::after,
table.dataTable thead th.sorting_desc::after {
  display: inline-block;
  font-family: "Ionicons";
  speak: none;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  z-index: 10;
  right: 7px;
}
table.dataTable thead td.sorting::before,
table.dataTable thead td.sorting_asc::before,
table.dataTable thead td.sorting_desc::before,
table.dataTable thead td.sorting::after,
table.dataTable thead td.sorting_asc::after,
table.dataTable thead td.sorting_desc::after,
table.dataTable thead th.sorting::before,
table.dataTable thead th.sorting_asc::before,
table.dataTable thead th.sorting_desc::before,
table.dataTable thead th.sorting::after,
table.dataTable thead th.sorting_asc::after,
table.dataTable thead th.sorting_desc::after {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  content: "";
  color: #0092ee;
}
table.dataTable thead td.sorting_asc::before,
table.dataTable thead th.sorting_asc::before {
  content: "\f11a";
}
table.dataTable thead td.sorting_desc::after,
table.dataTable thead th.sorting_desc::after {
  content: "\f118";
}
table.dataTable tfoot td,
table.dataTable tfoot th {
  border-top: none;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
  background: #eaecec;
}
table.dataTable.no-footer {
  border-bottom: none;
}
table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-top: 1px solid #eaecec;
}
table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: 1px solid #eaecec;
}
table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background: rgba(245, 245, 246, 0.5);
}
table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
  background: #324148;
  color: #fff;
}
table.dataTable tbody tr.selected {
  background: #324148;
  color: #fff;
}
table.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background: rgba(214, 217, 218, 0.2);
}
table.dataTable.display tbody tr.odd.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background: #324148;
  color: #fff;
}
table.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background: rgba(234, 236, 236, 0.3);
}
table.dataTable.display tbody tr.even.selected > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background: #324148;
  color: #fff;
}
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  background-color: #0092ee;
  color: #fff;
}
table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
  background: #324148;
  color: #fff;
}
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > tr:first-child {
  padding-left: 1.25rem;
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > tr:first-child:before {
  display: none;
}
table.dataTable > tbody > tr.child ul.dtr-details {
  width: 100%;
}
table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: none;
  padding: 0.25em;
}
table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0.25em;
}
table.dataTable > tbody > tr.child span.dtr-title {
  min-width: 80px;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  color: #324148;
}
table.dataTable > tbody > tr.child span.dtr-data {
  color: #848d91;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  margin-left: 0;
  color: #324148 !important;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0;
}
.dataTables_wrapper .dataTables_length select {
  width: 80px;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_length {
    display: none;
  }
}
/*Responsive Table*/
.tablesaw .tablesaw-sortable-head {
  padding: 0.75rem 1.25rem;
}
.tablesaw .tablesaw-sortable-head .tablesaw-sortable-arrow {
  position: absolute;
  right: 8px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  height: 24px;
}
.tablesaw .tablesaw-sortable-head .tablesaw-sortable-btn {
  padding: 0;
}
.tablesaw.tablesaw-swipe .tablesaw-swipe-cellpersist {
  border-right: none;
}
.tablesaw.tablesaw-stack td .tablesaw-cell-label,
.tablesaw .tablesaw-stack th .tablesaw-cell-label {
  font-weight: 500;
  color: #324148;
  font-size: 13px;
  text-transform: uppercase;
  padding: 3px 0.6em 0 0;
}

.tablesaw-bar .tablesaw-bar-section .tablesaw-btn {
  margin-left: 10px;
  margin-top: 0;
  padding: 0;
  height: auto;
  position: relative;
  top: -2px;
}
.tablesaw-bar .tablesaw-bar-section .tablesaw-btn span {
  display: none;
}
.tablesaw-bar .tablesaw-bar-section .tablesaw-btn:after {
  display: none;
}

.tablesaw-bar .tablesaw-bar-section label {
  color: #324148;
  text-transform: capitalize;
}
.tablesaw-bar .tablesaw-bar-section select {
  position: static;
  min-height: 1em;
  opacity: 1;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #324148;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: auto auto;
  background-size: 8px 10px;
  border: 2px solid #eaecec;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: border-color 0.15s ease-in-out;
  -moz-transition: border-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out;
}
.tablesaw-bar .tablesaw-bar-section select:focus {
  border-color: #00acf0;
}

.tablesaw-columntoggle-popup .btn-group > label {
  margin-right: 15px;
  margin-bottom: 0;
}
.tablesaw-columntoggle-popup .btn-group > label:last-child {
  margin-right: 0;
}
.tablesaw-columntoggle-popup .btn-group > label input {
  margin-right: 5px;
}

@media (max-width: 39.9375em) {
  .tablesaw-stack tbody tr {
    border-bottom: none;
  }

  .tablesaw-stack tbody tr:first-child {
    border-top: 1px solid #eaecec;
  }
}

/*Badge*/
.badge {
  font-weight: 500;
  border-radius: 4px;
  padding: 5px 7px;
  font-size: 72%;
  letter-spacing: 0.3px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
}
.badge.badge-indicator {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  padding: 0;
  position: relative;
}
.badge.badge-indicator:empty {
  display: inline-block;
}
.badge.badge-indicator.badge-indicator-sm {
  width: 5px;
  height: 5px;
}
.badge.badge-sm {
  padding: 3px 5px;
  font-size: 60%;
}
.badge.badge-info,
.badge.badge-cyan {
  background: #1ebccd;
  color: #fff;
}
.badge.badge-primary,
.badge.badge-sky {
  background: #00acf0;
  color: #fff;
}
.badge.badge-success,
.badge.badge-green {
  background: #22af47;
  color: #fff;
}
.badge.badge-danger,
.badge.badge-red {
  background: #f83f37;
  color: #fff;
}
.badge.badge-warning,
.badge.badge-yellow {
  background: #ffbf36;
  color: #fff;
}
.badge.badge-pink {
  background: #ed1b60;
  color: #fff;
}
.badge.badge-purple {
  background: #ab26aa;
  color: #fff;
}
.badge.badge-violet {
  background: #6640b2;
  color: #fff;
}
.badge.badge-indigo {
  background: #3a55b1;
  color: #fff;
}
.badge.badge-blue {
  background: #0092ee;
  color: #fff;
}
.badge.badge-teal {
  background: #009b84;
  color: #fff;
}
.badge.badge-neon {
  background: #88c241;
  color: #fff;
}
.badge.badge-lime {
  background: #d0d962;
  color: #fff;
}
.badge.badge-sun {
  background: #fde335;
  color: #fff;
}
.badge.badge-orange {
  background: #ff9528;
  color: #fff;
}
.badge.badge-pumpkin {
  background: #ff6028;
  color: #fff;
}
.badge.badge-brown {
  background: #7a5449;
  color: #fff;
}
.badge.badge-gold {
  background: #c1993f;
  color: #fff;
}
.badge.badge-smoke {
  background: #5e7d8a;
  color: #fff;
}
.badge.badge-secondary,
.badge.badge-grey {
  background: #9e9e9e;
  color: #fff;
}
.badge.badge-light {
  background: #eaecec;
  color: #324148;
}
.badge.badge-dark {
  background: #324148;
  color: #fff;
}
.badge.badge-outline {
  background: transparent;
  border: 2px solid;
}
.badge.badge-outline.badge-info,
.badge.badge-outline.badge-cyan {
  border-color: #1ebccd;
  color: #1ebccd;
}
.badge.badge-outline.badge-primary,
.badge.badge-outline.badge-sky {
  border-color: #00acf0;
  color: #00acf0;
}
.badge.badge-outline.badge-success,
.badge.badge-outline.badge-green {
  border-color: #22af47;
  color: #22af47;
}
.badge.badge-outline.badge-danger,
.badge.badge-outline.badge-red {
  border-color: #f83f37;
  color: #f83f37;
}
.badge.badge-outline.badge-warning,
.badge.badge-outline.badge-yellow {
  border-color: #ffbf36;
  color: #ffbf36;
}
.badge.badge-outline.badge-secondary,
.badge.badge-outline.badge-grey {
  border-color: #9e9e9e;
  color: #9e9e9e;
}
.badge.badge-outline.badge-light {
  border-color: #eaecec;
  color: #324148;
}
.badge.badge-outline.badge-dark {
  border-color: #324148;
  color: #324148;
}
.badge.badge-outline.badge-pink {
  border-color: #ed1b60;
  color: #ed1b60;
}
.badge.badge-outline.badge-purple {
  border-color: #ab26aa;
  color: #ab26aa;
}
.badge.badge-outline.badge-violet {
  border-color: #6640b2;
  color: #6640b2;
}
.badge.badge-outline.badge-indigo {
  border-color: #3a55b1;
  color: #3a55b1;
}
.badge.badge-outline.badge-blue {
  border-color: #0092ee;
  color: #0092ee;
}
.badge.badge-outline.badge-teal {
  border-color: #009b84;
  color: #009b84;
}
.badge.badge-outline.badge-neon {
  border-color: #88c241;
  color: #88c241;
}
.badge.badge-outline.badge-lime {
  border-color: #d0d962;
  color: #d0d962;
}
.badge.badge-outline.badge-sun {
  border-color: #fde335;
  color: #fde335;
}
.badge.badge-outline.badge-orange {
  border-color: #ff9528;
  color: #ff9528;
}
.badge.badge-outline.badge-pumpkin {
  border-color: #ff6028;
  color: #ff6028;
}
.badge.badge-outline.badge-brown {
  border-color: #7a5449;
  color: #7a5449;
}
.badge.badge-outline.badge-gold {
  border-color: #c1993f;
  color: #c1993f;
}
.badge.badge-soft-cyan,
.badge.badge-soft-info {
  background: #effafb;
  color: #1ebccd;
}
.badge.badge-soft-sky,
.badge.badge-soft-primary {
  background: #edf9fe;
  color: #00acf0;
}
.badge.badge-soft-green,
.badge.badge-soft-success {
  background: #eff9f2;
  color: #22af47;
}
.badge.badge-soft-red,
.badge.badge-soft-danger {
  background: #fff1f1;
  color: #f83f37;
}
.badge.badge-soft-yellow,
.badge.badge-soft-warning {
  background: #fff8eb;
  color: #ffbf36;
}
.badge.badge-soft-pink {
  background: #feeff4;
  color: #ed1b60;
}
.badge.badge-soft-purple {
  background: #f9f0f9;
  color: #ab26aa;
}
.badge.badge-soft-violet {
  background: #f4f2fa;
  color: #6640b2;
}
.badge.badge-soft-indigo {
  background: #f1f3f9;
  color: #3a55b1;
}
.badge.badge-soft-blue {
  background: #edf7fe;
  color: #0092ee;
}
.badge.badge-soft-teal {
  background: #edf8f6;
  color: #009b84;
}
.badge.badge-soft-neon {
  background: #f3f9ec;
  color: #88c241;
}
.badge.badge-soft-lime {
  background: #f9faeb;
  color: #d0d962;
}
.badge.badge-soft-sun {
  background: #fffbe5;
  color: #fde335;
}
.badge.badge-soft-orange {
  background: #fff4e9;
  color: #ff9528;
}
.badge.badge-soft-pumpkin {
  background: #fff4f0;
  color: #ff6028;
}
.badge.badge-soft-brown {
  background: #f6f3f2;
  color: #7a5449;
}
.badge.badge-soft-gold {
  background: #fbf8f1;
  color: #c1993f;
}
.badge.badge-soft-smoke {
  background: #f4f6f7;
  color: #5e7d8a;
}
.badge.badge-soft-grey,
.badge.badge-soft-secondary {
  background: #f5f5f5;
  color: #9e9e9e;
}
.badge.badge-pill {
  border-radius: 50px;
}

.badge-indicator-demo .badge-indicator {
  bottom: 2px;
}

.btn .badge {
  top: -2px;
}

.home_gallery .ReactGridGallery_tile-viewport {
  width: 130px !important;
  height: 90px !important;
}
.main-nav a.active {
  border: 2px solid #ef9a33;
  text-shadow: 0 0 black;
}

/**********************************************************************/

.h-blog ul li {
  border-bottom: 1px solid #dcdcdc;
  padding: 12px 0px;
  position: relative;
  overflow: hidden;
}
.ho-ev-date {
  border-radius: 3px;
  display: inline-block;
  float: left;
  color: #fff;
  text-align: center;
  padding: 14px 0;
  margin-right: 20px;
  text-transform: uppercase;
  width: 20%;
  background: #02294e;
  background: -webkit-linear-gradient(to top, #012951, #03294e);
  background: linear-gradient(to top, #012951, #03294e);
}
.ho-ev-date span:first-child {
  display: block;
  font-size: 25px;
  font-weight: 500;
  margin-top: 0px;
  line-height: 25px;
  color: #fff;
}
.ho-ev-date span:last-child {
  font-size: 12px;
  color: #fff;
}
.ho-ev-link {
  float: left;
  width: 60%;
}
.ho-ev-link h4 {
  font-size: 19px;
  color: #000;
  font-weight: 600;
}
.ho-ev-link p {
  color: #203245;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  font-size: 14px;
}
.ho-ev-link span {
  color: #959595;
  font-size: 12px;
  font-weight: 500;
}
.bot-gal > h4 {
  font-size: 17px;
  text-transform: uppercase;
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

footer {
  /* background-image:url('img/1.jpg'); */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0px -100px;
}
#site_footer .overlay {
  /* 	background: #ffffffc2; */
  background-color: #d6edd1;
}

.top-announcement {
  position: fixed;
  left: 0px;
  width: 100%;
  bottom: 0px;
  z-index: 999;
  /* background: #f4623a; */
  padding-top: 18px;
  color: #fff;
  background: linear-gradient(#8a0b059c 5%, #ce4f2c 22%) !important;
  height: 45px;
}

.top-announcement a {
  color: #fff;
  margin-right: 30px;
}
.top-announcement a:before {
  content: "\f2fc";
  font-family: "Ionicons";
  margin-right: 10px;
}
ul.announceul-top {
  display: inline !important;
}

.top-announcement .ta-header {
  background: #fffd26;
  position: absolute;
  top: 0px;
  color: #000;
  padding: 14px 20px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 0px 100px 100px 0px;
  z-index: 9;
}
.cs-announcement-top {
  /*background-image:url(img/announcement-bg1.png) !important;
	background-size: 100% 100% !important;*/
}

.inner-page-header .nav-item .nav-link {
  color: #000 !important;
}
.inner-page-header .top-title2 {
  color: #000 !important;
}
.inner-page-header #main {
  margin-top: 65px;
}
.page-inner-header {
  min-height: 160px;
  /*background: linear-gradient(180deg, #028001e0, #ffffff1f);
	  background-image: url(http://tdmnsc.com/img/bg_sub-header.png) repeat 0 0;*/
  /*background: linear-gradient(180deg, #028001e0, #096b05);*/
  background: linear-gradient(180deg, #01949ae0, #0d6a79);
}
.page-in {
  min-height: 450px;
}
ul.mmm-sidebar-menu {
  padding-left: 23px;
  list-style: none;
  font-size: 14px;
}
ul.mmm-sidebar-menu li {
  padding-bottom: 10px;
  width: 100%;
}
ul.mmm-sidebar-menu li:before {
  content: "\f125";
  font-family: "Ionicons";
  float: left;
  margin-right: 10px;
  font-size: 13px;
  margin-top: 5px;
}
.mmm-sidebar-menu-title {
  color: #0f727f;
  font-size: 18px;
}
.header.masthead {
  background: linear-gradient(180deg, #074a4ce0, #18b2cc) !important;
}
ul.list-style-none {
  list-style: none;
}
ul.list-style-none li {
  list-style: none;
  margin-bottom: 5px;
}

.slider-top-placeHolder {
  background: linear-gradient(180deg, #333232b3 40%, transparent);
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
}

@media only screen and (max-width: 700px) {
  .page-inner-header {
    display: none;
  }
  .page-in {
    margin-top: 125px;
  }
  .carousel-inner {
    margin-top: 10px;
    max-height: 250px;
  }
  .carousel-item img {
    max-height: 250px;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .mgt-user-container .row {
    margin-left: 25px !important;
  }
  .mgt-user-container .col-md-2 {
    max-width: 25% !important;
    width: 25% !important;
    padding: 10px;
  }
}

.cl__dept__menu__active {
  color: #0f727f !important;
  font-size: 15px;
  font-weight: 600;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}

.collapsible-header,
.collapsible-body {
  padding: 10px;
}

#mainNav {
  margin-top: 25px;
}
#mainNav.navbar-scrolled {
  margin-top: 0px;
}

a#logo {
  width: 1140px;
  height: 158px;
  background: url(img/clg-logo.png) no-repeat;
  text-indent: -9999px;
  display: block;
}

/*** NEW HEADER ****/
.cl__header {
  /*background: linear-gradient(180deg, #01949ae0, #0d6a79);*/
  /* background-image: linear-gradient(180deg, #003f58, #0d6a79); */
  background-color: white;
}

.cl__header img {
  max-width: 90%;
}
.cl__header .top-title2 {
  font-size: 15px;
  text-align: center;
  position: unset;
  /* top: 50px; */
  letter-spacing: 1px;
  width: 100%;
  z-index: 0;
  /* color: #d20925; */
  color: #ffff00;
  left: 0px;
  font-weight: 600;
  /* line-height: 30px; */
  /* margin-bottom: 28px; */
  padding-bottom: 2px;
}
.cl__header .top-title {
  margin: 0px;
  margin-top: 5px;
  /*text-shadow: 2px 2px gainsboro;*/
  text-align: center;
  position: unset;
  top: 14px;
  width: 100%;
  z-index: 9;
  /* color: #047305; */
  left: 0px;
  font-size: 40px;
  /* text-transform: uppercase; */
  color: #fff;
  /* font-family: 'Aladin', cursive; */
  /*font-family: Aladin, fantasy;*/
  letter-spacing: 1px;
}
/*** NEW HEADER ****/

.cs-announcement-top a {
  /* color: #fff; */
  /* color: #003f58;
	font-size: 14px; */
  color: #003f58;
  font-size: 18px;
  font-family: "FontAwesome";
  font-weight: 900;
}
.cs-announcement-top li {
  border-bottom: 1px solid #e0dbdb;
}

.cl__image__bg {
  min-height: 135px;
  background-color: grey;
  background-image: linear-gradient(to top, #adadad, #f7f7f700);
}

.staff-profile {
  cursor: pointer;
  margin-bottom: 30px;
}
.staff-profile .profile-image {
  text-align: center;
  background-image: linear-gradient(0deg, #b1acac, #696566f2);
  border-radius: 20px 20px 0px 0px;
}
.staff-profile .profile-image img {
  width: 100%;
  /*height: 280px;*/
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  width: 100%;
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.staff-profile .profile-info h5 {
  display: inline-block;
  outline: none;
  text-overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 0px;
  font-size: 15px;
}
.staff-profile .profile-info p {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.staff-profile .profile-info {
  background-color: #cddc39;
  text-align: center;
  padding: 15px 5px;
  color: #000;
  border-radius: 0px 0px 20px 20px;
}
.staff-profile:hover .profile-info h5 {
  /*background-color: #e91e63;*/
  font-weight: 800;
}
.staff-profile .profile-image img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.staff-profile .profile-image figure {
  overflow: hidden;
  margin: 0px;
  border-radius: 20px 20px 0px 0px;
}

.table.table-bordered {
  border: 1px solid #dee2e6 !important;
}
.user-custom-page-content .card {
  border: 0px;
  margin-bottom: 3px;
}
.user-custom-page-content .card-body {
  font-size: 19px;
  line-height: 30px;
  font-family: Times New Roman;
  border: 1px solid #dcdcdc;
  border-radius: 10px !important;
}
.user-custom-page-content .card-header {
  padding: 5px;
  background-color: #cecece;
  color: #000;
  border-radius: 10px !important;
  border: 0px;
}
.user-custom-page-content .card-header button {
  font-size: 20px;
  font-family: Times New Roman;
  font-weight: 600;
  color: #000;
}
.user-custom-page-content .card-header button:hover,
.user-custom-page-content .card-header button:focus {
  text-decoration: none;
}

/*-------------------------------------------------*/
/* = 30.MOBILE MANU 
/*-------------------------------------------------*/
.ed-mob-menu {
  position: fixed;
  top: 0px;
  /* width:100%; */
  height: 55px;
  background: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
  z-index: 999;
  display: none;
  right: 0px;
}
.ed-mob-menu-con {
  width: 90%;
  margin: 0 auto;
}
.ed-mm-left {
  float: left;
  width: 50%;
  box-sizing: border-box;
}
.wed-logo {
}
.wed-logo a {
}
.wed-logo a img {
}
.ed-mm-right {
  float: left;
  width: 50%;
  box-sizing: border-box;
}
.ed-mm-menu {
}
.ed-micon {
  float: right;
}
.ed-micon i {
  width: 32px;
  height: 32px;
  border: 1px solid #0a2444;
  text-align: center;
  font-size: 20px;
  padding: 5px;
  border-radius: 2px;
  margin-top: 10px;
  color: #0a2444;
  cursor: pointer;
}
.ed-mm-inn {
  position: fixed;
  width: 70%;
  background: #fff;
  padding: 50px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
  overflow-y: auto;
  right: -80%;
  height: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ed-mm-act {
  right: 0%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ed-mi-close {
  position: absolute;
  z-index: 99;
  margin: -50px 8px 8px -30px;
}
.ed-mi-close i {
  width: 32px;
  height: 32px;
  border: 1px solid #0a2444;
  text-align: center;
  font-size: 20px;
  padding: 5px;
  border-radius: 2px;
  margin-top: 10px;
  color: #0a2444;
  cursor: pointer;
}
.ed-mm-inn h4 {
}
.ed-mm-inn ul {
  margin-bottom: 30px;
  margin-top: 10px;
}
.ed-mm-inn ul li {
}
.ed-mm-inn ul li a {
  display: block;
  border-bottom: 1px solid #ececec;
  padding: 6px 0px 6px 25px;
  font-size: 14px;
}
.ed-mm-inn ul li a:before {
  content: "\f105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  font-size: 14px;
  color: black;
  margin-left: -25px;
}
.affix {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 55px;
  background: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
  z-index: 999;
  padding: 1px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#mobile_menu ul {
  padding-left: 0px;
  margin-left: 0px;
  list-style: none;
}

#mobile_menu {
  padding-left: 20px;
}
.ed-mi-close {
  padding-left: 25px;
}
.ed-mm-inn ul li a {
  font-size: 16px;
}

.mob-show {
  display: none;
}

@media only screen and (max-width: 800px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden !important;
  }
  .mob-25 {
    width: 20%;
    padding: 5px;
  }
  .mob-50 {
    width: 60%;
    padding: 0px;
  }
  .mob-50_1 {
    width: 50%;
  }
  .home-quick-links .mob-50_1 {
    margin-top: 20px;
  }
  .home-quick-links .mob-50_1 h4 {
    font-size: 15px !important;
    font-family: cursive !important;
  }
  .top-title {
    font-size: 16px !important;
  }
  .top-title2 {
    display: none;
  }
  .mob-hide {
    display: none;
  }
  .cl__header {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    height: 65px;
  }
  main {
    overflow-x: scroll;
  }
  header.masthead {
    padding-top: 3rem !important;
  }
  footer,
  #site_footer {
    /*   background-image: linear-gradient(45deg, #178791, #248490); */
    background-repeat: initial;
  }
  .page-in {
    margin-top: 70px;
  }
  .mob-show {
    display: block;
  }
  .mob-menu-nav-icon {
    font-size: 30px;
    color: #fff;
    padding-top: 3px;
    float: right;
    margin-right: 17px;
  }
}

.custom-ematerial ul.collapsible {
  padding: 0px;
  margin: 0px;
}
.custom-ematerial .collapsible-header {
  background-color: #003f58;
  cursor: pointer;
}
.custom-ematerial .collapsible-header-active {
  background-color: #c4082a;
}
.custom-ematerial .collapsible-header {
  color: #fff;
}
.custom-ematerial .collapsible-body {
  -webkit-transition: -webkit-transform 0.3s ease;
  -moz-transition: -moz-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
  border: 1px solid #ecebeb;
  display: block;
}

.custom-ematerial .hide-collapsible-body {
  display: none;
  height: 0px;
}

.social:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.social {
  -webkit-transform: scale(0.8);
  /* Browser Variations: */

  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
}

/*
    Multicoloured Hover Variations
*/

/*  #social-fb:hover {
     color: #3B5998;
 }
 #social-tw:hover {
     color: #4099FF;
 }
 #social-gp:hover {
     color: #d34836;
 }
 #social-em:hover {
     color: #f39c12;
 } */

#site_footer a {
  color: #000;
}
#site_footer a:hover {
  color: #000;
}
#site_footer i.social {
  color: #fff;
}
#site_footer i.social:hover {
  color: #fff;
}
.mob-menu-titlte {
  position: absolute;
  top: 13px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.mob-menu-title-hr {
  position: absolute;
  top: 32px;
  width: 100%;
  left: 0px;
}

.mob-menu-header {
  /* background-image: linear-gradient(45deg, #127b77, #00c6ad6e);*/
  /* background-color:purple; */
  background-color: #426f45;
  width: 100%;
  height: 52px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ed-mi-close {
  left: 15px;
  top: 48px;
  color: #fff;
}
.ed-mi-close i {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.home-quick-links a {
  margin-top: 15px;
}
.home-quick-links a:hover {
  text-decoration: none;
}

.home-quick-links a:hover img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.ps__rec ul {
  display: flex;
  list-style: none;
}

.ps__rec ul li {
  display: inline;
  list-style: none;
  padding: 0 15px;
}

.ps__rec ul li img {
  border: 1px solid #efefef;
}
