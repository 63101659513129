footer#site_footer{
	font-size: 15px;
	line-height: 24px;
}
footer#site_footer h3{
	font-size: 20px;
}

footer#site_footer ul{
	padding-left: 6px;
	list-style: none;
}
footer#site_footer ul.style-1 li:before {
	content: "\f125";
    font-family: "Ionicons";
	font-size:14px;
	padding-right:8px;
	color:#e66030;
}

footer .copyright{
	/* background: linear-gradient(#02192f 3%,#03294e 22%) !important; */
	    padding: 5px;
    /* background: linear-gradient(#f55d56d1 3%,#f4623a 22%) !important; */
	/* background: linear-gradient(#520d52 3%,#8c088c 22%) !important; */
  background-color: #426f45;
   /*  color: #f5bcba; */
   color: white;
	font-size:16px;
}

footer .copyright a{
	color: #fff;
}



#cs-mySidenav{
	position: fixed;
    top: 110px;
    right: -5px;
}
/* Style the links inside the sidenav */
#cs-mySidenav a {
  position: absolute; /* Position them relative to the browser window */
  right: -130px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 8px 15px; /* 15px padding */
  width: 180px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  font-size: 18px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
}

#cs-mySidenav a:hover {
  right: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#cs-mySidenav #about {
  top: 20px;
  background-color: #4CAF50;
}

#cs-mySidenav #blog {
  top: 65px;
  background-color: #2196F3; /* Blue */
}

#cs-mySidenav #projects {
  top: 110px;
  background-color: #f44336; /* Red */
}

#cs-mySidenav #contact {
  top: 155px;
  background-color: #555 /* Light Black */
}


@media only screen and (max-width: 600px) {
	#cs-mySidenav {
		display: none;
	}
}