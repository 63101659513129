.logo-imgs {
  width: 100%;
  max-width: 150px;
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
}
.top-title {
  text-shadow: 2px 2px black;
  text-align: center;
  position: fixed;
  top: 10px;
  width: 100%;
  z-index: 999;
  color: #fff92f;
  left: 0px;
}
.top-title2 {
  font-size: 12px;
  text-align: center;
  position: fixed;
  top: 50px;
  letter-spacing: 1px;
  width: 100%;
  z-index: 999;
  color: white;
  left: 0px;
}
.navbar-scrolled .logo-imgs {
  max-width: 80px;
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
}
.navbar-scrolled {
  padding: 5px 0px !important;
}
#mainNav .navbar-nav .nav-item .nav-link {
  margin-top: 10px;
  border: 1px solid #f3f3f347;
  padding: 10px !important;
  border-radius: 10px;
  margin: 3px;
  color: rgba(255, 255, 255, 1);
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
}
#mainNav .navbar-nav .nav-item .nav-link:hover {
  background: #fff92f;
  color: rgba(0, 0, 0, 1);
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
}
.navbar-nav {
  margin-top: 35px !important;
}
.navbar-scrolled .navbar-nav {
  margin-top: 0px !important;
}
.announcement-box {
  background: white;
  width: 100%;
  min-height: 300px;
  padding: 10px;
}
.bg-foot-cred {
  background: linear-gradient(#323232, #000);
}
.foot-widgs {
  padding: 50px 0px;
  box-shadow: 0px 0px 10px #e2dfdf;
  background: url(../img/tdmnsc-bg-1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
#widgets h3 {
  margin-bottom: 25px;
}
#widgets ul {
  padding: 0px;
}
#widgets ul li {
  list-style-type: none;
  list-style-position: inside;
}
#widgets ul li:before {
  content: url(../img/tick.png);
  vertical-align: sub;
  padding-right: 10px;
}
.bg-bg2 {
  background: url(../img/tdmnsc-bg-2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  header.masthead {
    padding-top: 0px;
  }
}
@media (max-width: 768px) {
  .navbar-scrolled .logo-imgs {
    display: none;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    margin-top: 10px;
    border: 1px solid #f3f3f347;
    padding: 10px !important;
    border-radius: 10px;
    margin: 3px;
    color: rgb(0, 0, 0);
  }
  .logo-imgs {
    width: 100%;
    max-width: 85px;
  }
  header.masthead {
    padding-top: 8rem;
    padding-bottom: calc(4rem - 72px);
  }
}

ul.announceul-top li {
  display: inline !important;
  color: #fff;
}

/* Images between <hr> tag */

hr.style-six {
  padding: 0;
  border: none;
  height: 1px;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  background-image: -ms-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
  color: #333;
  text-align: center;
}
hr.style-six:after {
  content: " ";
  display: inline-block;
  position: relative;
  top: -2.1em;
  font-size: 1.5em;
  padding: 19px 1.75em;
  background: #fff url(hr/book-open-96.png) no-repeat scroll center;
  background-size: 90px 90px;
  height: 50px;
}

hr.type_1 {
  border: 0;
  height: 55px;
  background-image: url(hr/hr-type-1.png);
  background-repeat: no-repeat;
  background-position: center;
}

hr.type_4 {
  border: 0;
  height: 55px;
  background-image: url(hr/type_4.png);
  background-repeat: no-repeat;
  background-position: center;
}

hr.type_5 {
  border: 0;
  height: 55px;
  background-image: url(hr/type_5.png);
  background-repeat: no-repeat;
  background-position: center;
}

hr.type_7 {
  border: 0;
  height: 55px;
  background-image: url(hr/type_7.png);
  background-repeat: no-repeat;
  background-position: center;
}

.home-top-cour {
  position: relative;
  overflow: hidden;
  padding: 15px 0;
  margin-bottom: 30px;
  transition: all 0.5s ease;
}

.home-top-cour:hover {
  transform: scale(1.02);
  box-shadow: 0 11px 9px -10px rgba(0, 0, 0, 0.52);
  transition: all 0.5s ease;
}
.ps__alumni:hover h4 {
  color: #ec1a1a;
}
.cl__header {
  margin-bottom: 5px !important;
}

.ps__announce_h4 {
  text-align: center;
  border: 2px solid green;
  padding: 3px;
  border-radius: 0 22px;
  background-color: #f3fff4;
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

.color-on-hover-white:hover {
  color: #426f45;
}
.fw-bold {
  font-weight: 600 !important;
}

.color-light-1 {
  background-color: #f3fff4;
}
.color-light-2 {
  background-color: #efefef;
}
