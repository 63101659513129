.cloader {
  border: 7px solid #f3f3f3; /* Light grey */
  border-top: 7px solid #e82804; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}