.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin-bottom:0px;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

.tab-list-active {
  background-color: white;
  border: solid #304965;
  border-width: 0px 0px 2px 0px;
}

